import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NbMediaBreakpoint, NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService, } from '@nebular/theme';

import {NbSidebarComponent} from '@nebular/theme/components/sidebar/sidebar.component';

import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/delay';
import 'rxjs/add/observable/of';
import {LoginService} from '../auth/components/login/login.service';
import {PagesService} from './pages.service';

import {environment} from '../../environments/environment';
import * as moment from 'moment';

enum UserRoles {
  All = 'ALL',
  Admin = 'ADMIN',
  User = 'USER',
  TemplateUser = 'TEMPLATEUSER'
}

@Component({
  selector: 'ngx-pages',
  styleUrls: ['./pages.component.scss'],
  templateUrl: './pages.component.html',
})
export class PagesComponent implements OnDestroy, OnInit {

  @ViewChild('settingsSidebar') settingsSidebar: NbSidebarComponent;
  sidebarCollapsed = true;
  queryParams: Params = {};
  menu: NbMenuItem[];
  layout: any = {};
  sidebar: any = {};
  isAdmin = false;
  loggedAsUser = null;
  devMode: boolean;
  private environment = environment;
  protected menuClick$: Subscription;
  lastPingWarning = false;
  year = moment().format('YYYY');


  constructor(protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService,
              private _login: LoginService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _pageService: PagesService) {

    const isBp = this.bpService.getByName('is');
    this.menuClick$ = this.menuService.onItemSelect()
      .withLatestFrom(this.themeService.onMediaQueryChange())
      .delay(20)
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });

    this.sidebarService.onToggle().subscribe(() => {
      setTimeout(() => {
        this.sidebarCollapsed = this.settingsSidebar.collapsed;
      });
    });

    this.sidebarService.onCollapse().subscribe(() => {
      setTimeout(() => {
        this.sidebarCollapsed = true;
      });
    });

    this.sidebarService.onExpand().subscribe(() => {
      setTimeout(() => {
        this.sidebarCollapsed = false;
      });
    });

    this._pageService.lastPingWarning.subscribe(() => {
      this.lastPingWarning = true;
      this.updateMenu();
    });

    this.updateMenu();
  }

  ngOnInit() {
    this.devMode = !this.environment.production;

    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();

    // route subscriber
    this._route
      .queryParams
      .subscribe(params => {
        this.queryParams = params;
        this.updateMenu();
      });
  }


  ngOnDestroy() {
    this.menuClick$.unsubscribe();
  }

  updateMenu() {

    const pars = this.queryParams;
    const menu = [
      {
        title: 'Dashboard',
        icon: 'fas fa-home',
        link: '/pages/dashboard',
        queryParams: pars,
        home: true,
        roles: [UserRoles.All],
      },
      {
        title: 'Alerts',
        icon: 'far fa-bell',
        link: '/pages/alert',
        queryParams: pars,
        roles: [UserRoles.Admin, UserRoles.User],
      },
      {
        title: 'GApp Statistics',
        icon: 'far fa-chart-bar',
        link: '/pages/gapp-statistics',
        queryParams: pars,
        roles: [UserRoles.Admin, UserRoles.User],
      },
      {
        title: 'Config',
        icon: 'fas fa-cogs',
        link: '/pages/config',
        queryParams: pars,
        roles: [UserRoles.User, UserRoles.TemplateUser],
      },
      {
        title: 'Reprocessing Rules',
        icon: 'fas fa-retweet',
        link: '/pages/reprocessing',
        queryParams: pars,
        roles: [UserRoles.User, UserRoles.TemplateUser],
      },
      {
        title: 'Third-Party Auth',
        icon: 'fas fa-id-badge',
        link: '/pages/third-party',
        queryParams: pars,
        roles: [UserRoles.All],
      },
      {
        title: 'Templates',
        icon: 'far fa-file-alt',
        link: '/pages/template',
        queryParams: pars,
        roles: [UserRoles.Admin],
      }
    ];

    const currentRoles = [];
    if (this.loggedAsUser) {
      if (this.loggedAsUser.register_type === 'template') {
        currentRoles.push(UserRoles.TemplateUser);
      } else {
        currentRoles.push(UserRoles.User);
      }
    } else {
      if (this.isAdmin) {
        currentRoles.push(UserRoles.Admin);
      }
    }

    this.menu = menu.filter((elem: any) => {
      if (elem.roles.indexOf(UserRoles.All) > -1) {
        return true;
      } else {
        return elem.roles.filter(el => {
          return currentRoles.indexOf(el) > -1;
        }).length > 0;
      }
    });
  }

  clickedBgPane() {
    this.sidebarService.collapse('settings-sidebar');
  }

  logOffAsUser() {
    this._router.navigate(['/pages/dashboard'], {queryParams: {}}).then(() => {
      window.location.reload(true);
    });
  }

}
