import {Component, ElementRef, OnInit, ViewEncapsulation} from '@angular/core';
import * as d3 from 'd3';
import * as d3fetch from 'd3-fetch';
import * as chroma from 'chroma-js';
import * as moment from 'moment';
import * as async from 'async';

import {ApiService} from '../../_api/api.service';
import {LoginService} from '../../auth/components/login/login.service';
import {System} from '../system/system';
import {TypeService} from '../type/type.service';
import {EmptyType, Type, TypeCollection} from '../type/type';
import {SidebarService, SidebarType} from '../sidebar.service';
import {ModalsService} from '../../_utils/modals/modals.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NbSidebarService} from '@nebular/theme';

import {QuestionBase, TextboxQuestion} from '../../_utils/forms';

import {PagesService} from '../pages.service';
import {ComplexTypeModalService} from '../../_utils/modals/modal/complex-type-modal.service';
import {ClipboardService} from 'ngx-clipboard';
import {ToastService} from '../../_utils/toast/toast.service';
import {IModalData} from '../../_utils/interfaces/imodal-data';
import {ITypeScheduleData} from '../../_utils/interfaces/itype-schedule-data';
import {ITypeSchedulerStatus} from '../../_utils/interfaces/itype-scheduler-status';

enum HookTypes {
  PostHook = 'post-hook',
  PreHook = 'pre-hook',
  ReprocessingRule = 'rule-hook',
  ReprocessingRuleDestination = 'rule-dest-hook'
}

class ConnectHookOptions {
  public commitAfterPublish = false;
  public onHold = false;
}

@Component({
  selector: 'app-config',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css'],
  providers: [ApiService],
})
export class ConfigComponent implements OnInit {

  loggedAsUser = null;

  // =========== SYSTEMS
  systems: System[];
  reprocessingRules: any[];

  // =========== TYPES
  allTypes: TypeCollection;
  showSystemsList = true;
  iconsList = {};

  // =========== CANVAS
  canvas: any;
  svg: any;
  svgGroup: any;
  //// d3arcs = [];

  // =========== VARS
  minInnerR = 200;
  diffRadius = 80;
  innerR = this.minInnerR;
  outerR = this.innerR + this.diffRadius;
  //// padAng = Math.PI / 100;
  circRad = 120;
  rotAngle: number;
  typeLabelW = 80;
  typeRadius = 0.7 * (this.typeLabelW) / 2;
  rectLabelW = 2 * this.typeRadius;

  // =========== GLOBALS
  canvasWidth = 1600;
  canvasHeight = 1000;
  colorArray = ['#F44336', '#3F51B5', '#4CAF50', '#FF9800', '#E91E63', '#2196F3', '#8BC34A', '#FF5722',
    '#03A9F4', '#CDDC39', '#795548', '#9C27B0', '#00BCD4', '#FFEB3B', '#9E9E9E', '#673AB7', '#009688', '#FFC107'];


  // =========== D3 ELEMENTS
  arc: d3.Arc<any, d3.DefaultArcObject>;
  labelArc: d3.Arc<any, d3.DefaultArcObject>;
  //// _currentArc: any;
  //// _currentLabel: any;
  //// _currentTemp: any;
  zoom: any;
  container: any;
  pie: any;
  infoArea: any;
  mainGroup: any;
  connGroup: any;
  typeConnGroup: any;
  actionGroup: any;
  cronInfoGroup: any;
  addHookGroup: any;

  destHookType = 'post';

  // OTHER
  queryParams: Params = {};
  sysExpanded: any = {};
  selectedType = '';
  addHookOriginType: any;

  unusedLinks = [];
  unusedAppendLinks = [];

  //// dragFun: any;
  //// colorScale: any;

  userSettings = null;


  // DISPLAY OPTIONS
  displaySelectedOption = 'show-hooks';


  constructor(private element: ElementRef,
              private api: ApiService,
              private router: Router,
              private sidebarService: NbSidebarService,
              private _route: ActivatedRoute,
              private _modals: ModalsService,
              private customSidebarService: SidebarService,
              private _pageService: PagesService,
              private _typeService: TypeService,
              private ctmService: ComplexTypeModalService,
              private _toast: ToastService,
              private _clipboardService: ClipboardService,
              private _login: LoginService) {

    // this.colorScale = d3.scaleSequential(d3.interpolateCool).domain([0,20]);
    // this.colorScale = chroma.scale('Spectral').colors(10);
  }

  ngOnInit() {

    // route subscriber
    this._route
      .queryParams
      .subscribe(params => {
        this.queryParams = params;
      });

    // LOAD DATA
    this.loadData();
  }

  loadData() {
    this.setLoading();

    this.loggedAsUser = this._login.getLoggedAsUser();
    if (this.loggedAsUser) {
      this.userSettings = this.loggedAsUser.settings;
    }

    if (!this.userSettings) {
      this.userSettings = {};
    }
    if (!this.userSettings.config) {
      this.userSettings.config = {systems: {}};
    }

    this.api.get('return_triggers').then(reprocessingRulesResponse => {

      this.reprocessingRules = reprocessingRulesResponse.results.sort(function (a, b) {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateA.valueOf() - dateB.valueOf();
      });

      this.api.get('system').then(response => {
        this.systems = response.results.sort((a, b) => (a.alias && b.alias ? a.alias.localeCompare(b.alias) : 0));
        this.systems.map(elem => {
          this.sysExpanded[elem.id] = false;
          elem.types.push(new EmptyType(elem.id));
        });

        // this.colorScale = chroma.scale('Spectral').colors(this.systems.length);
        this.colorArray = chroma.scale('Spectral').colors(this.systems.length);

        if (this.userSettings.config.systems) {
          Object.keys(this.userSettings.config.systems).forEach(key => {
            if (!this.systems.find(sys => sys.id === key)) {
              delete this.userSettings.config.systems[key];
            }
          });
        }

        this.systems.forEach((sys, idx) => {
          if (!this.userSettings.config.systems[sys.id] || !this.userSettings.config.systems[sys.id].color) {
            this.userSettings.config.systems[sys.id] = {
              color: this.colorArray[idx],
              label: sys.alias
            };
          }
        });

        const aux = this.systems.reduce(function (a, b) {
          return a.concat(b.types);
        }, []);

        this.allTypes = new TypeCollection();
        aux.map(el => this.allTypes[el.id] = el);

        this._typeService.getUnusedLinks().then(link => {
          if (link) {
            this.unusedLinks = link.links;
            this.unusedAppendLinks = link.appendLinks;
          }
        });

        this.loadGraphs();
      }).catch(error => {
        console.error(error);
        this._toast.showToast('error', 'Get System', 'Error getting systems!');
      });
    });
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }

  onRefresh() {
    this.loadData();
  }

  calcTimeElapsed(from, to) {
    if (!to) {
      return null;
    }
    const eta = moment(to).utc();
    const elapsed = from.diff(eta, 'minutes', true);
    return Math.round(elapsed);
  }

  createCanvas() {

    // SVG CANVAS
    this.canvas = d3.select('#d3canvas');
    this.canvas.select('svg').remove();
    this.svgGroup = this.canvas.append('svg')
      .attrs({width: this.canvasWidth, height: this.canvasHeight})
      .call(d3.zoom().scaleExtent([0.5, 8]).on('zoom', () => {
        const X = d3.event.transform.x;
        const Y = d3.event.transform.y;
        const K = d3.event.transform.k;
        let t = d3.zoomIdentity;
        t = t.translate(X, Y).scale(K).translate(this.canvasWidth / 2, this.canvasHeight / 2);
        this.svg.attr('transform', t);
      }));

    this.svg = this.svgGroup.append('g')
      .attr('transform', `translate(${this.canvasWidth / 2},${this.canvasHeight / 2})scale(1)`)
      .style('background-color', '#999');

    // BACKGROUND RECT
    this.svg.append('rect')
      .attrs({width: this.canvasWidth, height: this.canvasHeight})
      .attr('transform', `translate(${-this.canvasWidth / 2},${-this.canvasHeight / 2})`)
      .styles({'fill': 'none', 'pointer-events': 'all'});

    const x = 4;
    const m1 = `M${x * (-1)},${x}`;
    const l1 = `l${x * 2},${x * (-2)}`;
    const m2 = `M${x * (0)},${x * 4}`;
    const l2 = `l${x * 4},${x * (-4)}`;
    const m3 = `M${x * (3)},${x * 5}`;
    const l3 = `l${x * 2},${x * (-2)}`;
    const patt = m1 + l1 + m2 + l2 + m3 + l3;

    this.svgGroup.append('defs')
      .append('pattern')
      .attr('id', 'diagonalHatch')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', x * 4)
      .attr('height', x * 4)
      .append('path')
      .attr('d', patt)
      .attr('stroke', '#555')
      .attr('stroke-width', 0.5);
  }

  createInfoArea() {

    const iaAttrs = {
      class: 'info-circle',
      transform: 'translate(0,170)'
    };

    this.infoArea = this.svg.append('g')
      .attrs(iaAttrs);

    const iaRectAttrs = {
      width: 300,
      height: 60,
      transform: 'translate(-150,-30)'
    };

    this.infoArea.append('rect')
      .attrs(iaRectAttrs);

    this.infoArea.append('text')
      .styles({'text-anchor': 'middle', 'font-weight': 'bold'})
      .attr('transform', 'translate(0,-10)')
      .text('TESTING');
  }

  createPieGraph() {
    const _this = this;

    // CALCULATE MINIMUM SUFFICIENT RADIUS
    const typesLen = this.systems.reduce((acum, curr) => acum + curr.types.length, 0);
    const typeAngle = 360 / typesLen;
    const minRad = Math.ceil(180 * this.rectLabelW / (typeAngle * Math.PI));

    if (minRad >= this.minInnerR) {
      this.innerR = minRad;
      this.outerR = minRad + this.diffRadius;
    }

    // DEFINE PIE GENERATOR
    this.pie = d3.pie<any, any>().sort(null)
      .value(function (d) {
        return d['types'].length;
      })(_this.systems);

    // ARC
    this.arc = d3.arc().outerRadius(this.outerR).innerRadius(this.innerR);

    // LABEL ARC
    this.labelArc = d3.arc().outerRadius(this.outerR - 25).innerRadius(this.innerR - 25);

    // ARCS
    this.mainGroup = this.svg.selectAll('arc')
      .data(this.pie)
      .enter().append('g')
      .attr('class', 'arc')
      .attr('id', function (d) {
        return d.data.id;
      });

    // ARCS PATHS
    this.mainGroup.append('path')
      .attr('d', this.arc)
      .style('fill', function (d) {
        // return _this.getColorCode(d.index);
        return _this.getColorCodeSys(d.data.id, d.index);
      });

    // this.createPieLabels();
    this.createTypesElements();
  }

  fillTypes(element, multiplier) {
    const data = d3.select<any, any>(element.parentNode.parentNode).data();
    const hexColor = this.getColorCodeSys(data[0].data.id, data[0].index);
    const rgbColor = d3.rgb(hexColor);
    const mult = multiplier;
    const newRgb = {
      r: Math.round(mult * rgbColor.r),
      g: Math.round(mult * rgbColor.g),
      b: Math.round(mult * rgbColor.b)
    };
    return this.rgbToHex(newRgb.r, newRgb.g, newRgb.b);
  }

  // Create TYPES circles on the arcs
  createTypesElements() {
    const _this = this;

    // ======== CIRCLE CONTAINER

    const circleContainer = this.mainGroup.append('g')
      .attrs({'class': 'circleContainer', 'pointer-events': 'all'});

    const typeCircleContainer = circleContainer.selectAll('circle').data(function (d) {
      return d.data.types.sort((b, a) => (a.description && b.description ? a.description.localeCompare(b.description) : 0));
    }).enter()
      .append('g')
      .attrs({'class': 'singleCircle', 'pointer-events': 'all'})
      .attr('id', function (d) {
        return d.id;
      });

    typeCircleContainer.append('circle')
      .attrs({'class': 'refSingleCircle', 'cx': 0, 'cy': 0, 'pointer-events': 'all'})
      .style('fill', function () {
        return _this.fillTypes(this, 0.6);
      })
      .attr('r', _this.typeRadius);

    typeCircleContainer.append('circle')
      .attrs({'cx': 0, 'cy': -3, 'pointer-events': 'all'})
      .style('fill', function (d) {
        return _this.fillTypes(this, (d instanceof EmptyType) ? 0.6 : 1);
      })
      .attr('r', _this.typeRadius);

    typeCircleContainer.append('rect')
      .attrs({x: -_this.rectLabelW / 2, width: _this.rectLabelW})
      .attr('y', function (d) {
        return -_this.textSize(_this.getDescription(d.description));
      })
      .attr('height', function (d) {
        return _this.textSize(_this.getDescription(d.description));
      })
      .style('fill', function (d) {
        return _this.fillTypes(this, (d instanceof EmptyType) ? 0.6 : 1);
      });

    typeCircleContainer.append('circle')
      .attrs({'cx': 0, 'pointer-events': 'all'})
      .attr('cy', function (d) {
        return -_this.textSize(_this.getDescription(d.description));
      })
      .style('fill', function (d) {
        return _this.fillTypes(this, (d instanceof EmptyType) ? 0.6 : 1);
      })
      .attr('r', _this.typeRadius);

    typeCircleContainer.append('text')
      .text(function (d) {
        return _this.getDescription(d['description']);
      })
      .attrs({'pointer-events': 'none', 'alignment-baseline': 'middle'})
      .attr('transform', function (d) {
        const parentData = d3.select<any, any>(this.parentNode.parentNode).datum();
        const ang = _this.deg2rad(_this.rotAngle);
        const pos = ang * (parentData.data.types.findIndex(elem => elem.id === d.id) + 1);
        const rotAng = parentData.startAngle + pos - ang / 2;
        const rotation = _this.rad2deg(rotAng) > 180 ? 90 : 270;
        return 'translate(0,0)rotate(' + rotation + ')';
      })
      .attr('text-anchor', function (d) {
        const parentData = d3.select<any, any>(this.parentNode.parentNode).datum();
        const ang = _this.deg2rad(_this.rotAngle);
        const pos = ang * (parentData.data.types.findIndex(elem => elem.id === d.id) + 1);
        const rotAng = parentData.startAngle + pos - ang / 2;
        return _this.rad2deg(rotAng) > 180 ? 'end' : 'start';
      })
      .style('fill', function () {
        const data = d3.select<any, any>(this.parentNode.parentNode).data();
        const hexColor = _this.getColorCodeSys(data[0].data.id, data[0].index);
        const contrast = chroma.contrast(hexColor, 'white');
        return (contrast > 4.5 ? '#FFF' : '#000');
      });

    typeCircleContainer.attr('transform', function (d) {
      const parentData = d3.select<any, any>(this.parentNode).datum();
      const ang = _this.deg2rad(_this.rotAngle);
      const pos = ang * (parentData.data.types.findIndex(elem => elem.id === d.id) + 1);
      const rotAng = parentData.startAngle + pos - ang / 2;
      const rad = (_this.outerR + _this.innerR) / 2;
      return 'translate(' + rad * Math.sin(rotAng) + ',' + -rad * Math.cos(rotAng) + ')rotate(' + _this.rad2deg(rotAng) + ')';
    });


    // Render type circle badges
    _this.renderTypeBadges(typeCircleContainer, _this);


    d3.selectAll('.singleCircle')
      .on('click', function (d) {
        const notAllowed = d3.select(this).classed('not-allowed-destination-hook');
        const destHookMode = d3.select(this).classed('destination-hook-mode');

        if (destHookMode) {
          if (!notAllowed) {
            _this.selectHookDestination(d['id']);
          }
        } else {
          if (d instanceof EmptyType) {
            _this.addNewType(d['system_id']);
          } else {
            _this.selectType(d['id']);
          }
        }
      })
      .on('mouseover', function (d) {
        _this.toggleInfoArea(true, d);
      })
      .on('mouseout', function (d) {
        _this.toggleInfoArea(false, d);
      });
  }

  /**
   * This Method calculates and renders the badges on Type circle's side (if necessary)
   */
  private renderTypeBadges(typeCircles, _this): void {
    typeCircles.each(function (type) {

      const typeCircleElement = this;

      const parentData = d3.select<any, any>(this.parentNode).datum();
      const ang = _this.deg2rad(_this.rotAngle);
      const pos = ang * (parentData.data.types.findIndex(elem => elem.id === type.id) + 1);
      const rotAng = parentData.startAngle + pos - ang / 2;
      const offAngle = Math.PI - _this.rad2deg(rotAng);
      const offset = -_this.textSize(_this.getDescription(type.description)) - 25;

      // On Hold badge (pause icon)
      _this.renderOnHoldBadge(typeCircleElement, type, offset, offAngle, _this);

      // Cron status badge (yellow exclamation icon)
      let newOffset = _this.renderCronNotDefinedBadge(typeCircleElement, type, offset, offAngle, _this);

      // Cron delay/stuck badge (red/orange exclamation icon)
      newOffset = _this.renderCronStatusDelayedOrStuckBadge(typeCircleElement, type, newOffset, offAngle, _this);

    });
  }

  private renderOnHoldBadge(typeCircleElement, type: Type, offset: number, offAngle: number, _this): void {
    if (type.on_hold) {
      const group = d3.select(typeCircleElement).append('g').attr('transform', 'translate(0,' + offset + ')rotate(' + offAngle + ')');
      group.append('circle')
        .attrs({
          'class': 'hold-badge',
          'cx': 0,
          'cy': 0,
          'stroke-width': '2',
          'r': 12,
          'stroke': '#FFF',
          'pointer-events': 'all'
        })
        .style('fill', '#333');

      const aux: any = group;
      const icon = aux.node().appendChild(_this.iconsList['pause'].cloneNode(true));
      const iconSize = 14;
      d3.select(icon)
        .attrs({
          height: iconSize,
          width: iconSize,
          x: -iconSize / 2,
          y: -iconSize / 2,
          'fill': '#FFF',
          'pointer-events': 'none'
        });

      group.datum({label: 'On Hold', message: ''});
      _this.defineTooltip(group);
    }
  }

  private renderCronNotDefinedBadge(typeCircleElement, type: Type, offset: number, offAngle: number, _this): number {
    if (!(type instanceof EmptyType)) {

      let typeHasDefinedScheduler = false;
      if (type.hasOwnProperty('schedulers')) {
        type.schedulers.forEach(scheduler => {
          if (scheduler.operation === 'TASK' && scheduler.cron_format.length > 0) {
            typeHasDefinedScheduler = true;
          }
        });
      }

      if (!typeHasDefinedScheduler && (type.task && type.cron)) {
        typeHasDefinedScheduler = true;
      }

      if (!typeHasDefinedScheduler) {

        offset -= 25;

        const group = d3.select(typeCircleElement)
          .append('g')
          .attr('transform', 'translate(0,' + (offset) + ')rotate(' + Math.floor(offAngle) + ')');

        group.append('circle')
          .attrs({
            'class': 'cron-badge',
            'cx': 0,
            'pointer-events': 'all',
            'cy': 0,
            'r': 12,
            'stroke': '#FFF',
            'stroke-width': '2'
          })
          .style('fill', '#FFF');

        const aux: any = group;
        const icon = aux.node().appendChild(_this.iconsList['exclamation-triangle-solid'].cloneNode(true));
        const iconSize = 16;
        d3.select(icon)
          .attrs({
            height: iconSize,
            width: iconSize,
            x: -iconSize / 2,
            y: -iconSize / 2,
            color: '#ffc107',
            'pointer-events': 'none'
          });

        group.datum({label: 'Cron FALSE', message: ''});
        _this.defineTooltip(group);
      }
    }

    return offset;
  }

  private renderCronStatusDelayedOrStuckBadge(typeCircleElement, type: Type, offset: number, offAngle: number, _this): number {
    if (!(type instanceof EmptyType) && !type.on_hold) {

      const taskScheduler = _this.returnTypeScheduler('TASK', type);
      const commitScheduler = _this.returnTypeScheduler('COMMIT', type);

      const taskStatus = this.returnIfTypeSchedulerIsDelayedOrStuck('TASK', taskScheduler, type);
      const commitStatus = this.returnIfTypeSchedulerIsDelayedOrStuck('COMMIT', commitScheduler, type);
      const status = new Array<ITypeSchedulerStatus>();

      if (taskStatus) {
        status.push(taskStatus);
      }
      if (commitStatus) {
        status.push(commitStatus);
      }

      status.forEach(st => {
        offset -= 25;

        const group = d3.select(typeCircleElement)
          .append('g')
          .attr('transform', 'translate(0,' + (offset) + ')rotate(' + Math.floor(offAngle) + ')');

        group.append('circle')
          .attrs({
            'class': 'cron-badge',
            'cx': 0,
            'pointer-events': 'all',
            'cy': 0,
            'r': 12,
            'stroke': '#FFF',
            'stroke-width': '2'
          }).style('fill', '#FFF');

        const aux: any = group;
        const icon = aux.node().appendChild(_this.iconsList['exclamation-triangle-solid'].cloneNode(true));
        const iconSize = 16;
        d3.select(icon)
          .attrs({
            height: iconSize,
            width: iconSize,
            x: -iconSize / 2,
            y: -iconSize / 2,
            color: st.color,
            'pointer-events': 'none'
          });
        group.datum({label: st.level, message: st.message});
        _this.defineTooltip(group);
      });
    }

    return offset;

  }

  private returnIfTypeSchedulerIsDelayedOrStuck(operation: string, scheduler: ITypeScheduleData, type: Type): ITypeSchedulerStatus | null {

    // Constraints
    const warnDelayLevel = 5;
    const dangerDelayLevel = 15;
    const warnRunningTime = 8;
    const dangerRunningTime = 12;
    const warnColor = 'orange';
    const dangerColor = 'red';

    let schedulerStatus: ITypeSchedulerStatus = null;

    if (scheduler) {
      // Type task use the new cron system
      const nextExecution = moment(scheduler.next_execution);
      const lastExecution = moment(scheduler.last_execution);
      const now = moment();

      // Check if task is delayed
      if (!scheduler.is_running && now.isAfter(nextExecution)) {
        const timeDifference = Math.abs(now.diff(nextExecution, 'minutes'));
        if (timeDifference >= dangerDelayLevel) {
          schedulerStatus = {
            level: 'DANGER',
            color: dangerColor,
            message: `[${operation}] Execution Delayed by ${timeDifference} minutes, please check.`
          };
        } else if (timeDifference >= warnDelayLevel) {
          schedulerStatus = {
            level: 'WARNING',
            color: warnColor,
            message: `[${operation}] Execution Delayed by ${timeDifference} minutes, please check.`
          };
        }
      }

      // Check if task is stuck (running forever)
      if (scheduler.is_running) {
        const timeDifference = Math.abs(now.diff(lastExecution, 'hours'));
        if (timeDifference >= dangerRunningTime) {
          schedulerStatus = {
            level: 'DANGER',
            color: dangerColor,
            message: `[${operation}] Execution is running for ${timeDifference} hours, please check.`
          };
        } else if (timeDifference >= warnRunningTime) {
          schedulerStatus = {
            level: 'WARNING',
            color: warnColor,
            message: `[${operation}] Execution is running for ${timeDifference} hours, please check.`
          };
        }
      }

    } else {
      // Type task use the old cron system
      let nextExecution = null;
      const now = moment();
      if (operation === 'TASK') {
        nextExecution = moment(type.cron_schedule_task);
      }
      if (operation === 'COMMIT') {
        nextExecution = moment(type.cron_schedule_commit);
      }
      if (now.isAfter(nextExecution)) {
        const timeDifference = Math.abs(now.diff(nextExecution, 'minutes'));
        if (timeDifference >= dangerDelayLevel) {
          schedulerStatus = {
            level: 'DANGER',
            color: dangerColor,
            message: `[${operation}] Execution Delayed by ${timeDifference} minutes, please check.`
          };
        } else if (timeDifference >= warnDelayLevel) {
          schedulerStatus = {
            level: 'WARNING',
            color: warnColor,
            message: `[${operation}] Execution Delayed by ${timeDifference} minutes, please check.`
          };
        }
      }
    }
    return schedulerStatus;
  }

  private returnTypeScheduler(operation: string, type: Type): ITypeScheduleData | null {
    const schedulers = type.schedulers;
    let schedule: ITypeScheduleData = null;
    schedulers.forEach(sched => {
      if (sched.operation === operation) {
        schedule = sched as ITypeScheduleData;
      }
    });
    return schedule;
  }


  createMarkers() {
    const _this = this;
    const defs = this.mainGroup.append('svg:defs');

    // ======= ARROW

    const m1w = 4, m1h = 4, m1mult = 3;

    defs.append('svg:marker')
      .attr('id', function (d) {
        return 'arrow-' + d.data.id;
      })
      .attrs({
        'refX': m1w * m1mult / 2 + 4,
        'refY': m1h * m1mult / 2,
        'markerWidth': m1mult * m1w,
        'markerHeight': m1mult * m1h,
        'markerUnits': 'userSpaceOnUse',
        'orient': 'auto'
      })
      .append('path')
      .attr('d', 'M 0 0 ' + m1mult * m1w + ' ' + m1mult * m1h / 2 + ' 0 ' + m1mult * m1w)
      .style('fill', function (d) {
        return _this.getColorCodeSys(d.data.id, d.index);
      });

    // ======= ARROW DOUBLE

    let d1w = 8, d1h = 4, d1mult = 3;

    let d1path = [0, 0, 4, 2, 4, 0, 8, 2, 4, 4, 4, 2, 0, 4];
    let d1pathStr = d1path.map(val => val * 3).join(' ');

    defs.append('svg:marker')
      .attr('id', function (d) {
        return 'arrowDouble-' + d.data.id;
      })
      .attrs({
        'refX': d1w * d1mult,
        'refY': d1h * d1mult / 2,
        'markerWidth': d1w * d1mult,
        'markerHeight': d1h * d1mult,
        'markerUnits': 'userSpaceOnUse',
        'orient': 'auto'
      })
      .append('path')
      .attr('d', 'M ' + d1pathStr + ' z')
      .style('fill', function (d) {
        return _this.getColorCodeSys(d.data.id, d.index);
      });


    // ======= ARROW HOVER

    const mh1w = 4, mh1h = 4, mh1mult = 5;

    defs.append('svg:marker')
      .attr('id', function (d) {
        return 'arrowHov-' + d.data.id;
      })
      .attrs({
        'refX': mh1w * mh1mult / 2 + 3,
        'refY': mh1h * mh1mult / 2,
        'markerWidth': mh1mult * mh1w,
        'markerHeight': mh1mult * mh1h,
        'markerUnits': 'userSpaceOnUse',
        'orient': 'auto'
      })
      .append('path')
      .attr('d', 'M 0 0 ' +
        mh1mult * (mh1w - 2) + ' ' +
        mh1mult * (mh1h - 2) / 2 + ' ' +
        mh1mult * (mh1w - 2) + ' ' +
        mh1mult * (mh1h + 2) / 2 +
        ' 0 ' +
        mh1mult * mh1h)
      .style('fill', function (d) {
        return _this.getColorCodeSys(d.data.id, d.index);
      });


    // ======= CIRCLE MARKER

    defs.append('svg:marker')
      .attr('id', function () {
        return 'circleMarker';
      })
      .attrs({'refX': 5, 'refY': 5, 'markerWidth': 10, 'markerHeight': 10, 'markerUnits': 'userSpaceOnUse', 'orient': 'auto'})
      .append('circle')
      .attrs({'cx': 5, 'cy': 5, 'r': 2, 'fill': 'red'});


    // ======= ARROW DIAMOND

    const m2mult = 2;
    const m2w = m2mult * 8, m2h = m2mult * 6;
    const m2p1 = {x: (m2w / 2), y: 1};
    const m2p2 = {x: (m2w - 1), y: (m2h / 2)};
    const m2p3 = {x: (m2w / 2), y: (m2h - 1)};
    const m2p4 = {x: 1, y: (m2h / 2)};

    defs.append('svg:marker')
      .attr('id', function (d) {
        return 'diamond-' + d.data.id;
      })
      .attrs({'refX': m2w, 'refY': m2h / 2, 'markerWidth': m2w, 'markerHeight': m2h, 'markerUnits': 'userSpaceOnUse', 'orient': 'auto'})
      .append('path')
      .attr('d', 'M ' +
        m2p1.x + ' ' +
        m2p1.y + ' ' +
        m2p2.x + ' ' +
        m2p2.y + ' ' +
        m2p3.x + ' ' +
        m2p3.y + ' ' +
        m2p4.x + ' ' +
        m2p4.y + ' ' +
        m2p1.x + ' ' +
        m2p1.y)
      .styles({'stroke-width': '2', 'stroke-dasharray': '0,0', 'stroke-linecap': 'round', 'fill': 'white'})
      .style('stroke', function (d) {
        return _this.getColorCodeSys(d.data.id, d.index);
      });

    // ======= ARROW APPEND LINK

    d1w = 6;
    d1h = 4;
    d1mult = 3;

    d1path = [0, 2, 2, 0, 6, 0, 4, 2, 6, 4, 2, 4, 0, 2];
    d1pathStr = d1path.map(val => val * 3).join(' ');

    defs.append('svg:marker')
      .attr('id', function (d) {
        return 'arrowAppend-' + d.data.id;
      })
      .attrs({
        'refX': d1w * d1mult,
        'refY': d1h * d1mult / 2,
        'markerWidth': d1w * d1mult,
        'markerHeight': d1h * d1mult,
        'markerUnits': 'userSpaceOnUse',
        'orient': 'auto'
      })
      .append('path')
      .attr('d', 'M ' + d1pathStr + ' z')
      // .styles({"fill": "none", "stroke-width": 2})
      .style('fill', function (d) {
        return _this.getColorCodeSys(d.data.id, d.index);
      });

    // ======== MIDDLE LINE MARKER
    defs.append('svg:marker')
      .attr('id', function (d) {
        return 'stubMarker-' + d.data.id;
      })
      .attrs({'refX': 4, 'refY': 4, 'markerWidth': 8, 'markerHeight': 8, 'markerUnits': 'userSpaceOnUse', 'orient': 'auto'})
      .append('path')
      .attr('d', 'M 1 4 L3 4 L3 -4 L1 -4 M-1 4 L-3 4 L-3 -4 L-1 -4 Z')
      .attr('transform', 'translate(4,4)')
      // .style("fill", "#333");
      .style('fill', function (d) {
        return _this.getColorCodeSys(d.data.id, d.index);
      });


    // ==========
    defs.append('pattern')
      .attrs({id: 'hash4_4', width: '8', height: '8', patternUnits: 'userSpaceOnUse', patternTransform: 'rotate(-45)'})
      .append('rect')
      .attrs({width: '4', height: '8', transform: 'translate(0,0)', fill: '#88AAEE'});
  }

  // Main method to load graphicsG
  loadGraphs() {
    const _th = this;

    const reducer = (a, b) => a + b.types.length + 0;
    this.rotAngle = 360 / this.systems.reduce(reducer, 0);

    this.loadIcons().then(() => {

      this.createCanvas();	// Create Canvas
      this.createInfoArea();	// Create info area
      this.createPieGraph();	// Create Pie graph
      this.createMarkers();	// CREATE MARKERS

      // GROUP FOR CONNECTIONS
      this.connGroup = this.svg.append('g')
        .attr('class', 'conn-group');

      // GROUP FOR TYPE CONNECTIONS
      this.typeConnGroup = this.svg.append('g')
        .attr('class', 'type-conn-group');

      // Closes context menu if clicked outside
      d3.select('body')
        .on('click', function () {
          const contextMenu = d3.selectAll('.context-menu, .context-menu *, .line-conn-group, .line-conn-group *');
          const outside = contextMenu.filter(_th.equalToEventTarget).empty();
          if (outside) {
            d3.select('.context-menu').remove();
          }
        });

      this.infoArea.lower();
      this.connGroup.lower();
      this.typeConnGroup.lower();

      // Connect Hooks
      this.connectHooks();

      // Connect Links
      this.connectLinks();

      // Connect Reprocessing Rules
      this.connectReprocessingRules();

      this.displaySelectedOptionChanged();

      this.loaded();

    }).catch(err => {
      console.error(err);
    });
  }

  loadIcons() {
    const _th = this;

    return new Promise((resolve, reject) => {
      const iconsArray = [
        {key: 'pause', icon: null},
        {key: 'database', icon: null},
        {key: 'edit', icon: null},
        {key: 'trash-alt', icon: null},
        {key: 'history', icon: null},
        {key: 'anchor', icon: null},
        {key: 'link', icon: null},
        {key: 'arrow-alt-circle-left', icon: null},
        {key: 'play', icon: null},
        {key: 'edit', icon: null},
        {key: 'times', icon: null},
        {key: 'eye', icon: null},
        {key: 'copy-regular', icon: null},
        {key: 'exclamation-triangle-solid', icon: null},
        {key: 'clock-regular', icon: null}
      ];

      async.each(iconsArray, function (elem, callback) {
        d3fetch.svg('../../../assets/icons/' + elem.key + '.svg').then(doc => {
          elem.icon = doc.documentElement;
          callback(null);
        });
      }, function (err) {
        if (err) {
          reject(err);
        } else {
          iconsArray.forEach(elem => {
            _th.iconsList[elem.key] = elem.icon;
          });
          resolve(true);
        }
      });
    });
  }

  equalToEventTarget() {
    return this === d3.event.target;
  }

  toggleInfoArea(show: boolean, d) {
    if (show) {
      this.infoArea.style('opacity', 1);
      this.infoArea.select('rect').transition().duration(200).style('opacity', .7);
      this.infoArea.select('text').html(d['description']).style('opacity', 1);
    } else {
      this.infoArea.transition().duration(200).style('opacity', 0);
    }
  }

  connectHooks() {
    this.systems.forEach(sys => {
      sys.types.forEach(typ => {
        if (typ.hooks) {
          if (typ.hooks.post) {
            typ.hooks.post.filter(th => typ.id && th.destination).forEach(elem => {
              const options: ConnectHookOptions = {
                commitAfterPublish: elem.commit_after_publish,
                onHold: elem.on_hold
              };
              this.connectNodesById(typ.id, elem.destination, HookTypes.PostHook, options);
            });
          }

          if (typ.hooks.pre) {
            typ.hooks.pre.filter(th => typ.id && th.type).forEach(elem => {
              this.connectNodesById(typ.id, elem.type, HookTypes.PreHook);
            });
          }
        }
      });
    });
  }

  connectNodesById(idA: string, idB: string, type: HookTypes, options?: ConnectHookOptions) {
    const _this = this;

    //// const pc = {x: 0, y: 0};
    const p1 = _this.getCoordinatesFromNode(idA);
    const p2 = _this.getCoordinatesFromNode(idB);

    const sys = _this.getSystemFromTypeId(idA);

    const rad = (_this.outerR + _this.innerR) / 2;
    const nr = _this.innerR;
    const delta = Math.PI / 100;

    const alpha = Math.acos(p1.x / rad);
    const theta = Math.acos(p2.x / rad);

    let linedata = [
      {x: nr * Math.cos(alpha + delta), y: Math.sign(p1.y) * nr * Math.sin(alpha + delta)},
      {x: 0, y: 0},
      {x: nr * Math.cos(theta - delta), y: Math.sign(p2.y) * nr * Math.sin(theta - delta)}
    ];

    if (idA === idB) {
      const gamma = Math.PI / 15;
      const coef = 0.8;
      const coef2 = 0.7;

      linedata = [
        {x: nr * Math.cos(alpha + delta), y: Math.sign(p1.y) * nr * Math.sin(alpha + delta)},
        {x: coef * nr * Math.cos(alpha + gamma), y: coef * Math.sign(p1.y) * nr * Math.sin(alpha + gamma)},
        {x: coef2 * nr * Math.cos(alpha), y: coef2 * Math.sign(p1.y) * nr * Math.sin(alpha)},
        {x: coef * nr * Math.cos(alpha - gamma), y: coef * Math.sign(p1.y) * nr * Math.sin(alpha - gamma)},
        {x: nr * Math.cos(theta - delta), y: Math.sign(p2.y) * nr * Math.sin(theta - delta)}
      ];
    }

    const lineFunc = d3.line()
      .x(function (d) {
        return d['x'];
      })
      .y(function (d) {
        return d['y'];
      })
      .curve(d3.curveBundle);


    let strokeStyle = '0,0';
    let markerStyle = 'arrow';
    if (type === HookTypes.PreHook) {
      strokeStyle = '4,4';
      markerStyle = 'diamond';
    }

    const commitAfterPublish = (options && options.commitAfterPublish) || false;
    if (commitAfterPublish) {
      markerStyle = 'arrowDouble';
    }

    const hookG = _this.connGroup.append('g')
      .attr('class', 'hook-line-g');

    const onHold = (options && options.onHold) || false;
    if (onHold) {
      hookG.append('path')
        .datum(linedata)
        .attrs({'d': lineFunc, 'fill': 'none', 'stroke-width': '2', 'stroke': _this.getColorFromNode(idA)})
        .styles({'stroke-dasharray': strokeStyle, 'opacity': '1'})
        .attr('marker-end', function (d) {
          return 'url(#' + markerStyle + '-' + sys.id + ')';
        })
        .attr('marker-mid', function (d) {
          return 'url(#stubMarker-' + sys.id + ')';
        });
    } else {

      hookG.append('path')
        .datum(linedata)
        .attrs({'d': lineFunc, 'fill': 'none', 'stroke-width': '2', 'stroke': _this.getColorFromNode(idA)})
        .styles({'stroke-dasharray': strokeStyle, 'opacity': '1'})
        .attr('marker-end', function (d) {
          return 'url(#' + markerStyle + '-' + sys.id + ')';
        });

    }


    const typeDest = _this.getTypeFromId(idB);

    if (commitAfterPublish && typeDest && typeDest.cron_schedule_commit != null) {
      let alertCoef = 0.01;
      if (idA === idB) {
        alertCoef = -0.085;
      }

      hookG.append('circle')
        .attrs({
          'cx': (nr * 0.85) * Math.cos(theta - delta + alertCoef),
          'cy': Math.sign(p2.y) * (nr * 0.85) * Math.sin(theta - delta + alertCoef),
          'r': 4
        })
        .style('fill', 'red');
    }
  }

  connectReprocessingRules() {
    this.reprocessingRules.forEach(rule => {
      rule.visible = true;
      rule.type_origin.forEach(origin => {
        rule.type_destination.forEach(destination => {
          this.connectReprocessingRuleById(rule.id, origin, destination, HookTypes.ReprocessingRule);
          rule.hooks.forEach(hook => {
            if (hook.destination.length > 0) {
              this.connectReprocessingRuleById(rule.id, destination, hook.destination, HookTypes.ReprocessingRuleDestination);
            }
          });
        });
      });
    });
  }

  connectReprocessingRuleById(ruleId: string, idA: string, idB: string, type: HookTypes) {
    const _this = this;

    const p1 = _this.getCoordinatesFromNode(idA);
    const p2 = _this.getCoordinatesFromNode(idB);

    const sys = _this.getSystemFromTypeId(idA);
    if (sys === undefined) {
      // to do see if i must return or handle
      return;
    }

    const rad = (_this.outerR + _this.innerR) / 2;
    const nr = _this.innerR;
    const delta = Math.PI / 100;

    const alpha = Math.acos(p1.x / rad);
    const theta = Math.acos(p2.x / rad);

    let linedata = [
      {x: nr * Math.cos(alpha + delta), y: Math.sign(p1.y) * nr * Math.sin(alpha + delta)},
      {x: 0, y: 0},
      {x: nr * Math.cos(theta - delta), y: Math.sign(p2.y) * nr * Math.sin(theta - delta)}
    ];

    if (idA === idB) {
      const gamma = Math.PI / 15;
      const coef = 0.8;
      const coef2 = 0.7;

      linedata = [
        {x: nr * Math.cos(alpha + delta), y: Math.sign(p1.y) * nr * Math.sin(alpha + delta)},
        {x: coef * nr * Math.cos(alpha + gamma), y: coef * Math.sign(p1.y) * nr * Math.sin(alpha + gamma)},
        {x: coef2 * nr * Math.cos(alpha), y: coef2 * Math.sign(p1.y) * nr * Math.sin(alpha)},
        {x: coef * nr * Math.cos(alpha - gamma), y: coef * Math.sign(p1.y) * nr * Math.sin(alpha - gamma)},
        {x: nr * Math.cos(theta - delta), y: Math.sign(p2.y) * nr * Math.sin(theta - delta)}
      ];
    }

    const lineFunc = d3.line()
      .x(function (d) {
        return d['x'];
      })
      .y(function (d) {
        return d['y'];
      })
      .curve(d3.curveBundle);


    let strokeStyle;
    let markerStyle;

    if (type === HookTypes.ReprocessingRule) {
      strokeStyle = '0,0';
      markerStyle = 'arrow';
    } else {
      strokeStyle = '4,3,1,3';
      markerStyle = 'arrow';
    }

    const hookG = _this.connGroup.append('g')
      .attr('class', 'rep-rule-line-g')
      .attr('rule', ruleId);

    hookG.append('path')
      .datum(linedata)
      .attrs({'d': lineFunc, 'fill': 'none', 'stroke-width': '2', 'stroke': _this.getColorFromNode(idA)})
      .styles({'stroke-dasharray': strokeStyle, 'opacity': '1'})
      .attr('marker-end', function () {
        return 'url(#' + markerStyle + '-' + sys.id + ')';
      });

  }

  toggleRuleArrows(rule: any) {
    rule.visible = !rule.visible;
    d3.selectAll(`[rule="${rule.id}"]`).classed('hidden', !rule.visible);
  }

  hideAllRuleArrows() {
    this.reprocessingRules.forEach(rule => {
      rule.visible = false;
    });
    d3.selectAll(`[rule]`).classed('hidden', true);
  }

  displaySelectedOptionChanged() {
    if (this.displaySelectedOption === 'show-hooks') {
      d3.selectAll('.links-line-g').classed('hidden', true);
      d3.selectAll('.rep-rule-line-g').classed('hidden', true);
      d3.selectAll('.hook-line-g').classed('hidden', false);
    } else if (this.displaySelectedOption === 'show-links') {
      d3.selectAll('.links-line-g').classed('hidden', false);
      d3.selectAll('.hook-line-g').classed('hidden', true);
      d3.selectAll('.rep-rule-line-g').classed('hidden', true);
    } else if (this.displaySelectedOption === 'show-rep-rules') {
      d3.selectAll('.hook-line-g').classed('hidden', true);
      d3.selectAll('.links-line-g').classed('hidden', true);
      d3.selectAll('.rep-rule-line-g').classed('hidden', false);
    }
  }

  connectLinks() {

    const typeLinksArray = [];
    const typeAppendLinksArray = [];

    this.systems.forEach(sys => {
      sys.types.forEach(typ => {
        (typ.links || []).forEach(link => {
          typeLinksArray.push({
            id: typ.id,
            desc: typ.description,
            link: link.type
          });
        });

        (typ.append_links || []).forEach(alink => {
          typeAppendLinksArray.push({
            id: typ.id,
            desc: typ.description,
            link: alink.type
          });
        });

      });
    });

    typeAppendLinksArray.forEach(apl => {
      typeLinksArray.filter(lk => lk.link === apl.link).forEach(lk => {
        this.connectLinksById(apl.id, lk.id);
      });
    });
  }

  connectLinksById(idA: string, idB: string) {
    const _this = this;

    //// const pc = {x: 0, y: 0};
    const p1 = _this.getCoordinatesFromNode(idA);
    const p2 = _this.getCoordinatesFromNode(idB);
    const sys = _this.getSystemFromTypeId(idA);

    const rad = (_this.outerR + _this.innerR) / 2;
    const nr = _this.innerR;
    const delta = Math.PI / 100;

    const alpha = Math.acos(p1.x / rad);
    const theta = Math.acos(p2.x / rad);

    let linedata = [
      {x: nr * Math.cos(alpha + delta), y: Math.sign(p1.y) * nr * Math.sin(alpha + delta)},
      {x: 0, y: 0},
      {x: nr * Math.cos(theta - delta), y: Math.sign(p2.y) * nr * Math.sin(theta - delta)}
    ];

    if (idA === idB) {
      const gamma = Math.PI / 15;
      const coef = 0.8;
      const coef2 = 0.7;

      linedata = [
        {x: nr * Math.cos(alpha + delta), y: Math.sign(p1.y) * nr * Math.sin(alpha + delta)},
        {x: coef * nr * Math.cos(alpha + gamma), y: coef * Math.sign(p1.y) * nr * Math.sin(alpha + gamma)},
        {x: coef2 * nr * Math.cos(alpha), y: coef2 * Math.sign(p1.y) * nr * Math.sin(alpha)},
        {x: coef * nr * Math.cos(alpha - gamma), y: coef * Math.sign(p1.y) * nr * Math.sin(alpha - gamma)},
        {x: nr * Math.cos(theta - delta), y: Math.sign(p2.y) * nr * Math.sin(theta - delta)}
      ];
    }

    const lineFunc = d3.line()
      .x(function (d) {
        return d['x'];
      })
      .y(function (d) {
        return d['y'];
      })
      .curve(d3.curveBasis);

    const strokeStyle = '8,1';
    const markerStyle = 'arrowAppend';

    const linksG = _this.connGroup.append('g')
      .attr('class', 'links-line-g');

    // Hook line
    linksG.append('path')
      .datum(linedata)
      .attrs({'d': lineFunc, 'fill': 'none', 'stroke-width': '2', 'stroke': _this.getColorFromNode(idA)})
      .styles({'stroke-dasharray': strokeStyle, 'opacity': '1'})
      .attr('marker-end', function () {
        return 'url(#' + markerStyle + '-' + sys.id + ')';
      });

    //// const typeDest = _this.getTypeFromId(idB);
  }

  selectType(id: string) {
    const connections = [];

    this.connGroup.selectAll('*').remove();
    this.typeConnGroup.selectAll('*').remove();

    if (this.selectedType) {
      d3.select('#' + this.selectedType + ' circle')
        .classed('select-type', false);
    }

    if (this.selectedType !== id) {

      this.selectedType = id;

      d3.select('#' + id + ' circle')
        .classed('select-type', true);

      this.systems.forEach(s => {
        s.types.forEach(t => {
          if (t.hooks) {
            if (t.hooks.post) {
              t.hooks.post.forEach(th => {
                if (t.id === id || th.destination === id) {
                  connections.push({
                    from: t.id,
                    to: th.destination,
                    type: HookTypes.PostHook,
                    commitAfterPublish: th.commit_after_publish,
                    onHold: th.on_hold
                  });
                }
              });
            }

            if (t.hooks.pre) {
              t.hooks.pre.forEach(tp => {
                const tpre: any = tp;
                if (t.id === id || tpre.type === id) {
                  connections.push({from: t.id, to: tpre.type, type: HookTypes.PreHook});
                }
              });
            }
          }
        });
      });

      for (let i = 0; i < connections.length; i++) {
        const con = connections[i];
        const options: ConnectHookOptions = {
          commitAfterPublish: con.commitAfterPublish,
          onHold: con.onHold
        };
        this.connectNodesToTypeById(id, con.from, con.to, con.type, options);
      }
      this.plotCenterType(id);

    } else {
      this.selectedType = '';

      d3.select('#' + id + ' circle')
        .classed('select-type', false);

      this.connectHooks();
      this.connectLinks();
      this.connectReprocessingRules();
      this.displaySelectedOptionChanged();
    }
  }

  textSize(text) {
    if (!d3) {
      return;
    }
    const container: any = this.svgGroup.append('g');
    const textCont = container.append('text').attrs({x: -99999, y: -99999}).text(text);
    const size = textCont.node().getComputedTextLength();
    container.remove();
    // return { width: size.width, height: size.height };

    return size;
  }

  plotCenterType(origin: string) {
    const _th = this;
    const systemType = _th.getSystemFromTypeId(origin);
    const originType = _th.getTypeFromId(origin);
    const typeInitials = _th.getDescription(originType.description, true);

    const typeCircle = _th.typeConnGroup.append('g')
      .attr('class', 'type-circle');

    typeCircle.append('circle')
      .attrs({'cx': 0, 'cy': 0, 'r': _th.circRad})
      .style('fill', _th.getColorFromNode(origin));

    // ============ TEXT GROUP - SYSTEM and TYPE infos

    const textGroup = typeCircle.append('g')
      .attr('transform', 'translate(0,' + -0.7 * _th.circRad + ')');

    textGroup.append('text')
      .text(typeInitials)
      .attrs({'content-area': d3.select('.type-circle'), 'transform': 'translate(0,0)', 'pointer-events': 'none'})
      .styles({'font-size': '20', 'font-weight': 'bold', 'fill': '#fff', 'text-anchor': 'middle'});

    textGroup.append('text')
      .text(originType.description)
      .attrs({'transform': 'translate(0,20)', 'pointer-events': 'none'})
      .styles({'font-size': '12', 'font-weight': 'bold', 'fill': '#fff', 'text-anchor': 'middle'});

    textGroup.append('text')
      .text(systemType.alias)
      .attrs({'transform': 'translate(0,36)', 'pointer-events': 'none'})
      .styles({'font-style': 'italic', 'font-size': '12', 'fill': '#fff', 'text-anchor': 'middle'});

    this.cronInfoGroup = typeCircle.append('g');

    // Text below buttons
    const schedulers = originType.schedulers;
    if (originType.commit) {
      let schedulerCommit = schedulers.find(sched => sched.operation === 'COMMIT');
      if (!schedulerCommit) {
        schedulerCommit = {cron_format: 'null'} as ITypeScheduleData;
      }
      const cronInterval = originType.commit['cron_interval'] || null;
      this.cronInfoGroup.append('g').append('text')
        .text(`C: Cron: ${cronInterval} / Sched: ${schedulerCommit.cron_format}`)
        .attrs({'transform': 'translate(0,45)', 'pointer-events': 'none'})
        .styles({'font-size': '12', 'font-weight': 'bold', 'fill': '#fff', 'text-anchor': 'middle'});
    }

    if (originType.task) {
      let schedulerTask = schedulers.find(sched => sched.operation === 'TASK');
      if (!schedulerTask) {
        schedulerTask = {cron_format: 'null'} as ITypeScheduleData;
      }
      const cronInterval = originType.task['cron_interval'] || null;
      this.cronInfoGroup.append('g').append('text')
        .text(`T: Cron: ${cronInterval} / Sched: ${schedulerTask.cron_format}`)
        .attrs({'transform': 'translate(0,65)', 'pointer-events': 'none'})
        .styles({'font-size': '12', 'font-weight': 'bold', 'fill': '#fff', 'text-anchor': 'middle'});
    }


    // ============ ACTIONS GROUP
    const style = {
      'button': {
        'mouseout': {
          'fill': '#ccc'
        },
        'mouseover': {
          'fill': '#fff'
        }
      }
    };
    const buttonH = 30;
    const btnMargin = 5;
    const buttonW = 120;
    const iconSize = 0.7;

    this.actionGroup = typeCircle.append('g').attr('class', 'action-menu-group')
      .attr('transform', 'translate(0,' + 0.7 * _th.circRad + ')');

    // var colOne = [-1.5, -0.5, 0.5, 1.5];
    const colOne = [-2, -1, 0, 1, 2];
    const colTwo = [-2, -1, 0, 1, 2];
    const rows = [-3.5, -2.4];

    const createButton = (btnIcon, btnClass, label, message, colValue, rowValue, clickFunction) => {
      const actionMenuEntry = this.actionGroup.append('g').attr('class', btnClass);
      actionMenuEntry.attr('transform', 'translate(' + colValue * (buttonH + btnMargin) + ',' + rowValue * buttonH + ')');
      actionMenuEntry.append('rect')
        .attrs({x: -(buttonH + btnMargin) / 2, y: -buttonH / 2, width: buttonH, height: buttonH, rx: 10, ry: 10})
        .styles({'cursor': 'pointer', 'fill': '#ccc'})
        .on('click', function () {
          clickFunction();
        })
        .on('mouseover', function () {
          d3.select(this).styles(style.button.mouseover);
        })
        .on('mouseout', function () {
          d3.select(this).styles(style.button.mouseout);
        });

      const aux: any = d3.select(`.${btnClass}`);
      const icon = aux.node().appendChild(_th.iconsList[btnIcon].cloneNode(true));

      d3.select(icon).attrs({
        height: iconSize * buttonH,
        width: iconSize * buttonH,
        x: iconSize * (-(buttonH + btnMargin) / 2),
        y: iconSize * (-buttonH / 2), 'pointer-events': 'none'
      });

      this.actionGroup.selectAll(`.${btnClass}`).datum({label: label, message: message});
      this.defineTooltip(this.actionGroup.selectAll(`.${btnClass}`));
    };

    // ====== GENERIC DATA BUTTON
    createButton('database', 'action-menu-entry', 'View Generic Data', '', colOne[3], rows[0], function () {
      _th.customSidebarService.expand('settings-sidebar', SidebarType.Data);
      localStorage.setItem('generic-data-idFrom', origin);
      _th.router.navigate(['/pages', {outlets: {sideRoute: 'generic-data/' + origin}}], {queryParamsHandling: 'merge'});
    });

    // ====== EDIT TYPE BUTTON
    createButton('edit', 'edit-menu-entry', 'Edit Type', '', colOne[2], rows[0], function () {
      _th.editType(originType);
    });

    // ====== COPY ID FROM TYPE BUTTON
    createButton('copy-regular', 'copyid-menu-entry', 'Copy Type ID', '', colOne[4], rows[0], function () {
      _th._clipboardService.copyFromContent(originType.id);
      _th._toast.showToast('success', 'Copy Type ID', 'Type ID copied successfully');
    });

    // ====== DELETE TYPE BUTTON
    createButton('trash-alt', 'delete-menu-entry', 'Delete Type', '', colTwo[3], rows[1], function () {
      _th.deleteType(originType);
    });

    // ====== CRON SCHEDULE BUTTON
    createButton('clock-regular', 'schedule-menu-entry', 'Schedule Type', '', colTwo[4], rows[1], function () {
      const modalData = {title: 'Scheduler', data: originType, name: originType.description} as IModalData;
      _th._modals.showScheduleTypeModal(modalData).subscribe(response => {
        _th.loadData();
      });
    });

    // ====== HISTORY BUTTON
    createButton('history', 'history-menu-entry', 'Open History', '', colTwo[1], rows[1], function () {
      const modalData = {title: 'View History', data: originType.id, name: originType.description};
      _th._modals.showHistoryModal(modalData).subscribe(response => {
      });
    });

    // ====== EDIT LINKS BUTTON
    createButton('anchor', 'editlinks-menu-entry', 'Edit Links / Append links', '', colTwo[0], rows[1], function () {
      _th.openEditLinks(originType.id);
    });

    // ====== ADD NEW <<< POST >>> HOOK BUTTON
    createButton('link', 'add-hook-menu-entry', 'Add New POST Hook', '', colOne[0], rows[0], function () {
      _th.addNewHook(originType, 'post');
    });

    // ====== ADD NEW <<< PRE >>> HOOK BUTTON
    createButton('arrow-alt-circle-left', 'add-pre-hook-menu-entry', 'Add New PRE Hook', '', colOne[1], rows[0], function () {
      _th.addNewHook(originType, 'pre');
    });

    // ====== ON HOLD BUTTON
    createButton(originType.on_hold ? 'play' : 'pause',
      'on-hold-menu-entry',
      'Type is: ' + (originType.on_hold ? 'ON HOLD' : 'NOT HELD'),
      'Set Type to: ' + (!originType.on_hold ? 'ON HOLD' : 'NOT HELD'),
      colTwo[2],
      rows[1],
      function () {
        _th.toggleOnHoldType(originType);
      });

    // ========================================================================================
    this.addHookGroup = typeCircle.append('g').attr('class', 'add-hook-group')
      .attr('transform', 'translate(0,' + 0.6 * _th.circRad + ')');

    // ====== CANCEL ADD NEW HOOK BUTTON
    const cancelAddHookMenuEntry = this.addHookGroup.append('g').attr('class', 'cancel-add-hook-menu-entry');
    cancelAddHookMenuEntry.attr('transform', 'translate(' + 0 + ',' + -buttonH + ')');
    cancelAddHookMenuEntry.append('rect')
      .attrs({x: -buttonW / 2, y: -buttonH / 2, width: buttonW, height: buttonH, rx: 10, ry: 10})
      .styles({'cursor': 'pointer', 'fill': '#ccc'})
      .on('click', function () {
        _th.cancelAddNewHook();
      })
      .on('mouseover', function () {
        d3.select(this).styles(style.button.mouseover);
      })
      .on('mouseout', function () {
        d3.select(this).styles(style.button.mouseout);
      });

    cancelAddHookMenuEntry.append('g').append('text')
      .text('Cancel')
      .attrs({'pointer-events': 'none'})
      .styles({'font-size': '12', 'font-weight': 'bold', 'fill': '#000', 'text-anchor': 'middle', 'alignment-baseline': 'middle'});

    const selectDestinationHook = this.addHookGroup.append('g').attr('class', 'select-dest-hook');
    selectDestinationHook.attr('transform', 'translate(' + 0 + ',' + -2 * buttonH + ')');
    selectDestinationHook.append('g').append('text')
      .text('Select Destination Type')
      .attrs({'pointer-events': 'none'})
      .styles({'font-size': '14', 'font-weight': 'bold', 'fill': '#FFF', 'text-anchor': 'middle', 'alignment-baseline': 'middle'});

    this.addHookGroup.classed('hidden', true);

    this.typeConnGroup.raise();
  }

  connectNodesToTypeById(origin: string, from: string, to: string, type: HookTypes = HookTypes.PostHook, options?: ConnectHookOptions) {
    const _this = this;

    //// const pc = {x: 0, y: 0};
    const p1 = _this.getCoordinatesFromNode(from);
    const p2 = _this.getCoordinatesFromNode(to);

    const sys = _this.getSystemFromTypeId(from);
    //// const typeOrigin = _this.getTypeFromId(from);
    const typeDest = _this.getTypeFromId(to);

    const rad = (_this.outerR + _this.innerR) / 2;

    const nr = _this.innerR;
    const nrHov = _this.innerR - 10;

    const circRad = _this.circRad;
    const circRadHov = circRad + 10;

    const delta = Math.PI / 100;

    const alpha = Math.acos(p1.x / rad);
    const theta = Math.acos(p2.x / rad);

    let linedata = [];
    let linedataHov = [];

    const offMultA = 1.5;
    const offMultB = 0.7;

    if (origin === from) {
      const offset = 0;

      linedata = [
        {
          x: circRad * Math.cos(theta + delta + offMultA * offset),
          y: Math.sign(p2.y) * circRad * Math.sin(theta + delta + offMultA * offset)
        },
        {
          x: nr * Math.cos(theta + delta + offMultB * offset),
          y: Math.sign(p2.y) * nr * Math.sin(theta + delta + offMultB * offset)
        }
      ];

      linedataHov = [
        {
          x: circRad * Math.cos(theta + delta + offMultA * offset),
          y: Math.sign(p2.y) * circRad * Math.sin(theta + delta + offMultA * offset)
        },
        {
          x: nrHov * Math.cos(theta + delta + offMultB * offset),
          y: Math.sign(p2.y) * nrHov * Math.sin(theta + delta + offMultB * offset)
        }
      ];
    } else if (origin === to) {
      const offset = 0;

      linedata = [
        {
          x: nr * Math.cos(alpha - delta - offMultB * offset),
          y: Math.sign(p1.y) * nr * Math.sin(alpha - delta - offMultB * offset)
        },
        {
          x: circRad * Math.cos(alpha - delta - offMultA * offset),
          y: Math.sign(p1.y) * circRad * Math.sin(alpha - delta - offMultA * offset)
        },
      ];

      linedataHov = [
        {
          x: nr * Math.cos(alpha - delta - offMultB * offset),
          y: Math.sign(p1.y) * nr * Math.sin(alpha - delta - offMultB * offset)
        },
        {
          x: circRadHov * Math.cos(alpha - delta - offMultA * offset),
          y: Math.sign(p1.y) * circRadHov * Math.sin(alpha - delta - offMultA * offset)
        },
      ];
    }

    const lineFunc = d3.line()
      .x(function (d) {
        return d['x'];
      })
      .y(function (d) {
        return d['y'];
      })
      .curve(d3.curveBundle);

    let strokeStyle = '0,0';
    let markerStyle = 'arrow';
    if (type === HookTypes.PreHook) {
      strokeStyle = '4,4';
      markerStyle = 'diamond';
    }

    const commitAfterPublish = (options && options.commitAfterPublish) || false;
    const hookOnHold = (options && options.onHold) || false;

    if (commitAfterPublish) {
      markerStyle = 'arrowDouble';
    }

    const lineGroup = _this.typeConnGroup.append('g')
      .attr('class', 'line-conn-group');

    lineGroup.append('path')
      .datum(linedataHov)
      .attrs({
        'class': 'type-conn-hov',
        'd': lineFunc,
        'id': 'thov-' + type + '-' + from + '-' + to,
        'stroke-width': '10',
        'stroke': 'none',
        'pointer-events': 'all',
        'marker-end': 'none'
      })
      .style('fill', 'none')
      .on('click', function () {
        d3.event.preventDefault();
        //// const lineId = d3.select(this).attr('id');
        _this.createMenu(from, to, type, d3.mouse(this)[0], d3.mouse(this)[1], hookOnHold);
      });

    lineGroup.append('path')
      .attr('class', 'type-conn')
      .datum(linedata)
      .attrs({
        'd': lineFunc,
        'id': 'thov-' + type + '-' + from + '-' + to,
        'stroke-width': '4',
        'stroke': _this.getColorFromNode(from),
        'pointer-events': 'none',
        'marker-end': 'url(#' + markerStyle + '-' + sys.id + ')'
      })
      .styles({'fill': 'none', 'stroke-dasharray': strokeStyle});

    this.typeConnGroup.raise();

    d3.selectAll('.type-conn-hov')
      .on('mousemove', function (d) {
        const pathId = d3.select(this).attr('id');
        const _th: any = this;
        const sibling = d3.select<any, any>(_th.nextSibling);

        d3.select('#' + pathId)
          .styles({'stroke': sibling.attr('stroke'), 'opacity': '0.6'});

        d3.selectAll('.type-conn-group .line-conn-group .type-conn:not(#' + pathId + ')')
          .style('opacity', '0.4');
      })
      .on('mouseover', function (d) {
      })
      .on('mouseout', function (d) {
        const pathId = d3.select(this).attr('id');

        d3.select('#' + pathId)
          .style('stroke', 'none');

        d3.selectAll('.type-conn-group .line-conn-group .type-conn:not(#' + pathId + ')')
          .style('opacity', '1');
      });


    if (commitAfterPublish) {
      if (origin === from && typeDest && typeDest.cron_schedule_commit != null) {
        lineGroup.append('circle')
          .attrs({'cx': 0.85 * nr * Math.cos(theta + delta), 'cy': 0.85 * Math.sign(p2.y) * nr * Math.sin(theta + delta), 'r': 4})
          .style('fill', 'red');
      } else if (origin === to && typeDest && typeDest.cron_schedule_commit != null) {
        lineGroup.append('circle')
          .attrs({
            'cx': 1.25 * circRad * Math.cos(alpha - delta),
            'cy': 1.25 * Math.sign(p1.y) * circRad * Math.sin(alpha - delta),
            'r': 4
          })
          .style('fill', 'red');
      }
    }

    if (hookOnHold) {
      const p1x1 = linedata[0].x;
      const p1y1 = linedata[0].y;
      const ang1 = Math.atan(p1y1 / p1x1) * 180 / Math.PI;

      if (origin === from && typeDest && typeDest.on_hold != null) {

        lineGroup.append('path')
          .attr('d', 'M 1 4 L3 4 L3 -4 L1 -4 M-1 4 L-3 4 L-3 -4 L-1 -4 Z')
          .attr('transform',
            'translate(' + 0.8 *
            nr *
            Math.cos(theta + delta) + ',' +
            0.8 *
            Math.sign(p2.y) *
            nr *
            Math.sin(theta + delta) +
            ')rotate(' + ang1 + ')')
          .style('fill', 'red');

      } else if (origin === to && typeDest && typeDest.on_hold != null) {

        lineGroup.append('path')
          .attr('d', 'M 1 4 L3 4 L3 -4 L1 -4 M-1 4 L-3 4 L-3 -4 L-1 -4 Z')
          .attr('transform',
            'translate(' + 1.3 *
            circRad *
            Math.cos(alpha - delta) + ',' +
            1.3 *
            Math.sign(p1.y) *
            circRad *
            Math.sin(alpha - delta) +
            ')rotate(' + ang1 + ')')
          .style('fill', 'red');

      }
    }
  }

  getCoordinatesFromNode(id: string): any {
    const point = {x: 0, y: 0};
    const elem = d3.select('#' + id);
    if (!elem.empty()) {
      const transf = elem.attr('transform');
      const n1 = transf.substring(transf.indexOf('(') + 1, transf.indexOf(','));
      const n2 = transf.substring(transf.indexOf(',') + 1, transf.indexOf(')'));
      point.x = +n1;
      point.y = +n2;
    }
    return point;
  }

  getColorFromNode(id: string): string {
    let nodeColor: string;
    d3.select('#' + id).each(function (node) {
      const _this: any = this;
      nodeColor = d3.select(_this.firstChild).style('fill');
    });
    return nodeColor;
  }

  getSystemFromTypeId(id: string): System {
    const rid = id.replace('#', '');
    return this.systems.filter(s => {
      return s.types.filter(t => {
        return t.id === rid;
      })[0];
    })[0];
  }

  getTypeFromId(id: string): Type {
    const rid = id.replace('#', '');
    return this.allTypes[rid];
  }

  getDescription(text: string, short: boolean = false): string {
    const max = 20;
    let ellipsis = '';
    const name = text;
    let result = name;
    if (name && name !== '') {
      if (short) {
        const initials = name.match(/\b\w/g) || [];
        const first = initials.shift() || '';
        const second = initials.shift() || '';
        result = (first + second).toUpperCase();
      } else {
        ellipsis = (name.length >= max ? '...' : '');
      }
    } else {
      result = '+ New Type';
    }
    result = result.substring(0, max) + ellipsis;
    return result;
  }

  createMenu(idFrom, idTo, hookType, x, y, hookOnHold: boolean = false) {
    const _this = this;

    const items = [
      {action: 'edit', doc: 'edit'},
      {action: 'close', doc: 'times'},
      {action: 'delete', doc: 'trash-alt'}
    ];

    if (hookType === 'post-hook') {
      items.push({action: 'view-queue', doc: 'eye'});
      items.push({action: 'pause-hook', doc: hookOnHold ? 'play' : 'pause'});

    }

    const menuData = [{
      from: idFrom,
      to: idTo,
      type: hookType,
      items: items
    }];

    const style = {
      'circle': {
        'mouseout': {
          'fill': '#FFF',
          'stroke': '#333',
          'stroke-width': '2px'
        },
        'mouseover': {
          'fill': '#41dd7d'
        }
      },
      'text': {
        'fill': '#333',
        'font-size': '14'
      }
    };

    d3.select('.context-menu').remove();

    this.svg.selectAll('.context-menu')
      .data(menuData)
      .enter().append('g').attr('class', 'context-menu')
      .selectAll('.menu-entry')
      .data(function (d) {
        return d.items;
      })
      .enter().append('g').attr('class', 'menu-entry')
      .style('cursor', 'pointer')
      .on('mouseover', function () {
        d3.select(this).select('circle').styles(style.circle.mouseover);
      })
      .on('mouseout', function () {
        d3.select(this).select('circle').styles(style.circle.mouseout);
      });

    const cGroup = d3.selectAll('.menu-entry')
      .append('g');

    cGroup.append('circle')
      .attrs({cx: 0, cy: 0, r: 15})
      .styles(style.circle.mouseout);

    cGroup.each(function (d) {
      const cg: any = this;
      const icon = d3.select(cg).node().appendChild(_this.iconsList[d['doc']].cloneNode(true));
      d3.select(icon).attrs({height: 20, width: 20, x: -10, y: -10, transform: 'translate(0,0)'});
    });

    d3.select('.context-menu')
      .attr('transform', function (d) {
        return 'translate(' + x + ',' + y + ')';
      })
      .style('opacity', '0')
      .transition()
      .style('opacity', '1');


    d3.selectAll('.menu-entry')
      .on('click', function () {
        const _th: any = this;
        const menuParent = d3.select(_th.parentNode);
        const menuItem = d3.select(this);

        const _idFrom = menuParent.data()[0]['from'];
        const _idTo = menuParent.data()[0]['to'];
        const _hookType = menuParent.data()[0]['type'];
        const action = menuItem.data()[0]['action'];

        console.log(menuParent.data()[0]);

        if (action === 'close') {
          d3.select('.context-menu').remove();
        } else if (action === 'view-queue') {
          _this.customSidebarService.expand('settings-sidebar', SidebarType.Queue);
          localStorage.setItem('queue-idFrom', _idFrom);
          localStorage.setItem('queue-idTo', _idTo);
          _this.router.navigate(['/pages', {outlets: {sideRoute: 'queue/' + _idFrom + '/' + _idTo}}], {queryParamsHandling: 'merge'});
        } else if (action === 'edit') {
          _this.editHook(_hookType, _idFrom, _idTo);
        } else if (action === 'delete') {
          _this.deleteHook(_hookType, _idFrom, _idTo);
        } else if (action === 'pause-hook') {
          _this.pauseHook(_idFrom, _idTo);
        }
      })
      .transition()
      .attr('transform', function (d, i) {
        const rad = 30;
        const alpha = 2 * Math.PI / items.length;
        return 'translate(' + rad * Math.cos(i * alpha) + ',' + rad * Math.sin(i * alpha) + ')';
      });
  }

  openEditLinks(id) {

    // Get last Generic Data
    const urlObj = {fetch_qty: 1};
    const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
      return [k, urlObj[k]].join('=');
    }).join('&');
    const getUrl = 'genericdata/' + id + '?' + urlParams;

    this.setLoading();

    this.api.get(getUrl).then(response => {
      if (response && response.results) {

        let originData = {};
        if (response.results.length > 0) {
          const res = response.results[0];
          originData = res.structure;
        }

        this.ctmService.setOriginData(originData);
        this.loaded();

        const modalData = {title: 'Edit Links', data: id};
        this._modals.showEditLinkModal(modalData).subscribe(modalResponse => {

          if (modalResponse && modalResponse.success) {

            const editLinks = modalResponse.payload.links;
            const editAppendLinks = modalResponse.payload.appendLinks;

            this.setLoading();

            this.api.put('type/' + id, {links: editLinks, append_links: editAppendLinks}).then(() => {
              console.log('TYPE LINKS UPDATED');
              this.loaded();
              this.loadData();
            }).catch(error => {
              console.error(error);
              this._toast.showToast('error', 'PUT Error', 'Error updating type!');
              this.loaded();
            });
          }
        });
      }
    });
  }

  getLabel(id) {
    return this._typeService.getSystemTypeLabel(id);
  }

  parseSystem(system: System) {
    return {
      alias: system.alias,
      connection_details: system.connection_details,
      third_party_auth_id: system.third_party_auth_id
    };
  }

  editSystem(system) {
    const sysId = system.id;

    // console.log("SYSTEM: alias: " + system.alias + " | id: " + sysId);

    this.setLoading();

    this.api.get('system/' + sysId).then(response => {
      const editSystem = this.parseSystem(response);

      const modalData = {
        title: 'Edit System',
        data: editSystem,
        created_at: response.created_at,
        updated_at: response.updated_at,
        settings: this.userSettings.config.systems[sysId]
      };

      this.loaded();

      this._modals.showEditSystemModal(modalData).subscribe(data => {
        if (data.success && data.payload) {
          this.setLoading();

          this.userSettings.config.systems[sysId] = data.payload.settings;

          const modSystem = this.parseSystem(data.payload.system);

          Promise.all([
            this.api.put('system/' + sysId, modSystem),
            this.api.put('customer/' + this.loggedAsUser.id, {settings: this.userSettings})
          ]).then(([sys, cust]) => {
            return this._login.updateUsersList();
          })
            .then(() => {
              this._toast.showToast('success', 'Edit System', 'System: ' + modSystem.alias + ' edited successfully!');
              this.loadData();
            })
            .catch(error => {
              console.error(error);
              this._toast.showToast('error', 'PUT Error', 'Error updating system!');
              this.loaded();
            });
        }
      });
    }).catch(error => {
      console.error(error);
      this._toast.showToast('error', 'GET Error', 'Error getting system!');
      this.loaded();
    });
  }

  openHistory(id, name) {
    var modalData = { title: "View History", data: id, name: name };
    this._modals.showHistoryModal(modalData).subscribe(response => {
    });
  }

  parseType(type) {
    return {
      append_links: type.append_links,
      commit: type.commit,
      cron: type.cron,
      cron_schedule_commit: type.cron_schedule_commit,
      cron_schedule_task: type.cron_schedule_task,
      description: type.description,
      links: type.links,
      priority: type.priority,
      task: type.task,
      trigger_hooks_task: type.trigger_hooks_task,
      public_name: type.public_name,
    };
  }

  editType(type) {
    const typeId = type.id;

    console.log('TYPE: description: ' + type.description + ' | id: ' + typeId);

    this.setLoading();

    this.api.get('type/' + typeId).then(response => {

      const editType = this.parseType(response);
      const modalData = {
        title: 'Edit Type',
        data: editType,
        created_at: response.created_at,
        updated_at: response.updated_at,
      };

      this.loaded();

      this._modals.showEditTypeModal(modalData).subscribe(data => {
        if (data.success && data.payload) {
          this.setLoading();
          const modType = this.parseType(data.payload);
          this.api.put('type/' + typeId, modType).then(() => {
            this._toast.showToast('success', 'Success', 'Success updating type!');
            this.loadData();
          }).catch(error => {
            console.error(error);

            let msg = '';
            if (error._body) {
              const json = JSON.parse(error._body);
              msg = json.message;
            }

            this._toast.showToast('error', 'PUT Error', 'Error updating type! ' + msg);
            this.loaded();
          });
        }
      });
    }).catch(error => {
      console.error(error);
      this._toast.showToast('error', 'GET Error', 'Error getting type!');
      this.loaded();
    });
  }

  addNewSystem() {

    const newSystem = {
      alias: ''
    };

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'alias',
        label: 'System Alias',
        value: '',
        required: true,
        order: 1
      })
    ];

    const formData = {
      title: 'Add new System',
      questions: questions,
      flipEnabled: false
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        this.setLoading();
        const payload = res.payload;
        this.api.post('system', payload).then(() => {
          this.loadData();
        }).catch(error => {
          console.error(error);
          this._toast.showToast('error', 'POST Error', 'Error adding system!');
          this.loaded();
        });
      }
    }).catch(error => {
      console.error(error);
      this._toast.showToast('error', 'Error', 'Error showing Form Modal!');
    });
  }

  addNewType(systemId) {

    const newType = {
      description: ''
    };

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'description',
        label: 'Type Description',
        value: '',
        required: true,
        order: 1
      })
    ];

    const formData = {
      title: 'Add new Type',
      questions: questions,
      flipEnabled: false
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        this.setLoading();
        const payload = res.payload;
        payload['system_id'] = systemId;
        payload['on_hold'] = true;
        this.api.post('type', payload).then(() => {
          this.loadData();
        }).catch(error => {
          console.error(error);
          this._toast.showToast('error', 'POST Error', 'Error adding type!');
          this.loaded();
        });
      }
    }).catch(error => {
      console.error(error);
      this._toast.showToast('error', 'Error', 'Error showing Form Modal!');
    });
  }

  deleteSystem(system) {
    this._modals.showConfirmModal('Delete System?', 'Do you want to delete the system ' + system.alias + '?').then(cRes => {
      if (cRes && cRes.success) {
        this.setLoading();
        this.api.api_delete('system/' + system.id).then(res => {
          if (res.success) {
            this.loadData();
          } else {
            this.loaded();
          }
        }).catch(error => {
          console.error(error);
          this._toast.showToast('error', 'DELETE Error', 'Error deleting system!');
          this.loaded();
        });
      }
    });
  }

  deleteType(type) {
    const id = type.id;
    let depsNumber = 0;
    depsNumber += Object.values(this.allTypes).filter(elem => {
      if (!this.isEmptyType(elem) && elem.hooks) {
        return (elem.hooks.post && elem.hooks.post.filter(el => el.destination === id).length > 0) ||
          (elem.hooks.pre && elem.hooks.pre.filter(el => el.type === id).length > 0);
      }
    }).length;

    if (type.hooks) {
      if (type.hooks.post && type.hooks.post.length > 0) {
        depsNumber += type.hooks.post.length;
      }
      if (type.hooks.pre && type.hooks.pre.length > 0) {
        depsNumber += type.hooks.pre.length;
      }
    }

    if (depsNumber > 0) {
      this._modals.showConfirmModal(`Alert`, `This type has dependencies with hooks/pre-hooks, delete them and try again.`, false);
    } else {
      this._modals.showConfirmModal('Delete Type?', 'Do you want to delete the type ' + type.description + '?').then(cRes => {
        if (cRes && cRes.success) {
          this.setLoading();
          this.api.api_delete('type/' + type.id).then(res => {
            if (res.success) {
              this.loadData();
            } else {
              this.loaded();
            }
          }).catch(error => {
            console.error(error);
            this._toast.showToast('error', 'DELETE Error', 'Error deleting type!');
            this.loaded();
          });
        }
      });
    }
  }

  isEmptyType(type) {
    return type instanceof EmptyType;
  }

  toggleOnHoldType(type) {
    // console.log(type);
    this.api.put('type/' + type.id, {on_hold: !type.on_hold}).then(() => this.loadData()).catch(error => {
      console.error(error);
      this._toast.showToast('error', 'PUT Error', 'Error changing type hold status!');
    });
  }

  // ======== HOOK FUNCTIONS ============================================

  createHook(type, idFrom, idTo) {
    // Get last Generic Data
    const urlObj = {fetch_qty: 1};
    const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
      return [k, urlObj[k]].join('=');
    }).join('&');
    const getUrl = 'genericdata/' + idFrom + '?' + urlParams;

    this.setLoading();

    this.api.get(getUrl).then(response => {
      if (response && response.results) {

        let originData = {};
        if (response.results.length > 0) {
          const res = response.results[0];
          originData = res.structure;
        }

        this.ctmService.setOriginData(originData);
        this.loaded();

        if (type === 'post-hook') {

          const postModalData = {title: 'Add New Post Hook', data: {}, typeFrom: idFrom, typeTo: idTo, originData: originData};
          this._modals.showHookModal(postModalData).subscribe(modalResponse => {
            if (modalResponse && modalResponse.success) {
              const payload = modalResponse.payload;

              const structure = payload.hookStructure;
              const commitAfterPublish = payload.commitAfterPublish;
              const onHold = payload.onHold;

              if (!this.addHookOriginType.hooks.post) {
                this.addHookOriginType.hooks.post = [];
              }

              const newHook = {
                action: 'publish',
                destination: idTo,
                structure: structure,
                commit_after_publish: commitAfterPublish,
                on_hold: onHold
              };
              this.addHookOriginType.hooks.post.push(newHook);

              const fromId = this.addHookOriginType.id;

              this.api.put('type/' + fromId, {hooks: this.addHookOriginType.hooks}).then(() => {
                console.log('POST HOOK SAVED');
                this.cancelAddNewHook();
                this.loadData();
              }).catch(error => {
                console.error(error);
                this._toast.showToast('error', 'PUT Error', 'Error editing type!');
              });
            } else {
              this.cancelAddNewHook();
              this.loaded();
            }
          });
        }

        if (type === 'pre-hook') {

          const preModalData = {title: 'Add New Pre Hook', data: {}, typeFrom: idFrom, typeTo: idTo, originData: originData};
          this._modals.showPreHookModal(preModalData).subscribe(modalResponse => {
            if (modalResponse && modalResponse.success) {
              const property = modalResponse.payload;

              if (!this.addHookOriginType.hooks.pre) {
                this.addHookOriginType.hooks.pre = [];
              }

              const newPreHook = {property: property, type: idTo};
              this.addHookOriginType.hooks.pre.push(newPreHook);

              const fromId = this.addHookOriginType.id;

              this.api.put('type/' + fromId, {hooks: this.addHookOriginType.hooks}).then(() => {
                console.log('PRE HOOK SAVED');
                this.cancelAddNewHook();
                this.loadData();
              }).catch(error => {
                console.error(error);
                this._toast.showToast('error', 'PUT Error', 'Error editing type!');
              });
            } else {
              this.cancelAddNewHook();
              this.loaded();
            }
          });
        }
      }
    }).catch(error => {
      console.error(error);
      this._toast.showToast('error', 'GET Error', 'Error getting generic data!');
      this.loaded();
    });
  }

  editHook(type: string, idFrom: string, idTo: string) {

    // Get last Generic Data
    const urlObj = {fetch_qty: 1};
    const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
      return [k, urlObj[k]].join('=');
    }).join('&');
    const getUrl = 'genericdata/' + idFrom + '?' + urlParams;

    this.setLoading();

    this.api.get(getUrl).then(response => {
      if (response && response.results) {

        let originData = {};
        if (response.results.length > 0) {
          const res = response.results[0];
          originData = res.structure;
        }

        this.ctmService.setOriginData(originData);
        this.loaded();


        this.setLoading();
        this.api.get('type/' + idFrom).then(typeResponse => {
          this.loaded();
          if (typeResponse) {
            this.allTypes[idFrom] = typeResponse;

            if (type === 'post-hook') {
              if (this.allTypes[idFrom].hooks && this.allTypes[idFrom].hooks.post.length > 0) {
                const hook = typeResponse.hooks.post.filter(elem => {
                  return elem.destination === idTo;
                })[0];

                const modalData = {
                  title: 'Edit Post Hook',
                  typeFrom: idFrom,
                  typeTo: idTo,
                  data: hook,
                  originData: originData,
                  dataType: 'hook'
                };

                this._modals.showHookModal(modalData).subscribe(modalResponse => {

                  if (modalResponse && modalResponse.success && modalResponse.payload) {
                    const structure = modalResponse.payload.hookStructure;
                    const commitAfterPublish = modalResponse.payload.commitAfterPublish;
                    const onHold = modalResponse.payload.onHold;

                    this.setLoading();

                    this.api.get('type/' + idFrom).then(typeResponse2 => {

                      if (typeResponse2 && typeResponse2.hooks && typeResponse2.hooks.post) {
                        const pHooks = typeResponse2.hooks.post.map(elem => {
                          if (elem.destination === idTo) {
                            elem.structure = structure;
                            elem.commit_after_publish = commitAfterPublish;
                            elem.on_hold = onHold;
                          }
                          return elem;
                        });

                        if (pHooks) {
                          this.api.put('type/' + idFrom, {hooks: typeResponse2.hooks}).then(() => {
                            console.log('POST HOOK UPDATED');
                            this._toast.showToast('success', 'Success', 'Success updating Post Hook!');
                            this.loaded();
                            this.loadData();
                          }).catch(error => {
                            console.error(error);
                            this._toast.showToast('error', 'PUT Error', 'Error updating type!');
                            this.loaded();
                          });
                        } else {
                          this.loaded();
                        }
                      } else {
                        this.loaded();
                      }

                    }).catch(error => {
                      console.error(error);
                      this._toast.showToast('error', 'GET Error', 'Error getting type!');
                      this.loaded();
                    });
                  }
                });
              }
            }

            if (type === 'pre-hook') {
              if (this.allTypes[idFrom].hooks && this.allTypes[idFrom].hooks.pre.length > 0) {
                const preHook = this.allTypes[idFrom].hooks.pre.filter(elem => {
                  return elem.type === idTo;
                })[0];

                const preModalData = {
                  title: 'Edit Pre Hook',
                  data: preHook,
                  typeFrom: idFrom,
                  typeTo: idTo,
                  originData: originData, dataType: 'hook'
                };

                this._modals.showPreHookModal(preModalData).subscribe(modalResponse => {
                  if (modalResponse && modalResponse.success) {
                    const property = modalResponse.payload;

                    this.setLoading();

                    this.api.get('type/' + idFrom).then(typeResponse3 => {

                      if (typeResponse3 && typeResponse3.hooks && typeResponse3.hooks.pre) {
                        const pHooks = typeResponse3.hooks.pre.map(elem => {
                          if (elem.type === idTo) {
                            elem.property = property;
                          }
                          return elem;
                        });

                        if (pHooks) {
                          this.api.put('type/' + idFrom, {hooks: typeResponse3.hooks}).then(() => {
                            console.log('PRE HOOK UPDATED');
                            this._toast.showToast('success', 'Success', 'Success updating Pre Hook!');
                            this.loaded();
                            this.loadData();
                          }).catch(error => {
                            console.error(error);
                            this._toast.showToast('error', 'PUT Error', 'Error updating type!');
                            this.loaded();
                          });
                        } else {
                          this.loaded();
                        }

                      } else {
                        this.loaded();
                      }

                    }).catch(error => {
                      console.error(error);
                      this._toast.showToast('error', 'GET Error', 'Error getting type!');
                      this.loaded();
                    });
                  }
                });
              }
            }
          }
        }).catch(error => {
          console.error(error);
          this._toast.showToast('error', 'GET Error', 'Error getting type!');
          this.loaded();
        });
      }

    }).catch(error => {
      console.error(error);
      this._toast.showToast('error', 'GET Error', 'Error getting generic data!');
      this.loaded();
    });
  }

  pauseHook(idFrom: string, idTo: string) {
    if (this.allTypes[idFrom].hooks && this.allTypes[idFrom].hooks.post.length > 0) {

      const index = this.allTypes[idFrom].hooks.post.findIndex(elem => {
        return elem.destination === idTo;
      });
      const hook = this.allTypes[idFrom].hooks.post[index];
      const onHold = hook.on_hold;

      this._modals.showConfirmModal(
        onHold ?
          'Resume paused Hook?' :
          'Pause Hook?',
        'Do you want to ' + (onHold ? 'resume' : 'pause') + ' this hook?'
      ).then(cRes => {
        if (cRes && cRes.success) {
          hook.on_hold = !hook.on_hold;
          this.api.put('type/' + idFrom, {hooks: this.allTypes[idFrom].hooks}).then(() => {
            console.log('HOOK PAUSED/RESUMED');
            this.loadData();
          }).catch(error => {
            console.error(error);
            this._toast.showToast('error', 'PUT Error', 'Error updating type!');
            this.loaded();
          });
        }
      });
    }
  }

  deleteHook(type: string, idFrom: string, idTo: string) {
    if (type === 'post-hook') {
      if (this.allTypes[idFrom].hooks && this.allTypes[idFrom].hooks.post.length > 0) {

        const index = this.allTypes[idFrom].hooks.post.findIndex(elem => {
          return elem.destination === idTo;
        });

        this._modals.showConfirmModal('Delete Hook?', 'Do you want to delete this hook?').then(cRes => {
          if (cRes && cRes.success) {
            this.allTypes[idFrom].hooks.post.splice(index, 1);
            this.api.put('type/' + idFrom, {hooks: this.allTypes[idFrom].hooks}).then(() => {
              console.log('HOOK DELETED');
              this.loadData();
            }).catch(error => {
              console.error(error);
              this._toast.showToast('error', 'PUT Error', 'Error updating type!');
              this.loaded();
            });
          }
        });
      }
    } else if (type === 'pre-hook') {
      if (this.allTypes[idFrom].hooks && this.allTypes[idFrom].hooks.pre.length > 0) {

        const index = this.allTypes[idFrom].hooks.pre.findIndex(elem => {
          return elem.type === idTo;
        });

        this._modals.showConfirmModal('Delete Hook?', 'Do you want to delete this hook?').then(cRes => {
          if (cRes && cRes.success) {
            this.allTypes[idFrom].hooks.pre.splice(index, 1);
            this.api.put('type/' + idFrom, {hooks: this.allTypes[idFrom].hooks}).then(() => {
              console.log('HOOK DELETED');
              this.loadData();
            }).catch(error => {
              console.error(error);
              this._toast.showToast('error', 'PUT Error', 'Error updating type!');
              this.loaded();
            });
          }
        });
      }
    }
  }

  addNewHook(originType, type) {
    const _this = this;
    this.addHookOriginType = originType;

    this.addHookGroup.classed('hidden', false);
    d3.selectAll('.singleCircle').classed('destination-hook-mode', true);

    const isAllowed = function (d) {
      if (d instanceof EmptyType) {
        return false;
      }

      if (type === 'post') {
        _this.destHookType = 'post';
        if (originType.hooks && originType.hooks.post && originType.hooks.post.length > 0) {
          return originType.hooks.post.filter(item => item.destination === d['id']).length === 0;
        }
      } else if (type === 'pre') {
        _this.destHookType = 'pre';
        if (originType.hooks && originType.hooks.pre && originType.hooks.pre.length > 0) {
          return originType.hooks.pre.filter(item => item.type === d['id']).length === 0;
        }
      }

      return true;
    };

    const isNotAllowed = function (d) {
      return !isAllowed(d);
    };

    const allowedDests = d3.selectAll('.singleCircle').filter(isAllowed);
    allowedDests.classed('not-allowed-destination-hook', false);

    const notAllowedDests = d3.selectAll('.singleCircle').filter(isNotAllowed);
    notAllowedDests.classed('not-allowed-destination-hook', true);

    this.actionGroup.classed('hidden', true);
    this.cronInfoGroup.classed('hidden', true);
  }

  cancelAddNewHook() {
    this.addHookOriginType = null;
    this.addHookGroup.classed('hidden', true);
    this.actionGroup.classed('hidden', false);
    this.cronInfoGroup.classed('hidden', false);
    d3.selectAll('.singleCircle')
      .classed('not-allowed-destination-hook', false)
      .classed('destination-hook-mode', false);
  }

  selectHookDestination(typeId) {
    if (this.addHookOriginType) {
      if (!this.addHookOriginType.hooks) {
        this.addHookOriginType.hooks = {};
      }

      if (this.destHookType === 'post') {
        const fromId = this.addHookOriginType.id;
        this.createHook('post-hook', fromId, typeId);
      } else if (this.destHookType === 'pre') {
        const fromId = this.addHookOriginType.id;
        this.createHook('pre-hook', fromId, typeId);
      }
    } else {
      this.cancelAddNewHook();
    }
  }


  // ======== UTILS =========

  defineTooltip(path) {
    // define tooltip
    const tooltip = d3.select('#d3canvas') // select element in the DOM with id 'chart'
      .append('div') // append a div element to the element we've selected
      .attr('class', 'c-tooltip'); // add class 'tooltip' on the divs we just selected
    tooltip.append('div') // add divs to the tooltip defined above
      .attr('class', 'label'); // add class 'label' on the selection
    tooltip.append('div') // add divs to the tooltip defined above
      .attr('class', 'message'); // add class 'count' on the selection


    // mouse event handlers are attached to path so they need to come after its definition
    path.on('mouseover', function (d) {  // when mouse enters div
      tooltip.select('.label').html(d.label); // set current label
      tooltip.select('.message').html(d.message); // set current count
      tooltip.style('display', 'block'); // set display
    });

    path.on('mouseout', function () { // when mouse leaves div
      tooltip.style('display', 'none'); // hide tooltip for that element
    });

    path.on('mousemove', function (d) { // when mouse moves
      tooltip.style('top', (d3.event.layerY + 10) + 'px') // always 10px below the cursor
        .style('left', (d3.event.layerX + 10) + 'px'); // always 10px to the right of the mouse
    });
  }

  // hexToRgb(hex) {
  //   const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //   return result ? {
  //     r: parseInt(result[1], 16),
  //     g: parseInt(result[2], 16),
  //     b: parseInt(result[3], 16)
  //   } : null;
  // }

  componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  rgbToHex(r, g, b) {
    return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }

  deg2rad(degrees): number {
    return degrees * Math.PI / 180;
  }

  rad2deg(radians): number {
    return radians * 180 / Math.PI;
  }

  // randomBetween(a, b): number {
  //   return Math.floor(Math.random() * b) + a;
  // }

  // getColorCode(idx) {
  //   const len = this.colorArray.length;
  //   return this.colorArray[idx % len];
  // }

  getColorCodeSys(sysId, idx) {
    if (this.userSettings.config && this.userSettings.config.systems[sysId]) {
      return this.userSettings.config.systems[sysId].color;
    } else {
      const len = this.colorArray.length;
      return this.colorArray[idx % len];
    }
  }


}
