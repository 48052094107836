import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {Injectable} from '@angular/core';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  private headers: HttpHeaders = new HttpHeaders();

  constructor(private _route: ActivatedRoute) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const userToken = localStorage.getItem('token');

    if (userToken) {
      this.headers = request.headers.append('Authorization', 'Basic ' + userToken);
      this.setLoggedAsHeader();
      request = request.clone({headers: this.headers});
    }

    this.verifyAdmin();

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  private setLoggedAsHeader() {
    const user = this.getLoggedAsUser();
    if (user) {
      this.headers = this.headers.append('Custom-Customer-Id', user.id);
    }
  }

  private getLoggedAsUser(): any {
    const uid = this._route.snapshot.queryParamMap.get('userId');
    const usersList: Array<any> = JSON.parse(localStorage.getItem('usersList'));
    const templatesList: Array<any> = JSON.parse(localStorage.getItem('templatesList'));
    const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));

    let all = usersList;
    if (templatesList) {
      all = usersList.concat(templatesList);
    }

    if (uid && all) {
      return all.filter(elem => elem.id === uid)[0];
    } else if (currentUser && currentUser.length === 1) {
      return currentUser[0];
    } else {
      return null;
    }
  }

  private verifyAdmin() {
    const isAdmin = localStorage.getItem('isAdmin');
    if (isAdmin) {
      this.headers = this.headers.append('Connector-User-Admin', isAdmin.toString());
    } else {
      if (this.headers.get('Connector-User-Admin')) {
        this.headers = this.headers.delete('Connector-User-Admin');
      }
    }
  }

}
