import {Component, EventEmitter, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ComplexSchemaCollection, ComplexTypeService, ComplexTypeSnippet} from '../../complex_types/complex-types';
import {ComplexTypeEditorComponent} from '../../complex_types/complex-type-editor.component';
import {ComplexTypeModalService} from './complex-type-modal.service';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';

enum ComplexTabs {
  Structure = 'Structure',
  Result = 'Result',
  Data = 'Data'
}

@Component({
  selector: 'ngx-complex-type-modal',
  styleUrls: ['./modal.component.css'],
  templateUrl: './complex-type-modal.component.html',
})
export class ComplexTypeModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  @ViewChildren(ComplexTypeEditorComponent) ctEditors: QueryList<ComplexTypeEditorComponent>;

  initData: any;
  prePath: any;
  originData: any;

  modalTitle = 'Complex Types';
  ctList: Array<any>;

  selectedValue: ComplexTypeSnippet;
  modifiedValue: ComplexTypeSnippet;

  schemaData = null;
  jsonData = null;
  complexTabs = ComplexTabs;

  result = {
    success: false,
    payload: null
  };

  modalCount = new Array<any>();

  showAlert = false;
  invalidForm = false;

  validationResult: any = {};

  @ViewChild('resultEditor') resultEditor: JsonEditorComponent;
  public resultEditorOptions: JsonEditorOptions;
  @ViewChild('originDataEditor') originDataEditor: JsonEditorComponent;
  public originDataEditorOptions: JsonEditorOptions;

  constructor(public bsModalRef: BsModalRef, private complexTypeService: ComplexTypeService, private ctmService: ComplexTypeModalService) {
    this.ctList = this.complexTypeService.getComplexTypeSnippetList();
  }

  ngOnInit() {

    this.resultEditor.jsonEditorContainer.nativeElement.style.height = '100%';
    this.resultEditorOptions = new JsonEditorOptions();
    this.resultEditorOptions.mode = 'view';

    this.originDataEditor.jsonEditorContainer.nativeElement.style.height = '100%';
    this.originDataEditorOptions = new JsonEditorOptions();
    this.originDataEditorOptions.mode = 'view';

    const mc = this.ctmService.getCount();
    this.modalCount = Array(mc).fill(0);

    if (this.initData) {

      this.selectedValue = this.ctList.filter(item => {
        return item.type === this.initData.type;
      })[0];

      if (this.selectedValue) {
        this.showAlert = false;
        this.modifiedValue = this.selectedValue;
        this.modifiedValue.args = this.initData.args;
        this.valueChanged();
      } else {
        this.showAlert = true;
      }
    }

    this.invalidForm = this.isInvalidForm();
  }


  closeModal(success: boolean) {

    if (success && this.selectedValue) {

      const returnElem = {
        type: this.selectedValue.type,
        args: null
      };

      if (!this.selectedValue.args) {
        // means that selected complex type has no args and needs no input

      } else {

        if (this.schemaData && this.schemaData.length > 0) {

          const args = this.schemaData.reduce((obj, item) => {

            if (item.detail.value.type === 'integer') {
              obj[item.detail.key] = +item.model;
            } else {
              obj[item.detail.key] = item.model;
            }
            return obj;
          }, {});

          this.schemaData.forEach(item => {
            if (item.detail.value.optional && item.detail.value.optional.opt && item.detail.value.optional.model) {
              delete args[item.detail.key];
            }
          });

          returnElem.args = args;

        } else if (this.jsonData) {

          returnElem.args = Object.keys(this.selectedValue.args).reduce((obj, item) => {
            obj[item] = this.jsonData[item];
            return obj;
          }, {});
        }

      }

      this.result.payload = returnElem;
    }

    this.result.success = success;

    this.ctmService.decreaseCount();
    this.event.emit(this.result);
    this.bsModalRef.hide();
  }


  valueChanged() {
    this.schemaData = null;
    this.jsonData = null;

    if (this.selectedValue.schemas) {
      const csCollection = this.selectedValue.schemas as ComplexSchemaCollection;
      const csc = [];
      Object.getOwnPropertyNames(csCollection).map(k => {
        const val = csCollection[k];
        const obj = {
          detail: {key: k, value: val},
          model: val.default
        };

        if (this.modifiedValue && this.modifiedValue.type === this.selectedValue.type) {
          obj.model = this.modifiedValue.args[k];
        }

        csc.push(obj);
      });
      this.schemaData = csc;

    } else {
      this.schemaData = null;
    }

    this.invalidForm = this.isInvalidForm();
  }


  onModelChanged(event) {
    this.invalidForm = this.isInvalidForm();
  }


  isInvalidForm() {
    if (!this.selectedValue) {
      return true;
    }

    if (!this.schemaData) {
      return false;
    } else {
      if (this.schemaData && this.schemaData.length > 0) {
        return this.schemaData.filter(item => {
          if (item.detail.value.type === 'any') {
            return item.model === undefined;
          } else if (typeof item.model == 'number') {
            return false;
          } else if (item.detail.value.type === 'schema' || item.detail.value.type === 'boolean') {
            return false;
          } else if (item.detail.value.type === 'string' && item.detail.value.allowEmpty) {
            return false;
          } else if (item.detail.value.type === 'path_group' && item.detail.value.allowEmpty) {
            return false;
          } else {

            if (item.detail.value.optional && item.detail.value.optional.opt && item.detail.value.optional.model) {
              return false;
            }
            return !item.model || item.model === '';

          }
        }).length > 0;
      } else if (this.jsonData) {
        return (Object.keys(this.jsonData).length === 0 && this.jsonData.constructor === Object);
      } else {
        return true;
      }
    }
  }


  tabChanged(event) {
    if (event.tabTitle == ComplexTabs.Structure) {
    }
  }

  runTest() {
    const testElem = {
      type: this.selectedValue.type,
      args: null
    };

    if (this.schemaData && this.schemaData.length > 0) {
      const args = this.schemaData.reduce((obj, item) => {
        obj[item.detail.key] = item.model;
        return obj;
      }, {});

      this.schemaData.forEach(item => {
        if (item.detail.value.optional && item.detail.value.optional.opt && item.detail.value.optional.model) {
          delete args[item.detail.key];
        }
      });

      testElem.args = args;
    } else if (this.jsonData) {
      testElem.args = Object.keys(this.selectedValue.args).reduce((obj, item) => {
        obj[item] = this.jsonData[item];
        return obj;
      }, {});
    }

    this.ctmService.runPreTest(null, this.prePath, testElem).then(response => {
      this.validationResult = response;
    });

  }

}


