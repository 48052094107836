import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {EditValidationModalComponent} from '../../modals/modal/edit-validation-modal.component';


@Component({
  selector: 'app-structure-item',
  templateUrl: './structure-item.component.html',
  styleUrls: ['./structure-item.component.css']
})
export class StructureItemComponent implements OnInit, OnChanges {

  @Input() element: any;
  @Output() elementChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() index: number;
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  @Output() duplicated: EventEmitter<any> = new EventEmitter<any>();

  @Input() isEditMode = false;
  @Output() isEditModeChange: EventEmitter<any> = new EventEmitter<any>();

  editMode = false;
  confirmDelete = false;

  constructor(public modalRef: BsModalRef, private bsModalService: BsModalService) {
  }

  ngOnInit() {
    this.editMode = this.isEditMode;
  }

  ngOnChanges(changes) {
    if (changes.isEditMode) {
      this.editMode = changes.isEditMode.currentValue;
    }
  }


  onChange(event, type) {
    if (event.hasOwnProperty('edit')) {
      this.elementChange.emit(event);
    } else {
      const ret = {
        event: event,
        type: type
      };
      this.elementChange.emit(ret);
    }
  }


  changeEditMode() {
    this.editMode = !this.editMode;
    this.isEditModeChange.emit(this.editMode);
  }


  deleteStructureItem(index) {
    this.removed.emit();
  }

  duplicateStructureItem(index) {
    this.duplicated.emit();
  }


  editValidation(validation) {

    if (!validation) {
      this.element.validation = [];
      validation = [];
    }

    const modalData = {title: 'Edit Validation', data: validation};
    const initialState = {modalData: modalData};

    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(EditValidationModalComponent, config);
    activeModal.content.modalResult.subscribe(response => {
      if (response && response.success) {
        this.element.validation = response.payload;
      }
    });

  }

}
