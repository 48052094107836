import {Component, EventEmitter, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ApiService} from '../../../../_api/api.service';
import {TypeService} from '../../../../pages/type/type.service';
import {PagesService} from '../../../../pages/pages.service';
import {ToastService} from '../../../toast/toast.service';
import {ITypeScheduleData} from '../../../interfaces/itype-schedule-data';
import * as moment from 'moment';
import {IModalData} from '../../../interfaces/imodal-data';
import {Type} from '../../../../pages/type/type';
import * as Cron from 'cron-converter';

@Component({
  selector: 'app-schedule-type-modal',
  templateUrl: './schedule-type-modal.component.html',
  styleUrls: ['./schedule-type-modal.component.css']
})
export class ScheduleTypeModalComponent implements OnInit {

  public modalData: IModalData;
  public type: Type;

  public modalResult: EventEmitter<any> = new EventEmitter();
  private result = {success: false, payload: null};

  public forceReschedule = false;
  public nextExecution = '';
  public lastExecution = '';
  public lastExecutionTime = '';
  public isRunning = false;
  public currentOperation = 'TASK';
  public cronFormat = '* * * * *';
  public hasSchedule = false;
  public confirmationModalRef: BsModalRef;

  public nextExecutionPreview = '';
  public nextExecutionPreviewLocale = '';

  public nextExecutionLocale = '';
  public lastExecutionLocale = '';

  private scheduleData = new Array<ITypeScheduleData>();
  public confirmationMessage: string;

  constructor(public modalRef: BsModalRef,
              private api: ApiService,
              private typeService: TypeService,
              private pagesService: PagesService,
              private toast: ToastService,
              private bsmodal: BsModalService) {
  }

  ngOnInit() {
    this.modalData.title = 'Type Cron Scheduler';
    if (this.modalData.data) {
      this.type = this.modalData.data as Type;
    }
    this.loadData();
  }

  public async loadData() {
    this.pagesService.setLoading(true);
    try {
      this.scheduleData = (await this.api.get(`type/${this.type.id}/schedule`)).results as ITypeScheduleData[];
      this.loadScheduleData();
    } catch (error) {
      this.toast.showToast('error', `Error fetching schedule data for type ${this.type.id}`, '');
    }
    this.pagesService.setLoading(false);

  }

  public loadScheduleData() {

    this.nextExecution = '';
    this.lastExecution = '';
    this.lastExecutionTime = '';
    this.cronFormat = '';
    this.hasSchedule = false;

    this.nextExecutionLocale = '';
    this.lastExecutionLocale = '';

    this.scheduleData.filter(sdata => sdata.operation === this.currentOperation)
      .forEach(sdata => {
        const mNext = moment.utc(sdata.next_execution);
        const mLast = moment.utc(sdata.last_execution);
        this.nextExecution = mNext.isValid() ? mNext.format('DD/MM/YYYY HH:mm:ss') : '';
        this.lastExecution = mLast.isValid() ? mLast.format('DD/MM/YYYY HH:mm:ss') : '';

        this.nextExecutionLocale = mNext.isValid() ? mNext.local().format('DD/MM/YYYY HH:mm:ss Z') : '';
        this.lastExecutionLocale = mLast.isValid() ? mLast.local().format('DD/MM/YYYY HH:mm:ss Z') : '';

        this.lastExecutionTime = sdata.last_execution_elapsed_seconds;
        this.cronFormat = sdata.cron_format;
        this.isRunning = sdata.is_running;
        this.hasSchedule = true;
      });
  }

  public previewCron() {
    this.nextExecutionPreview = '';
    this.nextExecutionPreviewLocale = '';

    const cronInstance = new Cron({
      timezone: 'Europe/London'
    });
    cronInstance.fromString(this.cronFormat);
    const schedule = cronInstance.schedule();
    const next = schedule.next();

    this.nextExecutionPreview = next.format('DD/MM/YYYY HH:mm:ss Z');
    this.nextExecutionPreviewLocale = next.local().format('DD/MM/YYYY HH:mm:ss Z');
  }

  public closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  public confirmSchedule(confirmationModal: TemplateRef<any>) {
    if (this.currentOperation === 'TASK' && this.type.cron_schedule_task !== null) {
      this.confirmationMessage = `There is already a default TASK cron defined for this type, 
          do you want to remove the default cron and save this one?`;
      this.confirmationModalRef = this.bsmodal.show(confirmationModal);
    } else if (this.currentOperation === 'COMMIT' && this.type.cron_schedule_commit !== null) {
      this.confirmationMessage = `There is already a default COMMIT cron defined for this type, 
          do you want to remove the default cron and save this one?`;
      this.confirmationModalRef = this.bsmodal.show(confirmationModal);
    } else {
      this.putSchedule();
    }
  }

  public async confirmRemoveDefaultSchedule() {

    this.pagesService.setLoading(true, false);

    const _type = {} as Type;
    _type.id = this.type.id;

    if (this.currentOperation === 'TASK') {
      _type.cron = false;
      _type.task = this.type.task;
      _type.task['cron_interval'] = 0;
      try {
        await this.api.put(`type/${this.type.id}`, _type);
        this.putSchedule();
      } catch (e) {
        this.toast.showToast('error', 'Error trying to remove default cron for TASK', e.toString());
        console.log('Error trying to remove default cron for TASK', e);
      }
    } else {
      _type.commit = this.type.commit;
      _type.commit['cron_interval'] = 0;
      try {
        await this.api.put(`type/${this.type.id}`, _type);
        this.putSchedule();
      } catch (e) {
        this.toast.showToast('error', 'Error trying to remove default cron for COMMIT', e.toString());
        console.log('Error trying to remove default cron for COMMIT', e);
      }
    }
    this.confirmationModalRef.hide();
  }

  public async deleteSchedule() {
    this.pagesService.setLoading(true, false);
    try {
      await this.api.api_delete(`type/${this.type.id}/schedule/${this.currentOperation}`);
      this.toast.showToast('success', 'Cron schedule deleted successfully!', '');
      this.loadData();
    } catch (error) {
      console.log(error);
      this.toast.showToast('error', 'Error deleting cron schedule!', error.message);
      this.pagesService.setLoading(false);
    }
  }

  private async putSchedule() {
    this.pagesService.setLoading(true, false);
    try {
      await this.api.put(`type/${this.type.id}/schedule`, {
        operation: this.currentOperation,
        force: this.forceReschedule,
        cron: this.cronFormat
      });
      this.toast.showToast('success', 'Cron schedule saved successfully!', '');
      this.loadData();
    } catch (error) {
      console.log(error);
      this.toast.showToast('error', 'Error saving cron schedule!', error.message);
      this.pagesService.setLoading(false);
    }
  }
}
