import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagesComponent} from './pages.component';
import {ThemeModule} from '../@theme/theme.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {ModalsModule} from '../_utils/modals/modals.module';
import {ConfigComponent} from './config/config.component';

import {D3Service} from 'd3-ng2-service';
import {QueueComponent} from './queue/queue.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {GenericDataComponent} from './generic-data/generic-data.component';

import {ReprocessingRuleComponent} from './reprocessing-rule/reprocessing-rule.component';
import {ThirdPartyComponent} from './third-party/third-party.component';

import {ClipboardModule} from 'ngx-clipboard';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {AlertModule} from 'ngx-bootstrap/alert';


import {AlertComponent} from './alert/alert.component';
import {GappStatisticsComponent} from './gapp-statistics/gapp-statistics.component';

import {ApiService} from '../_api/api.service';
import {OauthComponent} from './oauth/oauth.component';

import {UtilsModule} from '../_utils/utils.module';
import {TextMaskModule} from 'angular2-text-mask';
import {TemplateComponent} from './template/template.component';
import {ScheduleCalendarComponent} from './third-party/schedule-calendar/schedule-calendar.component';
import {CalendarModule, DateAdapter} from 'angular-calendar';


@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    DashboardModule,
    ModalsModule,
    NgxDatatableModule,
    ClipboardModule,
    PopoverModule,
    ButtonsModule,
    ProgressbarModule,
    AlertModule,
    UtilsModule,
    TextMaskModule,
    CalendarModule,
  ],
  exports: [],
  declarations: [
    PagesComponent,
    ConfigComponent,
    QueueComponent,
    GenericDataComponent,
    ReprocessingRuleComponent,
    ThirdPartyComponent,
    AlertComponent,
    GappStatisticsComponent,
    OauthComponent,
    TemplateComponent,
    ScheduleCalendarComponent,
  ],
  entryComponents: [],
  providers: [
    D3Service,
    ApiService
  ]
})
export class PagesModule {
}
