import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/Observable';
import * as moment from 'moment';
import {merge} from 'rxjs';


export class GatewayQueue {
  id: string;
  created_at: string;
  updated_at: string;
  log: string;
  data_id: string;
  is_local_processed: string;
  is_processed: string;
  structure_origin: object;
  type_destination: string;
  type_origin: string;
  selected: boolean;
}

export class GatewayQueueObservable {
  dataChange: BehaviorSubject<GatewayQueue[]> = new BehaviorSubject<GatewayQueue[]>([]);

  get data(): GatewayQueue[] {
    return this.dataChange.value;
  }

  addData(new_data) {
    let copiedData = this.data.slice();
    copiedData = copiedData.concat(new_data);
    this.dataChange.next(copiedData);
  }

  removeData(data) {
    let copiedData = this.data.slice();
    copiedData = copiedData.filter(elem => data.indexOf(elem) === -1);
    this.dataChange.next(copiedData);
  }

  reloadData(data) {
    let copiedData = this.data.slice();
    copiedData = copiedData.map(elem => {
      if (elem.data_id == data.data_id) {
        elem = data;
      }
      return elem;
    });
    this.dataChange.next(copiedData);
  }

}


export class GatewayQueueDataSource {

  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  showing = 0;

  constructor(private _gatewayQueueObservable: GatewayQueueObservable) {

  }

  connect(): Observable<GatewayQueue[]> {
    const displayDataChanges = [
      this._gatewayQueueObservable.dataChange,
      this._filterChange,
    ];

    return merge(...displayDataChanges).map(() => {

      console.log(this._gatewayQueueObservable.data);

      const filtered_data = this._gatewayQueueObservable.data.slice().filter((item: GatewayQueue) => {
        const searchStr = JSON.stringify(item).toLowerCase() + moment.utc(item.updated_at).local().toLocaleString().toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });
      this.showing = filtered_data.length;
      return filtered_data;
    });
  }


}
