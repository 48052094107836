import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {ModalsService} from '../../_utils/modals/modals.service';
import {System, SystemCollection} from '../system/system';
import {TypeCollection} from '../type/type';
import * as moment from 'moment';
import {PagesService} from '../pages.service';
import {HookTriggerTemplate, TriggerTemplate} from './reprocessing-rule';

@Component({
  selector: 'app-reprocessing-rule',
  templateUrl: './reprocessing-rule.component.html',
  styleUrls: ['./reprocessing-rule.component.css'],
  providers: [ApiService],
})
export class ReprocessingRuleComponent implements OnInit {

  loading = false;
  selectedTrigger: any;
  triggers: any;
  systems: System[];
  allSystems: SystemCollection;
  allTypes: TypeCollection;

  constructor(private _api: ApiService, private _modals: ModalsService, private _pageService: PagesService) { }

  ngOnInit() {
    this.loadData();
  }

  onRefresh() {
    this.loadData();
  }

  loadData($event = null) {
    this.setLoading();
    this.loading = true;
    this.selectedTrigger = null;

    this._api.get('return_triggers').then(response => {
      console.log(response);
      this.triggers = response.results.sort(function(a, b) {
        const dateA = moment(a.created_at);
        const dateB = moment(b.created_at);
        return dateA.valueOf() - dateB.valueOf();
      });

    });
    this._api.get('system').then(response => {
      this.systems = response.results;

      this.allSystems = new SystemCollection();
      this.systems.map(el => this.allSystems[el.id] = el);

      const aux = this.systems.reduce(function(a, b) {
        return a.concat(b.types);
      }, []);
      this.allTypes = new TypeCollection();
      aux.map(el => this.allTypes[el.id] = el );

      this.loaded();
    });
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
    this.loading = false;
  }

  formatDate(date) {
    const mdate = moment(date);
    return mdate.format('DD/MM/YYYY HH:mm:ss');
  }

  getTypeInfo(typeId) {
    const type = this.allTypes[typeId];
    if (type) {
      const system = this.allSystems[type.system_id];
      return system.alias + ' > ' + type.description;
    } else {
      return null;
    }
  }

  addTrigger() {
    // const template = {
    // 	'hooks': [{
    // 		'destination': '',
    // 		'external_id_path': [],
    // 		"schedule_attempts": 0,
    // 		"schedule_delay": 0,
    // 		'structure': [{
    // 			'field_destination': [],
    // 			'field_origin': [],
    // 			'validation': [{
    // 				'condition': [{
    // 					'args': {
    // 						'comparison_type': '!=',
    // 						'value_1': [],
    // 						'value_2': []
    // 					},
    // 					'type': 'if_else'
    // 				}]
    // 			}]
    // 		}]
    // 	}],
    // 	'type_destination': [],
    // 	'type_origin': [] };

    const template = new TriggerTemplate();
    template.hooks.push(new HookTriggerTemplate());

    const obj = JSON.parse(JSON.stringify(template));
    const modalData = {
      title: 'Add New Reprocessing Rule',
      data: obj
    };

    this._modals.showReprocessingRuleModal(modalData).subscribe(data => {
      if (data.success && data.payload) {
        const payload = data.payload;
        const payloadCopy = JSON.parse(JSON.stringify(payload));
        const modObj = this.parseTrigger(payloadCopy);

        this.setLoading();
        this.loading = true;
        this._api.post('return_triggers', modObj).then(() => this.loadData());
      }
    });
  }


  editTrigger(trigger) {
    const id = trigger.id;
    const editObj = this.parseTrigger(trigger);

    const modalData = {
      title: 'Edit Reprocessing Rule',
      data: editObj,
      created_at: trigger.created_at,
      updated_at: trigger.updated_at,
    };

    this._modals.showReprocessingRuleModal(modalData).subscribe(data => {
      if (data.success && data.payload) {
        const payload = data.payload;
        const payloadCopy = JSON.parse(JSON.stringify(payload));
        const modObj = this.parseTrigger(payloadCopy);

        this.setLoading();
        this.loading = true;
        this._api.put('return_triggers/' + id, modObj).then(() => this.loadData());
      }
    });
  }


  deleteTrigger(trigger) {
    this._modals.showConfirmModal('Delete Reprocessing Rule?', 'Do you want to delete this reprocessing rule?').then(cRes => {
      if (cRes && cRes.success) {
        this.setLoading();
        this.loading = true;
        this._api.api_delete('return_triggers/' + trigger.id).then(res => {
          if (res.success) {
            this.loadData();
          }
        });
      }
    });
  }


  parseTrigger(trigger) {

    const hooks = trigger.hooks.map(elem => {
      elem.schedule_attempts = +elem.schedule_attempts;
      elem.schedule_delay = +elem.schedule_delay;
      delete elem['destinationModel'];
      return elem;
    });

    return {
      hooks: hooks,
      type_destination: trigger.type_destination,
      type_origin: trigger.type_origin,
      // destination: trigger.destination,
      // external_id_path: trigger.external_id_path,
      // structure: trigger.structure,
      // length: trigger.length,
    };
  }
}
