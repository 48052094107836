import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {QuestionBase} from './question-base';
import {TextboxQuestion} from './question-textbox';

@Injectable()
export class QuestionControlService {

  toFormGroup(questions: QuestionBase<any>[]) {
    const group: any = {};

    questions.forEach(question => {

      const validators = [];

      if (question.required) {
        validators.push(Validators.required);
      }

      if (question.controlType === 'textbox') {
        const textQ = question as TextboxQuestion;
        if (textQ.type === 'email') {
          validators.push(Validators.email);
        }
        if (textQ.type === 'number') {

        }
      }
      group[question.key] = new FormControl(question.value || '', validators);

    });
    return new FormGroup(group);
  }


  toJsonData(questions: QuestionBase<any>[]) {
    const data: any = {};
    questions.map(item => data[item.key] = item.value);
    return data;
  }
}
