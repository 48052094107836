import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {LoginService} from '../../auth/components/login/login.service';
import {ModalsService} from '../../_utils/modals/modals.service';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {SystemCollection} from '../system/system';
import {TypeCollection} from '../type/type';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {PagesService} from '../pages.service';

enum AlertLevel {
  Warning = 'WARNING',
  Error = 'ERROR',
  Critical = 'CRITICAL'
}

enum AlertStatus {
  Inserted = 'INSERTED',
  Read = 'READ',
  Solved = 'SOLVED'
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ApiService],
})
export class AlertComponent implements OnInit {

  isAdmin = false;
  loggedAsUser = false;

  loading = false;
  loadingTypes = false;

  alertLevel = AlertLevel;
  alertStatus = AlertStatus;

  expanded = false;

  showDataOptions = ['25', '100', '250', '500'];
  showDataQty = '25';
  nextUrl: string;

  chkAlertStatus = [
    {key: AlertStatus.Inserted, display: 'Unread', value: true, disabled: true},
    {key: AlertStatus.Read, display: 'Read', value: false, disabled: false},
    {key: AlertStatus.Solved, display: 'Solved', value: false, disabled: false},
    {key: 'ALL', display: 'All', value: false, disabled: false}
  ];

  chkAlertLevels = [
    {key: AlertLevel.Critical, display: 'Critical', value: true, disabled: false},
    {key: AlertLevel.Error, display: 'Error', value: true, disabled: false},
    {key: AlertLevel.Warning, display: 'Warning', value: false, disabled: false},
    {key: 'ALL', display: 'All', value: false, disabled: false}
  ];

  usersList: any;

  allSystems: SystemCollection;
  allTypes: TypeCollection;


  @ViewChild('pop') pop: PopoverDirective;

  @ViewChild('newTable') newtable: any;
  rowsData = [];
  allData = [];

  nullID = 'NULL_ID';

  filterStatusArray = [];
  filterLevelArray = [];

  possibleCustomersArray = [];
  filterCustomersArray = [];

  possibleSystemsArray = [];
  filterSystemsArray = [];

  possibleTypesArray = [];
  filterTypesArray = [];


  constructor(private _api: ApiService,
              private _modals: ModalsService,
              private _login: LoginService,
              private _pageService: PagesService,
              private _router: Router) {
    this.filterStatusArray = this.chkAlertStatus.filter(elem => elem.value).map(elem => elem.key.toUpperCase());
    this.filterLevelArray = this.chkAlertLevels.filter(elem => elem.value).map(elem => elem.key.toUpperCase());
  }


  ngOnInit() {
    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();
    this.usersList = JSON.parse(localStorage.getItem('usersList'));

    this.nextUrl = '';

    if (!this.isAdmin || (this.isAdmin && this.loggedAsUser)) {
      this.loadingTypes = true;
      this._api.get('system').then(response => {
        const systems = response.results;

        this.allSystems = new SystemCollection();
        systems.map(el => this.allSystems[el.id] = el);

        const aux = systems.reduce(function (a, b) {
          return a.concat(b.types);
        }, []);
        this.allTypes = new TypeCollection();
        aux.map(el => this.allTypes[el.id] = el);

        this.loadingTypes = false;
      });
    }

    // NEW
    this.fetchData();
  }


  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
    this.loading = false;
  }

  compareDates(a, b) {
    return moment.utc(a).diff(moment.utc(b));
  }

  formatDate(date) {
    const mdate = moment(date);
    return mdate.format('DD/MM/YYYY HH:mm:ss');
  }

  toggleExpandRow(row) {
    this.newtable.rowDetail.toggleExpandRow(row);
  }


  queryStringToJSON(url) {
    const pairs = url.split('&');
    const result = {};
    pairs.forEach(function (pair) {
      let a = pair.substring(0, pair.indexOf('='));
      const b = pair.substring(pair.indexOf('=') + 1);
      a = a.replace('?', '');
      result[a] = decodeURIComponent(b || '');
    });
    return JSON.parse(JSON.stringify(result));
  }


  onActivate($event) {
    if ($event.type === 'click' && $event.column.name !== 'nextStatus') {
      this.toggleExpandRow($event.row);
    }
  }

  nextStatus($event, row) {
    const id = row.id;
    row.uiUpdating = true;

    this._api.put('alert/' + id + '/next_status', {}).then(response => {

      this.allData.splice(this.allData.indexOf(row), 1, response);
      this.allData = [...this.allData];

      this.rowsData.splice(this.rowsData.indexOf(row), 1, response);
      this.rowsData = [...this.rowsData];

      // this.reloadData();
      response.uiUpdating = false;

      // GROUP CUSTOMERS
      this.groupCustomers();
      this.groupSystems();
      this.groupTypes();

      // FILTER DATA
      this.filterData();

      this.loaded();
    });
  }

  getBtnNextText(status: string) {
    if (status.toUpperCase() === AlertStatus.Inserted) {
      return 'Mark as Read';
    } else if (status.toUpperCase() === AlertStatus.Read) {
      return 'Mark as Solved';
    }
  }


  chkStatusChanged($event, chk) {
    if (this.chkAlertStatus.filter(elem => elem.value).length > 1) {
      this.chkAlertStatus.forEach(item => item.disabled = false);
    } else {
      this.chkAlertStatus.filter(elem => elem.value)[0].disabled = true;
    }

    this.filterStatusArray = this.chkAlertStatus.filter(elem => elem.value).map(elem => elem.key.toUpperCase());
    this.filterData();
  }

  chkLevelChanged($event, chk) {
    if (this.chkAlertLevels.filter(elem => elem.value).length > 1) {
      this.chkAlertLevels.forEach(item => item.disabled = false);
    } else {
      this.chkAlertLevels.filter(elem => elem.value)[0].disabled = true;
    }

    this.filterLevelArray = this.chkAlertLevels.filter(elem => elem.value).map(elem => elem.key.toUpperCase());
    this.filterData();
  }


  getCustomer(id) {
    if (id && id !== '') {
      const user = this.usersList.filter(elem => elem.id === id)[0];
      return user.name;
    } else {
      return null;
    }
  }


  getRowClass(row) {
    return {
      'updating-row': row.uiUpdating
    };
  }


  getTypeName(item) {
    if (this.allTypes && item) {
      if (this.allTypes[item]) {
        return this.allTypes[item].description;
      } else {
        return 'Type Undefined';
      }
    }
    return null;
  }


  getSystemName(item) {
    if (this.allSystems && item) {
      if (this.allSystems[item]) {
        return this.allSystems[item].alias;
      } else {
        return 'System Undefined';
      }
    }
    return null;
  }


  onRefresh() {
    this.nextUrl = '';
    this.rowsData = [];
    this.allData = [];

    this.fetchData();
  }


  // ==============================================
  // ==============================================

  onShown(): void {
    setTimeout(() => {
      this.pop.hide();
    }, 1000);
  }

  fetchData() {
    const search = this.nextUrl.replace('/api/v1/alert?', '');
    const urlObj = this.queryStringToJSON(search);

    urlObj.fetch_qty = this.showDataQty;

    const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
      return [k, urlObj[k]].join('=');
    }).join('&');

    const getUrl = 'alert?' + urlParams;

    this._api.get(getUrl).then(response => {
      this.nextUrl = response.next_url;
      response.results.forEach(elem => elem.uiUpdating = false);

      this.allData = this.allData.concat(response.results);
      this.allData = [...this.allData];

      this.rowsData = this.rowsData.concat(response.results);
      this.rowsData = [...this.rowsData];


      // GROUP CUSTOMERS
      this.groupCustomers();
      this.groupSystems();
      this.groupTypes();

      // FILTER DATA
      this.filterData();

      this.loaded();
    });
  }

  filterData() {
    const _th = this;

    // FILTER DATA --- (( LEVEL ))
    let temp1;
    if (this.filterLevelArray.indexOf('ALL') > -1) {
      temp1 = this.allData;
    } else {
      temp1 = this.allData.filter(function (d) {
        return _th.filterLevelArray.indexOf(d.level.toUpperCase()) > -1;
      });
    }


    // FILTER DATA --- (( STATUS ))
    let temp2;
    if (this.filterStatusArray.indexOf('ALL') > -1) {
      temp2 = temp1;
    } else {
      temp2 = temp1.filter(function (d) {
        return _th.filterStatusArray.indexOf(d.status.toUpperCase()) > -1;
      });
    }

    // FILTER DATA --- (( CUSTOMERS ))
    let temp3;
    temp3 = temp2.filter((d) => {
      return _th.filterCustomersArray.indexOf(d.customer_id || _th.nullID) > -1;
    });

    // FILTER DATA --- (( SYSTEMS ))
    let temp4;
    temp4 = temp3.filter(function (d) {
      return _th.filterSystemsArray.indexOf(d.system_id || _th.nullID) > -1;
    });

    // FILTER DATA --- (( TYPES ))
    let temp5;
    temp5 = temp4.filter(function (d) {
      return _th.filterTypesArray.indexOf(d.type_id || _th.nullID) > -1;
    });

    // update the rows
    this.rowsData = temp5;

    // Whenever the filter changes, always go back to the first page
    this.newtable.offset = 0;
  }


  groupCustomers() {
    const unique = Array.from(new Set(this.allData.map((item: any) => item.customer_id)));
    if (unique.indexOf(null) > -1) {
      unique[unique.indexOf(null)] = this.nullID;
    }
    this.possibleCustomersArray = unique.map(elem => {
      let isSelected = false;
      const cust = this.possibleCustomersArray.filter(el => el.id === elem)[0];
      if (cust) {
        isSelected = cust.selected;
      }
      return {id: elem, selected: isSelected};
    });

    const selectedElements = this.possibleCustomersArray.filter(elem => elem.selected);
    if (selectedElements.length === 0) {
      this.filterCustomersArray = this.possibleCustomersArray.map(elem => elem.id);
    } else {
      this.filterCustomersArray = selectedElements.map(elem => elem.id);
    }
  }


  groupSystems() {
    const unique = Array.from(new Set(this.allData.map((item: any) => item.system_id)));
    if (unique.indexOf(null) > -1) {
      unique[unique.indexOf(null)] = this.nullID;
    }
    this.possibleSystemsArray = unique.map(elem => {
      let isSelected = false;
      const sys = this.possibleSystemsArray.filter(el => el.id === elem)[0];
      if (sys) {
        isSelected = sys.selected;
      }
      return {id: elem, selected: isSelected};
    });

    const selectedElements = this.possibleSystemsArray.filter(elem => elem.selected);
    if (selectedElements.length === 0) {
      this.filterSystemsArray = this.possibleSystemsArray.map(elem => elem.id);
    } else {
      this.filterSystemsArray = selectedElements.map(elem => elem.id);
    }
  }


  groupTypes() {
    const unique = Array.from(new Set(this.allData.map((item: any) => item.type_id)));
    if (unique.indexOf(null) > -1) {
      unique[unique.indexOf(null)] = this.nullID;
    }
    this.possibleTypesArray = unique.map(elem => {
      let isSelected = false;
      const typ = this.possibleTypesArray.filter(el => el.id === elem)[0];
      if (typ) {
        isSelected = typ.selected;
      }
      return {id: elem, selected: isSelected};
    });

    const selectedElements = this.possibleTypesArray.filter(elem => elem.selected);
    if (selectedElements.length === 0) {
      this.filterTypesArray = this.possibleTypesArray.map(elem => elem.id);
    } else {
      this.filterTypesArray = selectedElements.map(elem => elem.id);
    }
  }


  selectCustomer(customer) {
    customer.selected = !customer.selected;
    this.groupCustomers();
    this.filterData();
  }

  selectSystem(system) {
    system.selected = !system.selected;
    this.groupSystems();
    this.filterData();
  }

  selectType(type) {
    type.selected = !type.selected;
    this.groupTypes();
    this.filterData();
  }


  getAlerts(elemID, property) {
    const alerts = {
      critical: null,
      error: null,
      warning: null
    };

    let filtered = [];
    if (elemID === this.nullID) {
      filtered = this.allData.filter(elem => elem[property] === null);
    } else {
      filtered = this.allData.filter(elem => elem[property] === elemID);
    }

    alerts.critical = filtered.filter(elem => elem.level === AlertLevel.Critical).length;
    alerts.error = filtered.filter(elem => elem.level === AlertLevel.Error).length;
    alerts.warning = filtered.filter(elem => elem.level === AlertLevel.Warning).length;

    return alerts;
  }

}
