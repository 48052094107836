import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';


@Component({
  selector: 'ngx-wizard-modal',
  templateUrl: './wizard-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class WizardModalComponent implements OnInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  user: any;
  wizardStructure = null;

  loading = false;

  constructor(public modalRef: BsModalRef) {
  }

  ngOnInit() {
    if (this.modalData && this.modalData.data) {
      this.user = this.modalData.data;
      if (this.user) {
        this.wizardStructure = this.user.wizard_structure;
      }
    }
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  loadingChange(event) {
    this.loading = event;
  }
}
