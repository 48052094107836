import {APP_BASE_HREF} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ThemeModule} from './@theme/theme.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxAuthModule} from './auth/auth.module';
import {AdminGuard, AuthGuard, UserGuard} from './_guards/index';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {D3Service} from 'd3-ng2-service'; // <-- import statement
import {PagesModule} from './pages/pages.module';

import {ToasterModule, ToasterService} from 'angular2-toaster';

import {DragulaModule} from 'ng2-dragula';

import {ComplexTypeService} from './_utils/complex_types/complex-types';
import {ComplexTypeModalService} from './_utils/modals/modal/complex-type-modal.service';
import {NguiAutoCompleteModule} from '@ngui/auto-complete';

import {ModalModule} from 'ngx-bootstrap/modal';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {AlertModule} from 'ngx-bootstrap/alert';

import {NgxLoadingModule} from 'ngx-loading';

import {PagesService} from './pages/pages.service';
import {LoginService} from './auth/components/login/login.service';
import {ToastService} from './_utils/toast/toast.service';
import {TypeService} from './pages/type/type.service';

import {ModalsModule} from './_utils/modals/modals.module';
import {TypeModule} from './pages/type/type.module';
import {UtilsModule} from './_utils/utils.module';

import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';

import {ColorPickerModule} from 'ngx-color-picker';
import {ApiInterceptor} from './_api/api.interceptor';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxAuthModule,
    ReactiveFormsModule,
    NgJsonEditorModule,
    PagesModule,
    ModalsModule,
    TypeModule,
    UtilsModule,
    DragulaModule,
    NguiAutoCompleteModule,
    ColorPickerModule,
    NgxLoadingModule.forRoot({}),
    ColorPickerModule,
    NgbModule.forRoot(),
    ThemeModule.forRoot(),
    ToasterModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    ButtonsModule.forRoot(),
    AlertModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'},
    AuthGuard,
    AdminGuard,
    UserGuard,
    D3Service,
    ToasterService,
    ComplexTypeService,
    ComplexTypeModalService,
    PagesService,
    LoginService,
    ToastService,
    TypeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
