import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EditContentModalComponent} from '../../../_utils/modals/modal/edit-content-modal.component';
import {
  ConnectionAuthenticationType,
  ConnectionDetails,
  ConnectionDetailSchema,
  ConnectionDetailStructure,
  ConnectionDetailsType,
  DetailSchemaType,
  PaginationType
} from '../system';

@Component({
  selector: 'app-connection-detail',
  templateUrl: './connection-detail.component.html',
  styleUrls: ['./connection-detail.component.css']
})
export class ConnectionDetailComponent implements OnInit, OnChanges {

  @Input() originalData: any;

  @Input() data: ConnectionDetails;
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() typeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() authChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() paginChange: EventEmitter<any> = new EventEmitter<any>();

  connection: ConnectionDetails;
  connectionKeys: Array<string>;

  connectionTypes: Array<any> = [];
  connectionType: ConnectionDetailsType = undefined;

  authenticationTypes: Array<any> = [];
  authenticationType: ConnectionAuthenticationType = undefined;

  paginationTypes: Array<any> = [];
  paginationType: PaginationType = undefined;

  constructor(private bsModalService: BsModalService) {
  }

  ngOnInit() {
    for (const type in ConnectionDetailsType) {
      if (isNaN(Number(type))) {
        // populate connection types for the dropdown
        this.connectionTypes.push(type);
      }
    }

    // iterate enum to get all options
    for (const type in ConnectionAuthenticationType) {
      if (isNaN(Number(type))) {
        // populate authentication types for the dropdown
        this.authenticationTypes.push(type);
      }
    }

    // iterate enum to get all options
    for (const type in PaginationType) {
      if (isNaN(Number(type))) {
        // populate authentication types for the dropdown
        this.paginationTypes.push(type);
      }
    }


    if (this.data && this.data.type) {

      // sets the connection details data type
      this.connectionType = this.data.type.toUpperCase() as ConnectionDetailsType;

      if (this.data.authentication_type) {
        // sets the authentication type
        this.authenticationType = this.data.authentication_type.toUpperCase() as ConnectionAuthenticationType;
      }

      if (this.data.pagination_type && typeof this.data.pagination_type !== 'object') {
        console.log(this.data.pagination_type);
        this.paginationType = this.data.pagination_type.toUpperCase() as PaginationType;
      }

      // set the keys from the connection details object
      const keys = Object.keys(this.data);
      keys.splice(keys.indexOf('type'), 1);
      this.connectionKeys = keys;
    }
  }


  ngOnChanges(changes) {
    if (this.data && this.data.type) {
      this.connectionType = this.data.type.toUpperCase() as ConnectionDetailsType;

      if (this.data.authentication_type) {
        this.authenticationType = this.data.authentication_type.toUpperCase() as ConnectionAuthenticationType;
      }

      if (this.data.pagination_type && typeof this.data.pagination_type !== 'object') {
        this.paginationType = this.data.pagination_type.toUpperCase() as PaginationType;
      }

      const keys = Object.keys(this.data);
      keys.splice(keys.indexOf('type'), 1);
      this.connectionKeys = keys;
    }
  }


  onTypeChange(event) {
    this.typeChange.emit(this.connectionType);
  }

  onAuthenticationChange(event) {
    this.authChange.emit(this.authenticationType);
  }

  onPaginationChange(event) {
    this.paginChange.emit(this.paginationType);
  }

  onStructureChange(event) {
    this.dataChange.emit(this.data);
  }

  onDataChange($event, item = null) {
    const value = $event.value;

    if (item) {
      this.originalData[item] = value;
      this.data[item].value = value;
    }
  }

  checkType(data, type) {
    if (data) {
      if (type === 'schema') {
        return data._ctype === 'ConnectionDetailSchema';
      } else if (type === 'structure') {
        return data._ctype === 'ConnectionDetailStructure';
      } else {
        return false;
      }
    }
  }


  openInModal(label) {
    const structure = this.data[label]._structure;

    const modalData = {
      title: 'Edit ' + label,
      label: label,
      data: structure,
      originalData: this.originalData ? this.originalData[label] : null
    };
    const initialState = {modalData: modalData};

    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(EditContentModalComponent, config);
    activeModal.content.modalResult.subscribe(response => {
      if (response && response.success) {
        this.data[label]._structure = response.payload.data;
        this.originalData[label] = response.payload.original;
        this.dataChange.emit(this.data);
      }
    });
  }


  toggleOptional(item) {
    this.data[item].optional.model = !this.data[item].optional.model;

    if (this.data[item].optional.model) {
      this.data[item].optional.bkp = this.data[item].value;
      this.data[item].value = null;
    } else {
      this.data[item].value = this.data[item].optional.bkp;
    }
    this.dataChange.emit(this.data);
  }

}
