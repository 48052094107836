export class TriggerTemplate {
  public type_destination: Array<any> = [];
  public type_origin: Array<any> = [];
  public hooks: Array<HookTriggerTemplate> = [];
}

export class HookTriggerTemplate {
  public destination = '';
  public schedule_attempts = 0;
  public schedule_delay = 0;
  public structure: Array<any> = [];
}
