import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {TypeSelectorComponent} from './type-selector/type-selector.component';
import {TypeListComponent} from './type-list/type-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    TypeSelectorComponent,
    TypeListComponent,
  ],
  exports: [
    TypeSelectorComponent,
    TypeListComponent,
  ]
})
export class TypeModule {
}
