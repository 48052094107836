import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngx-confirm-modal',
  templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {

  modalTitle = '';
  modalContent = '';
  showConfirmButton = true;

  result = {
    success: false
  };

  constructor(private activeModal: NgbActiveModal) {
  }

  closeModal(success: boolean) {
    this.result.success = success;
    this.activeModal.close(this.result);
  }

  onSubmit() {
    this.closeModal(true);
  }

}
