export enum EzyNotificationType {
  LastPing,
  SystemNotRunning,
}

export interface EzyNotification {
  type: EzyNotificationType;
  typeId: string;
  sysId?: string;
  title: string;
  subtitle: string;
  message: any;
  class: string;
}
