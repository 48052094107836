import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from '../_api/api.service';
import {LoginService} from '../auth/components/login/login.service';
import * as moment from 'moment';

@Injectable()
export class PagesService {
  private loading = false;
  public loadingEvent: EventEmitter<any>;
  public stopLoadingEvent: EventEmitter<any>;

  private pingThreshold = 5; // minutes
  private refreshInterval = 180; // seconds

  public lastPingWarning: EventEmitter<boolean>;
  public systemNotRunningWarning: EventEmitter<any>;

  constructor(private _api: ApiService, private _login: LoginService) {
    this.lastPingWarning = new EventEmitter();
    this.loadingEvent = new EventEmitter();
    this.stopLoadingEvent = new EventEmitter();
    this.systemNotRunningWarning = new EventEmitter();

    setTimeout(() => {
      const aux = _login.isAdmin() && !_login.getLoggedAsUser();
      this.getThirdParties(aux);
      setInterval(() => {
        this.getThirdParties(aux);
      }, this.refreshInterval * 1000);
    });
  }

  public setLoading(loading: boolean, showStop = false) {
    this.loading = loading;
    this.loadingEvent.emit({loading: this.loading, showStop: showStop});
  }

  public stopLoading(loading: boolean) {
    this.stopLoadingEvent.emit(loading);
  }

  public notifyPingWarning(elem): void {
    this.lastPingWarning.emit(elem);
  }

  getThirdParties(admin: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get('third_party_authorization').then(response => {
        let result = [];
        if (admin) {
          result = response.results.map(elem => {
            elem.third_party_authorization_list = this.mapParties(elem.third_party_authorization_list, admin);
            return elem;
          });
        } else {
          result = this.mapParties(response.results, admin);
        }
        resolve(result);
      }).catch(error => {
        reject(error);
      });
    });
  }

  private mapParties(array, admin) {
    return array.map(elem => {

      if (admin) {
        const json = JSON.parse(localStorage.getItem('usersList'));
        if (json) {
          const customer = json.filter(el => el.id === elem.customer_id)[0];
          if (customer) {
            elem.customerName = customer.name;
          }
        }
      }

      elem.lastPingAlert = false;
      elem.sysNotRunningAlert = false;

      const now = moment().utc();
      const pingAt = moment(elem.lw_last_ping_at).utc();
      if (elem.lw_last_ping_at) {
        const elapsed = now.diff(pingAt, 'minutes', true);

        const emitObj = {
          elem: elem,
          remove: false
        };

        if (elapsed > this.pingThreshold) {
          elem.lastPingAlert = true;
          elem.lastPingElapsed = Math.round(elapsed);
          emitObj.remove = false;
        } else {
          emitObj.remove = true;
        }
        this.notifyPingWarning(emitObj);
      }

      elem.created_at = this.formatDate(elem.created_at);
      elem.updated_at = this.formatDate(elem.updated_at);
      elem.last_request_at = this.formatDate(elem.last_request_at);
      elem.lw_last_ping_at = this.formatDate(elem.lw_last_ping_at);
      elem.lw_started_at = this.formatDate(elem.lw_started_at);

      // alert systems NOT running
      if (elem.lw_last_ping_systems) {
        elem.lw_last_ping_systems.forEach(sys => {
          const obj = {
            thirdParty: elem,
            system: sys,
            remove: false
          };

          if (!sys.is_running) {
            elem.sysNotRunningAlert = true;
            obj.remove = false;
          } else {
            obj.remove = true;
          }
          this.systemNotRunningWarning.emit(obj);
        });
      }

      return elem;
    });
  }

  formatDate(date) {
    if (date) {
      const mdate = moment(date);
      return mdate.format('DD/MM/YYYY HH:mm:ss');
    }
  }

}
