import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {DataSelectorComponent} from './data-selector/data-selector.component';
import {RefresherComponent} from './refresher/refresher.component';

import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ThemeModule} from '../@theme/theme.module';

import {DragulaModule} from 'ng2-dragula';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ClipboardModule} from 'ngx-clipboard';

import {PathGroupComponent} from './complex_types/path-group/path-group.component';
import {NavigationPathComponent} from './complex_types/navigation-path/navigation-path.component';
import {ComplexTypePathComponent} from './complex_types/complex-type-path/complex-type-path.component';
import {StructureItemComponent} from './complex_types/structure-item/structure-item.component';
import {PathGroupViewComponent} from './complex_types/path-group-view/path-group-view.component';

import {GenericDataTableComponent} from '../pages/generic-data/table/generic-data-table.component';
import {QueueTableComponent} from '../pages/queue/table/queue-table.component';

import {WizardElementComponent} from '../pages/template/wizard-element/wizard-element.component';
import {WizardComponent} from '../pages/template/wizard/wizard.component';
import {KeyNavigationPipe} from './pipes/pipes';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ThemeModule,
    ButtonsModule,
    RouterModule,
    DragulaModule,
    PopoverModule,
    AlertModule,
    ClipboardModule,
    NgxDatatableModule,
  ],
  declarations: [
    DataSelectorComponent,
    RefresherComponent,
    PathGroupComponent,
    NavigationPathComponent,
    ComplexTypePathComponent,
    StructureItemComponent,
    PathGroupViewComponent,
    GenericDataTableComponent,
    QueueTableComponent,
    WizardElementComponent,
    WizardComponent,
    KeyNavigationPipe,
  ],
  exports: [
    DataSelectorComponent,
    RefresherComponent,
    PathGroupComponent,
    NavigationPathComponent,
    ComplexTypePathComponent,
    StructureItemComponent,
    PathGroupViewComponent,
    GenericDataTableComponent,
    QueueTableComponent,
    WizardElementComponent,
    WizardComponent,
  ],
  entryComponents: [
    PathGroupComponent,
    NavigationPathComponent,
    ComplexTypePathComponent,
    StructureItemComponent,
    PathGroupViewComponent,
    GenericDataTableComponent,
    QueueTableComponent,
    WizardElementComponent,
    WizardComponent,
  ]
})
export class UtilsModule {
}
