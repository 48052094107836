import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {PagesService} from '../pages.service';
import {LoginService} from '../../auth/components/login/login.service';
import {ModalsService} from '../../_utils/modals/modals.service';
import {QuestionBase, TextboxQuestion} from '../../_utils/forms';
import {Router} from '@angular/router';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css'],
  providers: [ApiService]
})
export class TemplateComponent implements OnInit {

  isAdmin = false;
  loggedAsUser = null;

  templatesList = [];
  filtTemplatesList = [];
  selectedTemplate = null;
  filterTemplateList = '';


  constructor(private _api: ApiService,
              private _pageService: PagesService,
              private _login: LoginService,
              private _modals: ModalsService,
              private _router: Router) {
  }

  ngOnInit() {
    this.setLoading(true);
    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();

    this.loadData();
  }

  setLoading(status) {
    this._pageService.setLoading(status);
  }

  onRefresh() {
    this.loadData();
  }

  loadData() {
    this.setLoading(true);
    if (this.isAdmin && !this.loggedAsUser) {
      this.loadTemplatesList();
    } else {
      this.setLoading(false);
    }
  }


  loadTemplatesList() {
    this._api.get('template', {}).then(response => {
      if (response && response.results) {
        this.templatesList = response.results;
        this.templatesList.sort((a, b) => a.name.toUpperCase() >= b.name.toUpperCase() ? 1 : -1);
        this.assignTemplateCopy();
        localStorage.setItem('templatesList', JSON.stringify(this.templatesList));
      }

      const templateFilterInputLS = localStorage.getItem('templateFilterInput');
      if (templateFilterInputLS) {
        const obj = JSON.parse(templateFilterInputLS);
        this.filterTemplateList = obj.value;
        this.filterTemplateItem(this.filterTemplateList);
      }

      this.setLoading(false);
    }).catch(() => {
      this.setLoading(false);
    });
  }

  selectTemplate(template: any) {
    this.selectedTemplate = template;
  }


  addUserFromTemplate(template: any) {
    const editTemplate = {
      email: '',
      name: '',
      password: ''
    };

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        value: editTemplate.email,
        required: true,
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: editTemplate.name,
        required: true,
        order: 2
      }),
      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        value: editTemplate.password,
        required: true,
        order: 3
      })
    ];

    const formData = {
      title: 'New User from Template',
      questions: questions
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        const payload = res.payload;

        this._modals.showConfirmModal('Create User from Template?', '').then(cRes => {
          if (cRes && cRes.success) {
            this.setLoading(true);
            if (payload.email) {
              editTemplate.email = payload.email;
            }
            if (payload.name) {
              editTemplate.name = payload.name;
            }
            if (payload.password) {
              editTemplate.password = payload.password;
            }

            payload.register_type = 'customer';

            this._api.put('customer/' + template.id + '/copy', editTemplate).then(result => {
              // the result is the newly created user
              if (result && result.wizard_structure) {
                this._login.updateUsersList().then(() => {
                  this.setLoading(true);
                  this._router.navigate(['/pages/dashboard'], {queryParams: {userId: result.id}}).then(() => {
                    window.location.reload(true);
                  });
                });
              }

              this.setLoading(false);
            }).catch(() => this.setLoading(false));
          }
        });
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }


  assignTemplateCopy() {
    this.filtTemplatesList = Object.assign([], this.templatesList);
  }

  filterTemplateItem(value) {
    if (!value) {
      this.assignTemplateCopy();
    }

    const obj = {value: value};
    localStorage.setItem('templateFilterInput', JSON.stringify(obj));

    this.filtTemplatesList = Object.assign([], this.templatesList).filter(item => {
      return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
  }


  editUser(user: any) {
    const id = user.id;

    const editUser = {
      email: '',
      name: '',
      password: null
    };

    if (user.email) {
      editUser.email = user.email;
    }
    if (user.name) {
      editUser.name = user.name;
    }
    // if (user.password) { editUser.password = user.password; }

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        value: editUser.email,
        required: false,
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: editUser.name,
        required: false,
        order: 2
      }),
      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        type: 'password',
        value: null,
        required: false,
        order: 3
      })
    ];

    const formData = {
      title: 'Edit User',
      questions: questions
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        this.setLoading(true);
        const payload = res.payload;
        if (!payload.password || payload.password === '') {
          delete payload['password'];
        }
        this._api.put('customer/' + id, payload).then(() => {
          this._login.updateUsersList().then(() => this.loadTemplatesList());
        });
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }


  duplicateTemplate(template: any) {
    const editTemplate = {
      email: '',
      name: '',
      password: '',
      register_type: 'template'
    };

    if (template.email) {
      editTemplate.email = template.email;
    }
    if (template.name) {
      editTemplate.name = template.name;
    }
    if (template.password) {
      editTemplate.password = template.password;
    }

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        value: editTemplate.email,
        required: false,
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: editTemplate.name,
        required: false,
        order: 2
      }),
      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        value: editTemplate.password,
        required: false,
        order: 3
      })
    ];

    const formData = {
      title: 'Copy Template',
      questions: questions
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        const payload = res.payload;

        this._modals.showConfirmModal('Copy Template?', '').then(cRes => {
          if (cRes && cRes.success) {
            this.setLoading(true);
            if (payload.email) {
              editTemplate.email = payload.email;
            }
            if (payload.name) {
              editTemplate.name = payload.name;
            }
            if (payload.password) {
              editTemplate.password = payload.password;
            }

            this._api.put('customer/' + template.id + '/copy', editTemplate).then(() => this.loadTemplatesList());
          }
        });
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }


  editTemplateWizard(template: any) {
    if (template) {
      const modalData = {
        title: 'Edit Wizard Structure',
        data: template.wizard_structure,
      };

      this._modals.showEditTypeModal(modalData).subscribe(data => {
        if (data.success && data.payload) {
          this.setLoading(true);
          this._api.put('customer/' + template.id, {wizard_structure: data.payload}).then(() => {
            // this._toast.showToast('success', 'Success', 'Success updating type!');
            this.loadData();
          }).catch(error => {
            console.error(error);
            // this._toast.showToast('error', 'PUT Error', 'Error updating type!');
            this.setLoading(false);
          });
        }
      });
    }
  }

}
