import {AfterViewInit, Component, EventEmitter, OnInit, Renderer, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';


@Component({
  selector: 'ngx-edit-type-modal',
  templateUrl: './edit-type-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class EditTypeModalComponent implements OnInit, AfterViewInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };
  system: any;

  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;

  viewReady = false;
  editorIsValid = true;

  jsonData: any;


  constructor(private renderer: Renderer, public modalRef: BsModalRef) {
    this.editorOptions = new JsonEditorOptions();

    const _this = this;
    this.editorOptions.onChange = function () {
      try {
        _this.editor.get();
        _this.editorIsValid = true;
      } catch (err) {
        console.log(err);
        _this.editorIsValid = false;
      }
    };
  }

  ngOnInit() {

    const aux = this.modalData.data;
    delete aux['links'];
    delete aux['append_links'];
    this.jsonData = aux;

    this.initEditorOptions();
    this.renderer.setElementStyle(this.editor.jsonEditorContainer.nativeElement, 'height', '100%');
  }

  ngAfterViewInit() {
    this.viewReady = true;
  }

  saveChanges() {
    this.result.success = true;
    const json = this.editor.get();
    this.result.payload = JSON.parse(JSON.stringify(json));
    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  initEditorOptions() {
    this.editorOptions.mode = 'code';
  }

  formatDate(date) {
    const mdate = moment(date);
    return mdate.format('DD/MM/YYYY HH:mm:ss');
  }

}

