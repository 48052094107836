import {ApplicationRef, Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs/Observable';
import {Subscription} from 'rxjs/Subscription';

import {ModalComponent} from './modal/modal.component';
import {FormModalComponent} from './modal/form-modal.component';
import {ConfirmModalComponent} from './modal/confirm-modal.component';
import {JsonModalComponent} from './modal/json-modal.component';
import {HookModalComponent} from './modal/hook-modal.component';
import {PreHookModalComponent} from './modal/pre-hook-modal.component';
import {ComplexTypeModalComponent} from './modal/complex-type-modal.component';
import {EditSystemModalComponent} from './modal/edit-system-modal.component';
import {EditTypeModalComponent} from './modal/edit-type-modal.component';
import {ReprocessingRuleModalComponent} from './modal/reprocessing-rule-modal.component';
import {ExportCsvModalComponent} from './modal/export-csv-modal.component';
import {HistoryModalComponent} from './modal/history-modal.component';
import {EditLinkModalComponent} from './modal/edit-link-modal.component';
import {WizardModalComponent} from './modal/wizard-modal.component';

import {BsModalService} from 'ngx-bootstrap/modal';

import * as moment from 'moment';
import {combineLatest} from 'rxjs';
import {ScheduleTypeModalComponent} from './modal/schedule-type-modal/schedule-type-modal.component';
import {IModalData} from '../interfaces/imodal-data';


@Injectable()
export class ModalsService {

  modals: any = {};

  constructor(private modalService: NgbModal, private bsModalService: BsModalService, private appRef: ApplicationRef) {
  }


  subscribe() {
    const date = moment().unix();

    const _combine = combineLatest(
      this.bsModalService.onShow,
      this.bsModalService.onShown,
      this.bsModalService.onHide,
      this.bsModalService.onHidden
    ).subscribe(() => this.appRef.tick());

    const subscriptions = [];

    subscriptions.push(
      this.bsModalService.onShow.subscribe(() => {
        this.adjustIndex();
      })
    );
    subscriptions.push(
      this.bsModalService.onShown.subscribe(() => {
      })
    );
    subscriptions.push(
      this.bsModalService.onHide.subscribe(() => {
        this.adjustIndex();
      })
    );
    subscriptions.push(
      this.bsModalService.onHidden.subscribe(() => {
        const elements = document.getElementsByClassName('modal');
        if (elements.length === 0) {
          this.unsubscribe(date);
        }
      })
    );

    subscriptions.push(_combine);
    this.modals[date] = subscriptions;
  }

  adjustIndex() {
    setTimeout(() => {
      const elements = document.getElementsByClassName('modal') as HTMLCollectionOf<HTMLElement>;
      const backdrop = document.getElementsByClassName('modal-backdrop') as HTMLCollectionOf<HTMLElement>;

      let maxZ = 0;
      for (let i = 0; i < elements.length; i++) {
        const zIndex = 1050 + (10 * i);
        elements[i].style.zIndex = zIndex.toString();
        maxZ = zIndex;
      }

      if (backdrop[0]) {
        backdrop[0].style.zIndex = (maxZ - 5).toString();
      }
    });
  }

  unsubscribe(id) {
    this.modals[id].forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    delete this.modals[id];
  }


  public showSampleModal(): Promise<any> {
    const activeModal = this.modalService.open(ModalComponent, {size: 'lg', container: 'nb-layout'});
    activeModal.componentInstance.modalHeader = 'SAMPLE Modal';
    return activeModal.result;
  }

  public showFormModal(formData: any): Promise<any> {
    const activeModal = this.modalService.open(FormModalComponent, {size: 'lg', container: 'nb-layout'});
    activeModal.componentInstance.formData = formData;
    return activeModal.result;
  }

  public showConfirmModal(title: string, content: string, showConfirm = true): Promise<any> {
    const activeModal = this.modalService.open(ConfirmModalComponent, {size: 'sm', container: 'nb-layout'});
    activeModal.componentInstance.modalTitle = title;
    activeModal.componentInstance.modalContent = content;
    activeModal.componentInstance.showConfirmButton = showConfirm;
    return activeModal.result;
  }

  public showJsonModal(modalData: IModalData) {
    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(JsonModalComponent, config);
    return activeModal.content.modalResult;
  }

  public showHookModal(modalData: IModalData) {
    this.subscribe();

    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(HookModalComponent, config);
    return activeModal.content.event;
  }

  public showPreHookModal(modalData: IModalData) {
    this.subscribe();

    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(PreHookModalComponent, config);
    return activeModal.content.event;
  }

  public showComplexTypeModal(): Promise<any> {
    const activeModal = this.modalService.open(ComplexTypeModalComponent, {size: 'sm', container: 'nb-layout'});
    return activeModal.result;
  }


  public showEditSystemModal(modalData: IModalData) {
    this.subscribe();

    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(EditSystemModalComponent, config);
    return activeModal.content.modalResult;
  }

  public showEditTypeModal(modalData: IModalData) {
    this.subscribe();

    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(EditTypeModalComponent, config);
    return activeModal.content.modalResult;
  }


  public showReprocessingRuleModal(modalData: IModalData) {
    this.subscribe();

    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };
    const activeModal = this.bsModalService.show(ReprocessingRuleModalComponent, config);
    return activeModal.content.modalResult;
  }


  public showExportCsvModal(modalData: IModalData) {
    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };
    const activeModal = this.bsModalService.show(ExportCsvModalComponent, config);
    return activeModal.content.modalResult;
  }


  public showHistoryModal(modalData: IModalData) {
    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };
    const activeModal = this.bsModalService.show(HistoryModalComponent, config);
    return activeModal.content.modalResult;
  }

  public showScheduleTypeModal(modalData: IModalData) {
    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };
    const activeModal = this.bsModalService.show(ScheduleTypeModalComponent, config);
    return activeModal.content.modalResult;
  }

  public showEditLinkModal(modalData: IModalData) {
    this.subscribe();

    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };
    const activeModal = this.bsModalService.show(EditLinkModalComponent, config);
    return activeModal.content.modalResult;
  }


  public showWizardModal(modalData: IModalData) {
    const initialState = {modalData: modalData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };
    const activeModal = this.bsModalService.show(WizardModalComponent, config);
    return activeModal.content.modalResult;
  }

}
