import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@theme/theme.module';
import { DashboardComponent } from './dashboard.component';
import { Router, RouterModule } from '@angular/router';
import { UtilsModule } from '../../_utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule,
    RouterModule,
    UtilsModule,
  ],
  declarations: [DashboardComponent]
})
export class DashboardModule { }
