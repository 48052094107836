import {ModalsService} from './modals.service';

import {NgModule} from '@angular/core';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';

import {ModalComponent} from './modal/modal.component';
import {FormModalComponent} from './modal/form-modal.component';
import {ConfirmModalComponent} from './modal/confirm-modal.component';
import {JsonModalComponent} from './modal/json-modal.component';
import {HookModalComponent} from './modal/hook-modal.component';
import {PreHookModalComponent} from './modal/pre-hook-modal.component';
import {ComplexTypeModalComponent} from './modal/complex-type-modal.component';
import {EditSystemModalComponent} from './modal/edit-system-modal.component';
import {EditTypeModalComponent} from './modal/edit-type-modal.component';
import {EditContentModalComponent} from './modal/edit-content-modal.component';
import {ReprocessingRuleModalComponent} from './modal/reprocessing-rule-modal.component';
import {ExportCsvModalComponent} from './modal/export-csv-modal.component';
import {HistoryModalComponent} from './modal/history-modal.component';
import {EditLinkModalComponent} from './modal/edit-link-modal.component';
import {EditValidationModalComponent} from './modal/edit-validation-modal.component';
import {DataSelectorModalComponent} from './modal/data-selector-modal.component';
import {WizardModalComponent} from './modal/wizard-modal.component';

import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClipboardModule} from 'ngx-clipboard';
import {PopoverModule} from 'ngx-bootstrap/popover';

import {DynamicFormComponent} from '../forms/dynamic-form.component';
import {DynamicFormQuestionComponent} from '../forms/dynamic-form-question.component';
import {ComplexTypeEditorComponent} from '../complex_types/complex-type-editor.component';

import {ThemeModule} from '../../@theme/theme.module';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {DragulaModule} from 'ng2-dragula';
import {NguiAutoCompleteModule} from '@ngui/auto-complete';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AlertModule} from 'ngx-bootstrap/alert';

import {TextMaskModule} from 'angular2-text-mask';

import {TypeModule} from '../../pages/type/type.module';
import {UtilsModule} from '../utils.module';

import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {ColorPickerModule} from 'ngx-color-picker';

import {ConnectionDetailComponent} from '../../pages/system/connection-detail/connection-detail.component';
import {ConnectionDetailElementComponent} from '../../pages/system/connection-detail/connection-detail-element.component';
import {ConnectionDetailStructureComponent} from '../../pages/system/connection-detail/connection-detail-structure.component';

import {SystemSettingsComponent} from '../../pages/system/system-settings/system-settings.component';
import {ScheduleTypeModalComponent} from './modal/schedule-type-modal/schedule-type-modal.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    NgJsonEditorModule,
    DragulaModule,
    NguiAutoCompleteModule,
    ModalModule,
    AlertModule,
    TextMaskModule,
    TypeModule,
    UtilsModule,
    ButtonsModule,
    ClipboardModule,
    PopoverModule,
    NgxDatatableModule,
    ColorPickerModule
  ],
  exports: [
    ModalComponent,
    FormModalComponent,
    ConfirmModalComponent,
    JsonModalComponent,
    HookModalComponent,
    PreHookModalComponent,
    ComplexTypeModalComponent,
    EditSystemModalComponent,
    EditTypeModalComponent,
    EditContentModalComponent,
    ReprocessingRuleModalComponent,
    ExportCsvModalComponent,
    HistoryModalComponent,
    EditLinkModalComponent,
    EditValidationModalComponent,
    DataSelectorModalComponent,
    WizardModalComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ComplexTypeEditorComponent,
    ConnectionDetailComponent,
    ConnectionDetailElementComponent,
    ConnectionDetailStructureComponent,
    SystemSettingsComponent,
    ScheduleTypeModalComponent
  ],
  declarations: [
    // KeysPipe,
    ModalComponent,
    FormModalComponent,
    ConfirmModalComponent,
    JsonModalComponent,
    HookModalComponent,
    PreHookModalComponent,
    ComplexTypeModalComponent,
    EditSystemModalComponent,
    EditTypeModalComponent,
    EditContentModalComponent,
    ReprocessingRuleModalComponent,
    ExportCsvModalComponent,
    HistoryModalComponent,
    EditLinkModalComponent,
    EditValidationModalComponent,
    DataSelectorModalComponent,
    WizardModalComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ComplexTypeEditorComponent,
    ConnectionDetailComponent,
    ConnectionDetailElementComponent,
    ConnectionDetailStructureComponent,
    SystemSettingsComponent,
    ScheduleTypeModalComponent,
  ],
  providers: [
    ModalsService,
  ],
  entryComponents: [
    ModalComponent,
    FormModalComponent,
    ConfirmModalComponent,
    JsonModalComponent,
    HookModalComponent,
    PreHookModalComponent,
    ComplexTypeModalComponent,
    EditSystemModalComponent,
    EditTypeModalComponent,
    EditContentModalComponent,
    ReprocessingRuleModalComponent,
    ExportCsvModalComponent,
    HistoryModalComponent,
    EditLinkModalComponent,
    EditValidationModalComponent,
    DataSelectorModalComponent,
    WizardModalComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    ConnectionDetailComponent,
    ConnectionDetailElementComponent,
    ConnectionDetailStructureComponent,
    SystemSettingsComponent,
    ScheduleTypeModalComponent
  ],
})
export class ModalsModule {
}
