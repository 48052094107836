import {AfterViewInit, Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';
import {TypeService} from '../../../pages/type/type.service';
import {ComplexTypeModalService} from './complex-type-modal.service';
import {HookModalComponent} from './hook-modal.component';
import {ApiService} from '../../../_api/api.service';
import {HookTriggerTemplate} from '../../../pages/reprocessing-rule/reprocessing-rule';

@Component({
  selector: 'ngx-reprocessing-rule-modal',
  templateUrl: './reprocessing-rule-modal.component.html',
  styleUrls: ['./modal.component.css'],
  providers: [ApiService],
})
export class ReprocessingRuleModalComponent implements OnInit, AfterViewInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  viewReady = false;

  typeOriginList = [];
  typeDestinationList = [];

  hooksList = [];

  constructor(public modalRef: BsModalRef,
              private _typeService: TypeService,
              private ctmService: ComplexTypeModalService,
              private bsModalService: BsModalService,
              private _api: ApiService) {
  }

  ngOnInit() {
    this._typeService.load();

    if (this.modalData && this.modalData.data) {
      this.typeOriginList = this.modalData.data.type_origin;
      this.typeDestinationList = this.modalData.data.type_destination;


      this.hooksList = this.modalData.data.hooks.map((elem, idx) => {
        elem._idx = idx;
        elem._isExpanded = false;
        elem.destinationModel = {
          id: elem.destination,
          editMode: false
        };
        return elem;
      });
    }

  }

  ngAfterViewInit() {
    this.viewReady = true;
  }

  saveChanges() {
    this.result.success = true;
    this.result.payload = {
      type_origin: this.typeOriginList,
      type_destination: this.typeDestinationList,
      hooks: this.hooksList
    };
    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }


  formatDate(date) {
    const mdate = moment(date);
    return mdate.format('DD/MM/YYYY HH:mm:ss');
  }


  hookTypeChanged($event, item) {
    const action = $event.action;
    if (action === 'save') {
      item.destination = $event.id;
      item.destinationModel.id = $event.id;
      item.destinationModel.editMode = false;
    }
  }

  hookPathChanged($event, hook) {
    hook.external_id_path = $event;
  }


  listChanged($event, list) {
    if (list === 'origin') {
      this.typeOriginList = $event;
    } else if (list === 'destination') {
      this.typeDestinationList = $event;
    }
  }


  editStructure(hook) {

    const originData = {
      created_at: 'Wed, 06 Dec 2017 00:07:26 GMT',
      customer_id: '12345',
      data_id: '12345|12345',
      id: '12345',
      is_local_processed: false,
      is_processed: true,
      log: 'Status_code: 201',
      old_id: null,
      return_object: {},
      structure_destination: {},
      structure_origin: {},
      type_destination: '12345',
      type_origin: '12345',
      updated_at: 'Wed, 06 Dec 2017 00:09:01 GMT'
    };

    const idFrom = this.ctmService.getTypesInfo().typeFrom;
    const idTo = this.ctmService.getTypesInfo().typeTo;

    const hookData = {structure: hook.structure};

    const modalData = {
      title: 'Edit Structure',
      hookType: 'post-hook',
      typeFrom: idFrom,
      typeTo: idTo,
      data: hookData,
      originData: originData,
      isReturn: true,
      dataType: 'reprocessing',
      typeFromList: this.typeOriginList,
      typeToList: this.typeDestinationList,
      showCommitAfterPublish: false,
    };
    const initialState = {modalData: modalData};

    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(HookModalComponent, config);
    activeModal.content.event.subscribe(response => {
      if (response && response.success && response.payload) {
        hook.structure = response.payload.hookStructure;
      }
    });

  }

  addNewHook() {
    const dest = {
      destinationModel: {
        id: null,
        editMode: false
      }
    };
    const newHook = new HookTriggerTemplate();
    const newObj = {...dest, ...newHook};
    this.hooksList.push(newObj);
  }


  deleteHook(hook) {
    const index = this.hooksList.indexOf(hook);
    this.hooksList.splice(index, 1);
  }


  numberMask(rawValue) {
    const digitRegExp = /\d/;
    return rawValue.split('').map((char) => digitRegExp.test(char) ? digitRegExp : char);
  }


  getHookDestination(dest) {
    if (dest && dest != '') {
      const label = this._typeService.getSystemTypeLabel(dest);
      return label;
    } else {
      return 'NO DESTINATION';
    }
  }

}
