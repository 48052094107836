import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../../_api/api.service';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {PathGroupComponent} from '../../complex_types/path-group/path-group.component';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {PagesService} from '../../../pages/pages.service';

enum HookTabs {
  Structure = 'Structure',
  Advanced = 'Advanced',
  Result = 'Result',
  Issues = 'Issues',
  Data = 'Data'
}

@Component({
  selector: 'ngx-pre-hook-modal',
  templateUrl: './pre-hook-modal.component.html',
  styleUrls: ['./modal.component.css'],
  providers: [ApiService]
})
export class PreHookModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();

  hookTabs = HookTabs;
  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };
  originData = null;
  validationResult = null;
  isValidResult = false;

  currentTab = this.hookTabs.Structure;

  propertyPath: { values: Array<any> };

  @ViewChild('pathGroup') pathGroup: PathGroupComponent;

  @ViewChild('advEditor') advEditor: JsonEditorComponent;
  public advEditorOptions: JsonEditorOptions;

  @ViewChild('dataViewer') dataViewer: JsonEditorComponent;
  public dataViewerOptions: JsonEditorOptions;

  @ViewChild('resultEditor') resultEditor: JsonEditorComponent;
  public resultEditorOptions: JsonEditorOptions;


  constructor(public modalRef: BsModalRef, private _pageService: PagesService, private _api: ApiService) {
    this.propertyPath = {
      values: new Array<any>()
    };
  }

  ngOnInit() {
    this.advEditor.jsonEditorContainer.nativeElement.style.height = '100%';
    this.advEditorOptions = new JsonEditorOptions();
    this.advEditorOptions.mode = 'code';

    this.dataViewer.jsonEditorContainer.nativeElement.style.height = '100%';
    this.dataViewerOptions = new JsonEditorOptions();
    this.dataViewerOptions.mode = 'code';

    this.resultEditor.jsonEditorContainer.nativeElement.style.height = '100%';
    this.resultEditorOptions = new JsonEditorOptions();
    this.resultEditorOptions.mode = 'view';

    if (this.modalData.originData) {
      this.originData = this.modalData.originData;
    }

    if (this.modalData.hasOwnProperty('data')) {
      if (this.modalData.data.property) {
        const property = this.modalData.data.property;
        if (typeof property === 'string') {
          this.propertyPath.values = [property];
        } else {
          this.propertyPath.values = property;
        }
      }
    }
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }

  closeModal() {
    this.event.emit(this.result);
    this.modalRef.hide();
  }

  tabChanged(event) {
    if (event.tabTitle == HookTabs.Advanced) {
      this.currentTab = this.hookTabs.Advanced;
      this.advEditor.set(JSON.parse(JSON.stringify(this.propertyPath.values)));
    } else {
      this.commitJsonStructure();
    }

    if (event.tabTitle == HookTabs.Structure) {
      this.currentTab = this.hookTabs.Structure;
      const json = this.advEditor.get();
      const aux = JSON.parse(JSON.stringify(json));
      this.propertyPath.values = aux;
      this.pathGroup.updateDaraArray(aux);
    }

    if (event.tabTitle == HookTabs.Result) {
      this.currentTab = this.hookTabs.Result;
    }

    if (event.tabTitle == HookTabs.Issues) {
      this.currentTab = this.hookTabs.Issues;
    }

  }

  commitJsonStructure() {
    const json = this.advEditor.get();
    this.propertyPath.values = JSON.parse(JSON.stringify(json));
  }

  onChange(event) {
    this.propertyPath.values = event;
    this.advEditor.set(JSON.parse(JSON.stringify(this.propertyPath.values)));
    this.invalidateTest();
  }

  runTestAll() {
    this.setLoading();

    if (this.currentTab == this.hookTabs.Advanced) {
      this.commitJsonStructure();
    }

    const structure = {
      field_origin: this.propertyPath.values,
      field_destination: ['result'],
      validation: []
    };

    const data = {
      origin: this.originData,
      structure: [structure]
    };

    let newData = {};
    try {
      newData = this.dataViewer.get();
    } catch (err) {
      console.log(err);
    }
    data.origin = newData;

    this._api.post('../../ui/transformdict', data).then(res => {
      if (res && res.hasOwnProperty('result')) {

        if (Object.keys(data.origin).length === 0 && data.origin.constructor === Object) {
          this.isValidResult = true;
        } else {
          this.isValidResult = !(Array.isArray(res.result) || (typeof res.result === 'object'));
        }
      } else {
        this.isValidResult = false;
      }
      console.log(res);
      this.validationResult = res;
      this.loaded();
    }).catch(err => {
      console.log(err);
      this.invalidateTest();
      this.loaded();
    });
  }

  invalidateTest() {
    this.isValidResult = false;
    this.validationResult = null;
  }

  saveChanges() {
    this.result = {
      success: true,
      payload: this.propertyPath.values
    };
    this.closeModal();
  }

  restoreData() {
    this.dataViewer.set(this.originData);
  }


}
