import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-navigation-path',
  templateUrl: './navigation-path.component.html',
  styleUrls: ['./navigation-path.component.css'],
})
export class NavigationPathComponent {

  @Input() element: any;
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  @Output() elementChange = new EventEmitter<any>();

  saveChanges(elem) {
    elem.edit = false;
    this.elementChange.emit(elem);
  }

  removeElement(elem) {
    elem.confirmRemove = true;
  }

  confirmRemoveElement() {
    this.removed.emit();
  }

  cancelRemoveElement(elem) {
    elem.confirmRemove = false;
    elem.edit = false;
    this.elementChange.emit(elem);
  }

  toggleEditMode(element, status) {
    element.edit = status;
    this.elementChange.emit(element);
  }

}
