import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {ComplexTypeModalService} from '../modals/modal/complex-type-modal.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {HookModalComponent} from '../modals/modal/hook-modal.component';
import {ComplexSchema, ComplexSchemaCollection} from './complex-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';


@Component({
  selector: 'complex-type-editor',
  templateUrl: './complex-type-editor.component.html',
  styleUrls: ['./complex-type-editor.component.css'],
})
export class ComplexTypeEditorComponent implements OnInit {

  @Input() schema: { key: string, value: ComplexSchema } | null;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modelEditor') editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;

  selectedOption: any = undefined;

  @Input() args?: any;
  @Input() compModel?: any;
  @Output() compModelChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() prePath: any;

  arrayModel = [];
  jsonModel: any;
  isOptional = false;
  isOptionalModel = false;

  innerSchemaData: any = null;

  numberMask: any;

  constructor(private ctmService: ComplexTypeModalService, private bsModalService: BsModalService) {
    this.numberMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowNegative: true,
      allowLeadingZeroes: true,
      includeThousandsSeparator: false,
      integerLimit: null,
    });
  }


  ngOnInit() {

    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'code';

    if (this.schema) {

      if (this.schema.value.optional !== undefined) {
        this.isOptional = this.schema.value.optional.opt;

        if (this.compModel !== undefined) {
          this.schema.value.optional.model = false;
        }

        this.isOptionalModel = this.schema.value.optional.model;
      }

      if (this.schema.value.options && this.schema.value.options.length > 0) {
        if (this.compModel) {
          this.selectedOption = this.compModel;
        } else {
          this.selectedOption = this.schema.value.default;
        }
        setTimeout(() => this.onChange(this.selectedOption));
      }

      if (this.schema.value.type === 'path_group') {
        if (!this.compModel) {
          this.compModel = [];
        }
      }

      if (this.schema.value.type === 'schema') {
        this.mapInnerSchema();
      }

      if (this.schema.value.type === 'array') {
        if (!this.compModel) {
          this.compModel = [{value: ''}];
          this.arrayModel = this.compModel;
        } else {
          this.arrayModel = this.compModel.map((elem, i) => {
            return {value: elem};
          });
        }
      }

      if (this.schema.value.type === 'any') {
        this.jsonModel = this.compModel;
        setTimeout(() => this.editor.set(JSON.parse(JSON.stringify(this.jsonModel))));
      }

      if (this.schema.value.type === 'array_pg') {
        if (!this.compModel) {
          this.compModel = [{value: []}];
          this.arrayModel = this.compModel;
        } else {
          this.arrayModel = this.compModel.map((elem, i) => {
            return {value: elem};
          });
        }
      }

    }

    if (!this.schema && this.args) {
      setTimeout(() => this.onChange(this.args));
    }

    const _th = this;
    this.editorOptions.onChange = function () {
      try {
        _th.onChange(_th.editor.get());
      } catch (err) {
        _th.onChange(undefined);
      }
    };
  }

  onChange(event) {
    this.compModel = event;
    this.compModelChange.emit(this.compModel);
  }

  onPathChange(event, type) {
    const ret = {
      event: event,
      type: type
    };
    this.compModelChange.emit(event);
  }


  isOptionalChanged() {
    this.schema.value.optional.model = this.isOptionalModel;
    this.onPathChange(this.compModel, 'origin');
  }


  mapInnerSchema() {

    const csCollection = this.schema.value.schema as ComplexSchemaCollection;
    const csc = [];

    Object.getOwnPropertyNames(csCollection).map(k => {
      const val = csCollection[k];
      const obj = {
        detail: {key: k, value: val},
        model: val.default
      };

      if (this.compModel && this.compModel.hasOwnProperty(k)) {
        obj.model = this.compModel[k];
      }

      csc.push(obj);
    });

    this.innerSchemaData = csc;
  }

  innerModelChanged() {
    this.compModel = this.innerSchemaData.reduce((obj, item) => {
      if (item.detail.value.type === 'integer') {
        obj[item.detail.key] = +item.model;
      } else {
        obj[item.detail.key] = item.model;
      }
      return obj;
    }, {});
    this.compModelChange.emit(this.compModel);

  }

  updateArray(event, index) {
    this.arrayModel[index].value = event;
    this.arrayChange();
  }

  arrayChange() {
    const returnArray = this.arrayModel.map(elem => elem.value);
    this.compModelChange.emit(returnArray);
  }

  addNewArrayElement() {
    if (this.schema) {
      if (this.schema.value.type === 'array') {
        this.arrayModel.push({value: ''});
      }

      if (this.schema.value.type === 'array_pg') {
        this.arrayModel.push({value: []});
      }
    }
  }

  removeArrayElement(index) {
    this.arrayModel.splice(index, 1);
    this.arrayChange();
  }


  editStructure() {
    const hookData = this.ctmService.getHookData();
    const preOriginData = this.ctmService.getPreOriginData();

    const idFrom = this.ctmService.getTypesInfo().typeFrom;
    const idTo = this.ctmService.getTypesInfo().typeTo;

    const modalData = {
      title: 'Edit Structure',
      hookType: 'post-hook',
      typeFrom: idFrom,
      typeTo: idTo,
      data: hookData,
      preOriginData: preOriginData,
      isReturn: true
    };
    const initialState = {modalData: modalData};

    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(HookModalComponent, config);
    activeModal.content.event.subscribe(response => {
      if (response && response.success && response.payload) {
        this.onChange(response.payload.hookStructure);
      }
    });
  }

}
