import {Component, EventEmitter, OnInit, Renderer2, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ApiService} from '../../../_api/api.service';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import * as moment from 'moment';


@Component({
  selector: 'ngx-history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class HistoryModalComponent implements OnInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  @ViewChild('pop') pop: PopoverDirective;
  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };
  next_url: string;
  historyData: any = [];
  historyContent: any = null;

  constructor(public modalRef: BsModalRef, private _api: ApiService, private renderer: Renderer2) {
    this.editorOptions = new JsonEditorOptions();
  }

  ngOnInit() {
    this.modalData.title = 'History Viewer';
    this.editorOptions.mode = 'view';
    this.renderer.setStyle(this.editor.jsonEditorContainer.nativeElement, 'height', '100%');

    if (this.modalData && this.modalData.name) {
      this.modalData.title = this.modalData.name + ' - ' + this.modalData.title;
    }

    if (this.modalData.data) {
      const id = this.modalData.data;
      this.loadHistory(id);
    }
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }


  loadHistory(id) {
    this._api.get('history/' + id).then(jsonRes => {
      this.next_url = jsonRes.next_url;
      if (jsonRes.results) {
        this.historyData = this.historyData.concat(jsonRes.results.map(item => item.structure));
      }
      if (this.historyData.length > 0) {
        this.showHistory(this.historyData[0]);
      }
    });
  }

  showHistory(content) {
    this.historyContent = content;
    this.editor.set(this.historyContent);
  }

  loadMore() {
    if (this.next_url) {
      const new_id = this.next_url.replace('/api/v1/history/', '');
      this.loadHistory(new_id);
    }
  }

  stringifyModel() {
    return JSON.stringify(this.historyContent, null, 2);
  }

  dateTimeToMoment(date) {
    return moment(date).local().format('DD/MM/YYYY HH:mm:ss');
  }

  onShown(): void {
    setTimeout(() => {
      this.pop.hide();
    }, 1000);
  }

}


