import {AfterViewInit, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {ApiService} from '../../../_api/api.service';

import {DragulaService} from 'ng2-dragula';

import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {DataSelectorModalComponent} from './data-selector-modal.component';
import {ComplexTypeModalService} from './complex-type-modal.service';

import {PagesService} from '../../../pages/pages.service';
import {TypeService} from '../../../pages/type/type.service';

enum HookTabs {
  Structure = 'Structure',
  Advanced = 'Advanced',
  Result = 'Result',
  Data = 'Data'
}

@Component({
  selector: 'ngx-hook-modal',
  templateUrl: './hook-modal.component.html',
  styleUrls: ['./modal.component.css'],
  providers: [ApiService]
})
export class HookModalComponent implements OnInit, AfterViewInit {

  public event: EventEmitter<any> = new EventEmitter();

  @ViewChild('advEditor') advEditor: JsonEditorComponent;
  @ViewChild('resultEditor') resultEditor: JsonEditorComponent;
  @ViewChild('dataViewer') dataViewer: JsonEditorComponent;

  public advEditorOptions: JsonEditorOptions;
  public resultEditorOptions: JsonEditorOptions;
  public dataViewerOptions: JsonEditorOptions;

  public jsonValid = true;

  showCommitAfterPublish = true;

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  jsonHookData = null;
  hookStructure = [];
  filteredStructure = [];
  preOriginData: any;
  hookStructureHandler = [];
  filtStructureHandler = [];
  validationResult: any;
  isValidResult = false;
  hookTabs = HookTabs;
  originData = null;
  isFilterActive = false;
  filterInput = '';
  isReturn = false;
  currentTab = this.hookTabs.Structure;
  commitAfterPublish = false;
  onHold = false;

  showTestResult = false;
  runTestResult = false;

  constructor(private _api: ApiService,
              private dragulaService: DragulaService,
              private bsModalService: BsModalService,
              public modalRef: BsModalRef,
              private ctmService: ComplexTypeModalService,
              private _pageService: PagesService,
              private _typeService: TypeService) {

    const bag: any = this.dragulaService.find('bag-parent');
    if (bag !== undefined) {
      this.dragulaService.destroy('bag-parent');
    }
    dragulaService.setOptions('bag-parent', {
      moves: function (el, container, handle) {
        return handle.id === 'bag-handle';
      }
    });
  }

  ngOnInit() {
    const _th = this;

    this.advEditor.jsonEditorContainer.nativeElement.style.height = '100%';
    this.resultEditor.jsonEditorContainer.nativeElement.style.height = '100%';
    this.dataViewer.jsonEditorContainer.nativeElement.style.height = '100%';

    this.advEditorOptions = new JsonEditorOptions();
    this.advEditorOptions.mode = 'code';

    this.advEditorOptions.onChange = () => {
      this.invalidateTest();
      this.validateJson();
    };

    this.resultEditorOptions = new JsonEditorOptions();
    this.resultEditorOptions.mode = 'view';

    this.dataViewerOptions = new JsonEditorOptions();
    this.dataViewerOptions.mode = 'code';

    if (this.modalData.data.structure) {
      this.hookStructure = this.modalData.data.structure;
      this.filteredStructure = this.modalData.data.structure;

      this.hookStructure.forEach(() => {
        this.hookStructureHandler.push({editMode: false});
        this.filtStructureHandler.push({editMode: false});
      });

    }

    if (this.modalData.originData) {
      this.originData = this.modalData.originData;
      this.jsonHookData = this.originData;
    }

    if (this.modalData.preOriginData) {
      this.preOriginData = this.modalData.preOriginData;
    }

    if (this.modalData.hasOwnProperty('isReturn')) {
      this.isReturn = this.modalData.isReturn;
    }

    if (this.modalData.data.hasOwnProperty('commit_after_publish')) {
      this.commitAfterPublish = this.modalData.data.commit_after_publish;
    } else {
      this.modalData.data.commit_after_publish = false;
    }

    if (this.modalData.data.hasOwnProperty('on_hold')) {
      this.onHold = this.modalData.data.on_hold;
    } else {
      this.modalData.data.on_hold = false;
    }

    if (this.modalData.hasOwnProperty('showCommitAfterPublish')) {
      this.showCommitAfterPublish = this.modalData.showCommitAfterPublish;
    }

    this.ctmService.setTypesInfo(this.modalData.typeFrom, this.modalData.typeTo);
  }

  ngAfterViewInit() {
    this.clearFilter();
  }


  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }


  closeModal() {
    this.event.emit(this.result);
    this.modalRef.hide();
  }

  saveChanges() {
    if (this.currentTab == this.hookTabs.Advanced) {
      this.commitJsonStructure();
    }

    this.result = {
      success: true,
      payload: {
        hookStructure: this.hookStructure,
        commitAfterPublish: this.commitAfterPublish,
        onHold: this.onHold
      }

    };
    this.closeModal();
  }


  onChange(value, index, structure) {
    if (!value.hasOwnProperty('edit')) {
      if (value.type === 'origin') {
        // this.hookStructure[index].field_origin = value.event;
        structure[index].field_origin = value.event;
      }

      if (value.type === 'destination') {
        // this.hookStructure[index].field_destination = value.event;
        structure[index].field_destination = value.event;
      }

      this.advEditor.set(JSON.parse(JSON.stringify(this.hookStructure)));
    }

    this.invalidateTest();
  }


  tabChanged(event) {

    if (this.currentTab != this.hookTabs.Advanced) {
      this.advEditor.set(JSON.parse(JSON.stringify(this.hookStructure)));
    }

    if (event.tabTitle == HookTabs.Advanced) {
      this.currentTab = this.hookTabs.Advanced;
      this.advEditor.set(JSON.parse(JSON.stringify(this.hookStructure)));
    } else {
      this.commitJsonStructure();
    }

    if (event.tabTitle == HookTabs.Structure) {
      this.currentTab = this.hookTabs.Structure;
    }

    if (event.tabTitle == HookTabs.Data) {
      this.currentTab = this.hookTabs.Data;
      if (this.preOriginData) {
        if (Array.isArray(this.preOriginData)) {
          this.originData = this.preOriginData[0];
          this.jsonHookData = this.originData;
        } else {
          this.originData = this.preOriginData;
          this.jsonHookData = this.originData;
        }
      }
    }

    if (event.tabTitle == HookTabs.Result) {
      this.currentTab = this.hookTabs.Result;
    }
  }

  commitJsonStructure() {
    const json = this.advEditor.get();
    this.hookStructure = JSON.parse(JSON.stringify(json));

    this.hookStructureHandler = [];
    this.filtStructureHandler = [];
    this.hookStructure.forEach(() => {
      this.hookStructureHandler.push({editMode: false});
      this.filtStructureHandler.push({editMode: false});
    });

    this.filterItem();
  }


  addNewStructureItem() {
    const obj = {
      validation: [],
      field_origin: ['ORIGIN'],
      field_destination: ['DESTINATION']
    };
    this.hookStructure.push(obj);
    this.hookStructureHandler.push({editMode: true});

    this.invalidateTest();
    this.filterItem();
  }

  removeStructureItem(isFiltered, index, item) {
    let hIndex = 0;
    this.hookStructure.filter((elem, ind) => {
      if (elem === item) {
        hIndex = ind;
      }
      return elem === item;
    });

    this.hookStructure.splice(hIndex, 1);
    this.hookStructure = [...this.hookStructure];

    this.hookStructureHandler.splice(hIndex, 1);
    this.hookStructureHandler = [...this.hookStructureHandler];

    this.invalidateTest();
    this.filterItem(isFiltered);
  }


  duplicateStructureItem(isFiltered, index, item) {
    let hIndex = 0;
    let newObj;
    this.hookStructure.filter((elem, ind) => {
      if (elem === item) {
        hIndex = ind;
      }
      return elem === item;
    });

    if (isFiltered) {
      newObj = Object.assign({}, this.filteredStructure[index]);
    } else {
      newObj = Object.assign({}, this.hookStructure[index]);
    }

    this.hookStructure.splice(hIndex, 0, newObj);
    this.hookStructure = [...this.hookStructure];

    this.hookStructureHandler.push({editMode: false});
    this.hookStructureHandler[hIndex + 1].editMode = true;
    this.hookStructureHandler = [...this.hookStructureHandler];

    this.invalidateTest();
    this.filterItem(isFiltered);
  }


  restoreData() {
    this.dataViewer.set(this.jsonHookData);
  }

  runTestAll() {
    const _th = this;

    this.showTestResult = false;
    this.runTestResult = false;

    this.setLoading();

    if (this.currentTab == this.hookTabs.Advanced) {
      this.commitJsonStructure();
    }

    const data = {
      origin: this.jsonHookData,
      structure: this.hookStructure
    };

    if (this.preOriginData) {
      if (Array.isArray(this.preOriginData)) {
        data.origin = this.preOriginData[0];
      } else {
        data.origin = this.preOriginData;
      }
    }

    let newData = {};
    try {
      newData = this.dataViewer.get();
    } catch (err) {
      console.log(err);
    }
    data.origin = newData;

    if (!data.structure || data.structure.length === 0) {
      this.invalidateTest();
      this.showTestResult = true;
      this.runTestResult = false;
      this.loaded();
    } else {
      this._api.post('../../ui/transformdict', data).then(res => {
        console.log(res);
        this.isValidResult = true;
        this.validationResult = res;

        this.showTestResult = true;
        this.runTestResult = true;

        this.loaded();
      }).catch(err => {
        console.log(err);
        this.invalidateTest();

        this.showTestResult = true;
        this.runTestResult = false;

        this.loaded();
      });
    }
  }

  invalidateTest() {
    this.isValidResult = false;
    this.validationResult = null;
  }

  filterItem(active = false) {
    this.isFilterActive = active;
    let count = 0;
    this.filteredStructure = this.hookStructure.filter((elem, ind) => {
      if (JSON.stringify(elem).toLowerCase().indexOf(this.filterInput.toLowerCase()) > -1) {
        this.filtStructureHandler[count] = this.hookStructureHandler[ind];
        count++;
        return elem;
      }
    });
  }

  clearFilter() {
    this.filterInput = '';
    this.isFilterActive = false;
  }


  selectDataFromModal() {

    let dataType = 'hook';
    if (this.modalData.dataType) {
      dataType = this.modalData.dataType;
    }

    const modalData = {
      title: 'Select Data',
      typeFrom: this.modalData.typeFrom,
      typeFromList: this.modalData.typeFromList,
      typeToList: this.modalData.typeToList,
      dataType: dataType
    };
    const initialState = {modalData: modalData};

    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(DataSelectorModalComponent, config);
    activeModal.content.modalResult.subscribe(response => {
      if (response && response.success) {
        if (response.payload) {
          if (dataType === 'hook' && response.payload.structure) {
            this.dataViewer.set(response.payload.structure);
          }

          if (dataType === 'reprocessing') {
            if (response.payload.parent && response.payload.parent.value) {
              this.dataViewer.set(response.payload.parent.value);
            }
          }
        }
      }
    });

  }

  private validateJson() {
    try {
      const json = JSON.parse(this.advEditor.getText());
      this.jsonValid = true;
    } catch (e) {
      this.jsonValid = false;
    }
  }

}
