import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {PagesService} from '../pages.service';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {ModalsService} from '../../_utils/modals/modals.service';
import {LoginService} from '../../auth/components/login/login.service';
import {QuestionBase} from '../../_utils/forms/question-base';
import {TextboxQuestion} from '../../_utils/forms/question-textbox';
import {DropdownQuestion} from '../../_utils/forms/question-dropdown';
import {ScheduleCalendarComponent} from './schedule-calendar/schedule-calendar.component';
import {ToastService} from '../../_utils/toast/toast.service';

@Component({
  selector: 'app-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.css'],
  providers: [ApiService],
})
export class ThirdPartyComponent implements OnInit {

  @ViewChild('pop') pop: PopoverDirective;

  @ViewChildren(ScheduleCalendarComponent) schedCalendars: QueryList<ScheduleCalendarComponent>;

  loading = false;
  parties: any;
  isAdmin = false;
  loggedAsUser = null;
  adminView = false;

  tpOptions = [
    {key: 'local_worker', value: 'Local Worker'},
    {key: 'ftp_worker', value: 'FTP Worker'},
    {key: 'passive_worker', value: 'Passive Worker'},
    {key: 'other', value: 'Other'}
  ];

  timeMask: any;

  constructor(private _api: ApiService,
              private _modals: ModalsService,
              private _pageService: PagesService,
              private _toast: ToastService,
              private _login: LoginService) {

    this.timeMask = {
      mask: function (rawValue) {
        const emptyString = '';
        const rawValueLength = rawValue.length;
        const rawArray = rawValue.split(emptyString);
        const first = +rawArray[0];

        if (rawValueLength === 2) {
          if (first === 2) {
            return [/[0-2]/, /[0-3]/, ':', /[0-5]/, /\d/];
          }
        }
        return [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
      },
      guide: false,
      showMask: true,
    };
  }

  ngOnInit() {
    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();
    this.adminView = this.isAdmin && !this.loggedAsUser;

    this.loadData();
  }

  onRefresh() {
    this.loadData();
  }


  loadData() {
    this.setLoading();
    this.loading = true;

    this._pageService.getThirdParties(this.adminView).then(result => {
      this.parties = result;
      this.loaded();
    }).catch(err => {
      console.error(err);
      this.loaded();
    });
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
    this.loading = false;
  }

  toggleDetails(party) {
    party.expanded = !party.expanded;
  }

  onShown(): void {
    setTimeout(() => {
      this.pop.hide();
    }, 1000);
  }

  toggleActiveParty(party) {
    const action = party.is_active ? 'inactivate' : 'activate';
    this.setLoading();
    this._api.put('third_party_authorization/' + party.id + '/' + action, {}).then(() => {
      this.loadData();
    }).catch(err => {
      console.error(err);
      this.loaded();
    });

    party.is_active = !party.is_active;
  }

  addParty() {
    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'description',
        label: 'Description',
        value: '',
        required: true,
        order: 1
      }),
      new DropdownQuestion({
        key: 'app_type',
        label: 'App Type',
        value: '',
        required: true,
        order: 2,
        options: this.tpOptions
      })];

    const formData = {
      title: 'Add new Third-party Authorization',
      questions: questions,
      flipEnabled: false
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        const payload = res.payload;
        this.setLoading();
        this._api.post('third_party_authorization', payload).then(() => {
          this.loadData();
        }).catch(err => {
          console.error(err);
          this.loaded();
        });
      }
    }).catch(err => {
      console.log('ERR', err);
    });

  }

  editParty(party) {
    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'description',
        label: 'Description',
        value: party.description,
        required: true,
        order: 1
      }),
      new DropdownQuestion({
        key: 'app_type',
        label: 'App Type',
        value: party.app_type,
        required: true,
        order: 2,
        options: this.tpOptions
      })];

    const formData = {
      title: 'Edit Third-party Authorization',
      questions: questions,
      flipEnabled: false
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        const payload = res.payload;
        this.setLoading();
        this._api.put('third_party_authorization/' + party.id, payload).then(() => {
          this.loadData();
        }).catch(err => {
          console.error(err);
          this.loaded();
        });
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }

  deleteParty(party) {
    this._modals.showConfirmModal('Delete Third-Party Authorization?',
      'Do you want to delete the third-party authorization: ' + party.description + '?').then(cRes => {
      if (cRes && cRes.success) {
        this.setLoading();
        this._api.api_delete('third_party_authorization/' + party.id).then(res => {
          if (res.success) {
            this.loadData();
          } else {
            this.loaded();
          }
        }).catch(err => {
          console.error(err);
          this.loaded();
        });
      }
    });
  }

  listToString(list) {
    return list.join(', ');
  }

  restartLocalWorker(party) {
    if (!party.lw_restart) {
      this._modals.showConfirmModal('Restart Local Worker?',
        'Do you want to restart the Local Worker: ' + party.description + '?').then(cRes => {
        if (cRes && cRes.success) {
          this.setLoading();
          this._api.put('third_party_authorization/' + party.id, {lw_restart: true}).then(response => {
            console.log(response);
            this._toast.showToast('success', 'Restart Local Worker', 'Local worker set to restart: ' + party.description);
            this.loadData();
          }).catch(error => {
            this._toast.showToast('error', 'Error', 'Error restarting local worker: ' + error);
            this.loaded();
          });
        }
      });
    }
  }


  updateLocalWorker(party) {
    if (!party.lw_start_update) {
      this._modals.showConfirmModal('Update Local Worker?',
        'Do you want to update the Local Worker: ' + party.description + '?').then(cRes => {
        if (cRes && cRes.success) {
          this.setLoading();
          this._api.put('third_party_authorization/' + party.id, {lw_start_update: true}).then(response => {
            console.log(response);
            this._toast.showToast('success', 'Update Local Worker', 'Local worker set to update: ' + party.description);
            this.loadData();
          }).catch(error => {
            this._toast.showToast('error', 'Error', 'Error updating local worker: ' + error);
            this.loaded();
          });
        }
      });
    }
  }


  renewToken(party) {
    this._modals.showConfirmModal('Renew Token?', 'Do you want to renew the token for: ' + party.description + '?').then(cRes => {
      if (cRes && cRes.success) {
        this.setLoading();
        this._api.put('third_party_authorization/' + party.id + '/renew_token', {}).then(() => {
          this._toast.showToast('success', 'Renew Token', 'Token renewed for ' + party.description);
          this.loadData();
        }).catch(error => {
          this._toast.showToast('error', 'Error', 'Error renewing token: ' + error);
          this.loaded();
        });
      }
    });
  }


  toggleLocalWorkerHold(party) {
    party.lw_on_hold = !party.lw_on_hold;

    this.setLoading();
    this._api.put('third_party_authorization/' + party.id, {lw_on_hold: party.lw_on_hold}).then(() => {
      this.loadData();
    }).catch(err => {
      console.error(err);
      this.loaded();
    });
  }

  saveIdleSchedule(idx, party) {
    const cal = this.schedCalendars.toArray().find(elem => elem.id === 'cal-' + idx);
    const psched = cal.getParsedSchedule();
    this.setLoading();
    this._api.put('third_party_authorization/' + party.id, {lw_idle_schedule: psched}).then(() => {
      this.loadData();
    }).catch(err => {
      console.error(err);
      this.loaded();
    });
  }

  allowSaveIdle(party): boolean {
    return party.lw_idle_schedule.filter((elem: ThirdPartySchedule) => {
      return !(elem.start.day
        && elem.stop.day
        && elem.start.time
        && elem.start.time.length === 5
        && elem.stop.time
        && elem.stop.time.length === 5);
    }).length > 0;
  }

}


export class ThirdPartySchedule {
  public stop: { day: string, time: string };
  public start: { day: string, time: string };

  constructor() {
    this.stop = {day: null, time: null};
    this.start = {day: null, time: null};
  }
}

