import {BehaviorSubject} from 'rxjs/BehaviorSubject';


export class GenericData {
  id: string;
  created_at: string;
  updated_at: string;
  external_id: string;
  structure: object;
}


export class GenericDataObservable {
  dataChange: BehaviorSubject<GenericData[]> = new BehaviorSubject<GenericData[]>([]);
  get data(): GenericData[] { return this.dataChange.value; }

  addData(new_data) {
    let copiedData = this.data.slice();
    copiedData = copiedData.concat(new_data);
    this.dataChange.next(copiedData);
  }

  removeData(data) {
    let copiedData = this.data.slice();
    copiedData = copiedData.filter(elem => data.indexOf(elem) === -1);
    this.dataChange.next(copiedData);
  }
}
