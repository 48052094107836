import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TypeService} from '../type.service';

@Component({
  selector: 'app-type-selector',
  templateUrl: './type-selector.component.html',
  styleUrls: ['./type-selector.component.css']
})
export class TypeSelectorComponent implements OnInit {

  @Input() type: TypeSelector;
  @Output() typeChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() allowDelete = true;
  allTypesListDisplay = [];
  selectedTypeId: string = undefined;

  deleteMode = false;

  constructor(private _typeService: TypeService) {
  }

  ngOnInit() {
    this._typeService.getAllTypesList().then(response => {
      this.allTypesListDisplay = response.map(elem => {
        return {id: elem, label: this.getLabel(elem)};
      })
        .sort((a, b) => a.label.toUpperCase() >= b.label.toUpperCase() ? 1 : -1);
    });

    if (this.type && this.type.id) {
      this.selectedTypeId = this.type.id;
    }
  }

  getLabel(id) {
    return this._typeService.getSystemTypeLabel(id);
  }

  enterEditMode() {
    this.type.editMode = true;
  }

  toggleDeleteMode(status) {
    this.deleteMode = status;
  }

  confirmDelete() {
    const event = {id: this.selectedTypeId, action: 'delete'};
    this.typeChange.emit(event);
  }

  saveEdited() {
    const event = {id: this.selectedTypeId, action: 'save'};
    this.typeChange.emit(event);
  }

  changeSelectedType() {
  }
}


class TypeSelector {
  id: string;
  editMode = false;
}
