import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {CalendarEvent, CalendarEventTimesChangedEvent, CalendarEventTitleFormatter} from 'angular-calendar';
import {DayViewHourSegment} from 'calendar-utils';
import {fromEvent, merge, Observable} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {addDays, addMinutes, endOfWeek, setDay, startOfWeek} from 'date-fns';
import {ModalsService} from '../../../_utils/modals/modals.service';


function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {

  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  weekTooltip(event: CalendarEvent, title: string) {
    if (!event.meta.tmpEvent) {
      return super.weekTooltip(event, title);
    }
  }

  dayTooltip(event: CalendarEvent, title: string) {
    if (!event.meta.tmpEvent) {
      return super.dayTooltip(event, title);
    }
  }
}


class ScheduleObject {
  public start: { day: string, time: string };
  public stop: { day: string, time: string };
}


@Component({
  selector: 'app-schedule-calendar',
  templateUrl: './schedule-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./schedule-calendar.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleCalendarComponent implements OnInit {

  @Input() id: string;

  view = 'month';
  viewDate: Date = new Date();
  events: CalendarEvent[] = [];
  dragToCreateActive = false;

  timeMask: any;
  dayOptions = [
    {key: 'SUN', display: 'Sunday'},
    {key: 'MON', display: 'Monday'},
    {key: 'TUE', display: 'Tuesday'},
    {key: 'WED', display: 'Wednesday'},
    {key: 'THU', display: 'Thursday'},
    {key: 'FRI', display: 'Friday'},
    {key: 'SAT', display: 'Saturday'}
  ];

  weekDays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  private _schedule: Array<ScheduleObject>;
  @Input() set schedule(schedule: Array<ScheduleObject>) {
    this._schedule = schedule;
    this.createEventsFromSchedule(schedule);
  }

  isEditingEvent = false;
  eventToEdit = null;
  originalEventToEdit: CalendarEvent = null;
  parsedSchedule = null;

  constructor(private cdr: ChangeDetectorRef, private _modals: ModalsService) {

    this.timeMask = {
      mask: function (rawValue) {
        const emptyString = '';
        const rawValueLength = rawValue.length;
        const rawArray = rawValue.split(emptyString);
        const first = +rawArray[0];

        if (rawValueLength === 2) {
          if (first === 2) {
            return [/[0-2]/, /[0-3]/, ':', /[0-5]/, /\d/];
          }
        }
        return [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
      },
      guide: false,
      showMask: true,
    };

  }

  ngOnInit() {
  }


  createEventsFromSchedule(schedule: Array<ScheduleObject>) {
    if (schedule && schedule.length > 0) {

      const startWeek = startOfWeek(this.viewDate);
      const endWeek = endOfWeek(this.viewDate);

      schedule.forEach(elem => {
        const start = elem.start;
        const stop = elem.stop;

        const startTime = start.time.split(':');
        const stopTime = stop.time.split(':');

        const startIndex = this.getDayFromShort(start.day);
        const stopIndex = this.getDayFromShort(stop.day);

        const eventStartDate = setDay(startWeek, stopIndex);
        eventStartDate.setHours(+stopTime[0], +stopTime[1]);

        const eventStopDate = setDay(startWeek, startIndex);
        eventStopDate.setHours(+startTime[0], +startTime[1]);

        if (eventStopDate < eventStartDate) {
          // break in 2 events
          const event1: CalendarEvent = this.createEvent(eventStartDate, endWeek);
          const event2: CalendarEvent = this.createEvent(startWeek, eventStopDate);
          this.events = [...this.events, event1, event2];

        } else {
          const event: CalendarEvent = this.createEvent(eventStartDate, eventStopDate);
          this.events = [...this.events, event];
        }
      });
      this.refresh();
    }
  }


  getDayFromShort(day): number {
    let idx = 0;
    switch (day) {
      case 'SUN':
        idx = 0;
        break;
      case 'MON':
        idx = 1;
        break;
      case 'TUE':
        idx = 2;
        break;
      case 'WED':
        idx = 3;
        break;
      case 'THU':
        idx = 4;
        break;
      case 'FRI':
        idx = 5;
        break;
      case 'SAT':
        idx = 6;
        break;
    }
    return idx;
  }


  eventTimesChanged({event, newStart, newEnd}: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh();
  }


  createEvent(start: Date, end?: Date): CalendarEvent {
    const ev: CalendarEvent = {
      title: 'IDLE PERIOD',
      cssClass: 'custom-event',
      color: {
        primary: '#ad2121',
        secondary: '#FAE3E3'
      },
      start: start,
      meta: {
        tmpEvent: true
      },
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: true,
      actions: [
        {
          label: '<i class="far fa-edit"></i>',
          onClick: ({event}: { event: CalendarEvent }): void => {
            this.editEvent(event);
          }
        },
        {
          label: '<i class="fas fa-trash"></i>',
          onClick: ({event}: { event: CalendarEvent }): void => {
            this.deleteEvent(event);
          }
        }
      ]
    };
    if (end) {
      ev.end = end;
    }
    return ev;
  }


  startDragToCreate(segment: DayViewHourSegment, mouseDownEvent: MouseEvent, segmentElement: HTMLElement) {
    const dragToSelectEvent: CalendarEvent = this.createEvent(segment.date);

    this.events = [...this.events, dragToSelectEvent];
    const segmentPosition = segmentElement.getBoundingClientRect();
    this.dragToCreateActive = true;
    const endOfView = endOfWeek(this.viewDate);

    const mouseDown$ = fromEvent(document, 'mousedown');
    const mouseMove$ = fromEvent(document, 'mousemove');
    const allEvents$ = merge(mouseDown$, mouseMove$);

    allEvents$.pipe(
      finalize(() => {
        delete dragToSelectEvent.meta.tmpEvent;
        this.dragToCreateActive = false;
        this.refresh();
      }),
      takeUntil(fromEvent(document, 'mouseup'))
    )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        const minutesDiff = ceilToNearest(mouseMoveEvent.clientY - segmentPosition.top, 30);
        const daysDiff = floorToNearest(mouseMoveEvent.clientX - segmentPosition.left, segmentPosition.width) / segmentPosition.width;
        const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
        if (newEnd > segment.date && newEnd < endOfView) {
          dragToSelectEvent.end = newEnd;
        }

        if (newEnd.getDay() === 0 && newEnd.getHours() === 0 && newEnd.getMinutes() === 0) {
          dragToSelectEvent.end = endOfView;
        }
        this.refresh();
      });
  }

  private refresh() {
    this.events = [...this.events];
    this.parseAllEvents();
    // this.checkOverlap();
    this.cdr.detectChanges();
  }


  addZero(i) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  deleteEvent(event) {
    this._modals.showConfirmModal('Delete schedule?', 'Do you want to delete this schedule?').then(cRes => {
      if (cRes && cRes.success) {
        // this.events = this.events.filter(iEvent => iEvent !== event);
        this.events.splice(this.events.indexOf(event), 1);
        this.refresh();
      }
    });
  }

  editEvent(event) {
    this.isEditingEvent = true;
    this.originalEventToEdit = event;
    this.eventToEdit = this.parseEvent(event);
  }

  isConfirmEnabled() {
    if (this.eventToEdit.start.day
      && this.eventToEdit.stop.day
      && this.eventToEdit.start.time
      && this.eventToEdit.start.time.length === 5
      && this.eventToEdit.stop.time
      && this.eventToEdit.stop.time.length === 5) {
      if (this.weekDays.indexOf(this.eventToEdit.stop.day) > this.weekDays.indexOf(this.eventToEdit.start.day)) {
        return false;
      } else {
        return !(this.eventToEdit.stop.day === this.eventToEdit.start.day && this.eventToEdit.start.time <= this.eventToEdit.stop.time);
      }
    } else {
      return false;
    }
  }

  confirmEditEvent() {
    const startWeek = startOfWeek(this.viewDate);

    const start = this.eventToEdit.start;
    const stop = this.eventToEdit.stop;

    const startTime = start.time.split(':');
    const stopTime = stop.time.split(':');

    const startIndex = this.getDayFromShort(start.day);
    const stopIndex = this.getDayFromShort(stop.day);

    const eventStartDate = setDay(startWeek, stopIndex);
    eventStartDate.setHours(+stopTime[0], +stopTime[1]);

    const eventStopDate = setDay(startWeek, startIndex);
    eventStopDate.setHours(+startTime[0], +startTime[1]);

    this.originalEventToEdit.start = eventStartDate;
    this.originalEventToEdit.end = eventStopDate;

    this.cancelEditEvent();
    this.refresh();
  }

  cancelEditEvent() {
    this.isEditingEvent = false;
    this.originalEventToEdit = null;
    this.eventToEdit = null;
  }

  parseAllEvents() {

    const sortedRanges = this.events.sort((prev, curr) => {
      const prevTime = prev.start.getTime();
      const currTime = curr.start.getTime();
      if (prevTime < currTime) {
        return -1;
      }
      if (prevTime === currTime) {
        return 0;
      }
      return 1;
    });

    const result = sortedRanges.reduce((result1, current, idx) => {
      if (idx === 0) {
        result1.push(current);
        return result1;
      }
      const previous = result1[result1.length - 1];
      if (previous.end && current.start) {
        const previousEnd = previous.end.getTime();
        const currentStart = current.start.getTime();
        const overlapA = (previousEnd >= currentStart);
        if (overlapA) {
          previous.end = current.end;
        } else {
          result1.push(current);
        }
      }

      return result1;
    }, []);

    this.events = [...result];
  }

  parseEvent(event) {
    const evStart = event.start;
    const evEnd = event.end;
    let auxStart = null;
    if (evEnd) {
      auxStart = {
        day: this.weekDays[evEnd.getDay()],
        time: this.addZero(evEnd.getHours()) + ':' + this.addZero(evEnd.getMinutes())
      };
    }

    return {
      start: auxStart,
      stop: {
        day: this.weekDays[evStart.getDay()],
        time: this.addZero(evStart.getHours()) + ':' + this.addZero(evStart.getMinutes())
      }
    };
  }


  getParsedSchedule() {
    const first = this.events[0];
    const last = this.events[this.events.length - 1];
    let overlapWeekend = false;
    if (first && last) {
      overlapWeekend = (last.end.getHours() === endOfWeek(this.viewDate).getHours()
        && last.end.getMinutes() === endOfWeek(this.viewDate).getMinutes()
        && first.start.getTime() === startOfWeek(this.viewDate).getTime());
    }

    const parsedSchedule = this.events.map(elem => {
      return this.parseEvent(elem);
    });

    if (overlapWeekend) {
      parsedSchedule[0].stop = parsedSchedule[parsedSchedule.length - 1].stop;
      parsedSchedule.pop();
    }
    this.parsedSchedule = parsedSchedule;

    return this.parsedSchedule;
  }

}
