import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {LoginService} from '../../../auth/components/login/login.service';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {SidebarService, SidebarType} from '../../../pages/sidebar.service';
import {PagesService} from '../../../pages/pages.service';
import {EzyNotification, EzyNotificationType} from './ezy-notification';
import {EzyNotificationQueue} from './ezy-notification-queue';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  devMode: boolean;
  private environment = environment;

  currentUser = null;
  isAdmin = false;
  isLoggedUser = false;
  loggedAsUser = null;


  userMenu = [
    {title: 'Log out', link: '/auth/login'}
  ];

  notifications: EzyNotificationQueue;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private _login: LoginService,
              private titleService: Title,
              private router: Router,
              private customSidebarService: SidebarService,
              private _pageService: PagesService) {

    this.notifications = new EzyNotificationQueue();

    this._pageService.lastPingWarning.subscribe(emitObj => {
      if (emitObj && emitObj.elem) {
        const elem = emitObj.elem;

        const notif = {} as EzyNotification;
        notif.type = EzyNotificationType.LastPing;
        notif.typeId = elem.id;

        notif.title = (elem.customerName ? 'Customer: ' + elem.customerName : null);
        notif.subtitle = 'Third-Party Auth: ' + elem.description;

        notif.message = 'Last ping: ' + elem.lastPingElapsed + ' minutes';
        notif.class = 'badge badge-danger';

        if (emitObj.remove) {
          this.notifications.remove(notif);
        } else {
          if (elem.is_active) {
            this.notifications.add(notif, true);
          } else {
            this.notifications.remove(notif);
          }
        }

      }
    });

    this._pageService.systemNotRunningWarning.subscribe(elem => {
      if (elem) {
        const thirdParty = elem.thirdParty;
        const system = elem.system;

        const notif = {} as EzyNotification;
        notif.type = EzyNotificationType.SystemNotRunning;
        notif.typeId = thirdParty.id;
        notif.sysId = system.id;

        notif.title = (thirdParty.customerName ? 'Customer: ' + thirdParty.customerName : null);
        notif.subtitle = 'Third-Party Auth: ' + thirdParty.description;

        notif.message = system.alias + ' - Not Running';
        notif.class = 'badge badge-danger';

        if (elem.remove) {
          this.notifications.remove(notif);
        } else {
          if (thirdParty.is_active) {
            this.notifications.add(notif, true);
          } else {
            this.notifications.remove(notif);
          }
        }
      }
    });
  }

  ngOnInit() {

    this.devMode = !this.environment.production;

    const auxCurrentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (auxCurrentUser) {
      if (auxCurrentUser.constructor === Array) {
        this.currentUser = auxCurrentUser[0];
      } else {
        this.currentUser = auxCurrentUser;
      }
    }

    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();
    this.isLoggedUser = this.loggedAsUser != null;

    if (this.loggedAsUser) {
      this.titleService.setTitle((this.environment.production ? '' : '[DEV] ') + this.loggedAsUser.name + ' - EzyLink');
    } else {
      this.titleService.setTitle((this.environment.production ? '' : '[DEV] ') + 'EzyLink');
    }

  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleGenericData() {
    this.router.navigate(['/pages', {outlets: {sideRoute: 'generic-data'}}], {queryParamsHandling: 'merge'}).then(res => {
      this.customSidebarService.toggle('settings-sidebar', SidebarType.Data);
    });

  }

  toggleGatewayQueue() {
    this.router.navigate(['/pages', {outlets: {sideRoute: 'queue'}}], {queryParamsHandling: 'merge'}).then(res => {
      this.customSidebarService.toggle('settings-sidebar', SidebarType.Queue);
    });
  }


  notificationClicked(notif: EzyNotification) {
    if (notif.type === EzyNotificationType.LastPing || notif.type === EzyNotificationType.SystemNotRunning) {
      this.router.navigate(['/pages/third-party'], {queryParamsHandling: 'merge'});
    }
  }

  goToHome() {
    // this.menuService.navigateHome();
  }

  startSearch() {
    // this.analyticsService.trackEvent('startSearch');
  }

  onMenuItemClick() {
  }

  showAlerts() {

  }
}



