import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {System} from '../system/system';
import {isNullOrUndefined} from 'util';

const STORAGE_OAUTH_PATH = 'OAUTH_DATA_STORAGE';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  styleUrls: ['./oauth.component.css']
})
export class OauthComponent implements OnInit {

  private route_subscriber;
  system_id: string;
  system: System;
  code: string;
  loading = true;
  params: Params;
  error_message;
  userId: string;

  constructor(private _api: ApiService, private _route: ActivatedRoute, private _router: Router) {
  }

  ngOnInit() {
    this.route_subscriber = this._route.queryParams.subscribe(params => {
      this.system_id = params['system_id'];
      this.code = params['code'];
      this.userId = params['userId'];
      this.params = params;
      this.loadData();
    });
  }

  loadData() {
    const local = JSON.parse(localStorage.getItem(STORAGE_OAUTH_PATH));
    localStorage.removeItem(STORAGE_OAUTH_PATH);

    if (this.system_id) {
      this._api.get('system/' + this.system_id).then(data => {
        this.system = data;
        const details = this.system.connection_details;
        if (details && details['oauth2']) {
          if (details['oauth2'].data && details['oauth2'].data.token) {
            this.loading = false;
          } else {
            this.authorize();
          }
        } else {
          this._router.navigate(['/pages/config']);
        }
      });
    } else {
      if (local) {
        if (isNullOrUndefined(this.params['state'])) {
          this.params['state'] = local.state;
        }

        if (local.userId) {
          this._router.navigate(['/pages/oauth'], {
            queryParams: {userId: local.userId, state: local.state},
            queryParamsHandling: 'merge'
          }).then(res => {

          });
        } else {
          this._api.get('oauth2/callback', this.params).catch(err => {
            this.error_message = err;
          }).then(() => {
            this._router.navigate(['/pages/config']);
          });
        }
      } else {
        const tempUserId = this.params['userId'];
        if (tempUserId) {
          this._api.get('oauth2/callback', this.params).catch(err => {
            this.error_message = err;
          }).then(() => {
            this._router.navigate(['/pages/config'], {queryParams: {userId: tempUserId}});
          });
        } else {
          this._router.navigate(['/pages/config']);
        }
      }
    }
  }

  authorize() {
    this._api.get('oauth2/authorize/' + this.system.id).then(data => {
      localStorage.setItem(STORAGE_OAUTH_PATH, JSON.stringify({system_id: this.system.id, state: data.state, userId: this.userId}));
      window.location.href = data.authorization_url;
    });
  }

}
