import {Injectable} from '@angular/core';
import {ApiService} from '../../../_api/api.service';

@Injectable()
export class ComplexTypeModalService {

  private modalCount = 0;
  private typesInfo = {
    typeFrom: null,
    typeTo: null
  };

  private originData: any;
  private preOriginData: any;
  private hookData: any;

  constructor(private _api: ApiService) {
  }

  getCount(): number {
    return this.modalCount;
  }

  increaseCount() {
    this.modalCount++;
  }

  decreaseCount() {
    if (this.modalCount > 0) {
      this.modalCount--;
    }
  }

  public getTypesInfo(): any {
    return this.typesInfo;
  }

  public setTypesInfo(typeFrom, typeTo) {
    this.typesInfo.typeFrom = typeFrom;
    this.typesInfo.typeTo = typeTo;
  }

  public setOriginData(data) {
    this.originData = data;
  }

  public getOriginData(): any {
    return this.originData;
  }

  public setPreOriginData(data) {
    this.preOriginData = data;
  }

  public getPreOriginData(): any {
    return this.preOriginData;
  }

  public setHookData(data) {
    this.hookData = data;
  }

  public getHookData(): any {
    return this.hookData;
  }


  runPreTest(pathData = null, prePath, dataArray = null) {
    const promise = new Promise((resolve, reject) => {
      this.getTypesInfo();
      let aux;

      if (dataArray) {
        aux = dataArray;
      }

      if (pathData) {
        const arr = pathData.array;
        const ind = pathData.index;
        aux = arr.slice(0, ind);
      }

      let origin = [];
      if (prePath) {
        origin = prePath.concat(aux);
      } else {
        origin = aux;
      }

      const obj = {
        field_destination: ['result'],
        field_origin: origin,
        validation: []
      };

      const data = {
        origin: this.originData,
        structure: [obj]
      };

      this._api.post('../../ui/transformdict', data).then(res => {
        if (res && res.hasOwnProperty('result')) {
          resolve(res.result);
        } else {
          reject();
        }
      }).catch(error => {
        reject(error);
      });
    });
    return promise;
  }

}
