import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserLogin} from '../../models/user';
import {LoginService} from './login.service';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
})
export class NgxLoginComponent implements OnInit {

  showMessages: any = {};

  errors: string[] = [];
  messages: string[] = [];
  submitted = false;

  forms = {
    validation: {
      password: {
        required: true,
        minLength: 1
      }
    }
  };

  user = new UserLogin('', '');
  loading = false;


  constructor(private loginService: LoginService, private router: Router) {
  }

  ngOnInit() {
    localStorage.clear();
  }

  login(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.loading = true;

    this.loginService.getCustomers(this.user)
      .then(customers => this.loginSuccess())
      .catch(reason => this.loginError(reason));
  }

  loginError(reason) {

    console.log(reason);

    this.loading = false;
    this.showMessages.error = true;
    this.showMessages.success = false;

    let error_message: string;
    if (reason.status === 401) {
      error_message = 'E-mail or Password is not correct!';
    } else {
      error_message = 'Unexpected error, please try again later!';
    }
    this.errors.push(error_message);
    this.submitted = false;
  }

  loginSuccess() {
    this.showMessages.error = false;
    this.showMessages.success = true;

    this.messages.push('Success! Redirecting to dashboard');

    const _this = this;
    setTimeout(function () {
      _this.router.navigate(['/pages/dashboard']);
    }, 1000);
  }
}
