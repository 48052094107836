import {Injectable} from '@angular/core';
import {NbSidebarService} from '@nebular/theme';

export enum SidebarType {
  Data = 'Data',
  Queue = 'Queue'
}

@Injectable()
export class SidebarService {

  sidebarState = {};

  constructor(protected nbSidebarService: NbSidebarService) {
    for (const item in SidebarType) {
      if (isNaN(Number(item))) {
        this.sidebarState[item] = false;
      }
    }
  }

  expand(tag: string, type: SidebarType) {
    this.sidebarState[type] = true;
    this.nbSidebarService.expand(tag);
  }

  toggle(tag: string, type: SidebarType) {

    // TOGGLE DATA
    if (type === SidebarType.Data) {

      // IF QUEUE IS SHOWING
      if (this.sidebarState[SidebarType.Queue]) {
        this.sidebarState[SidebarType.Queue] = false;
      }

      // IF DATA IS SHOWING
      if (this.sidebarState[SidebarType.Data]) {
        this.sidebarState[SidebarType.Data] = false;
        this.nbSidebarService.collapse(tag);
      } else {
        this.sidebarState[SidebarType.Data] = true;
        this.nbSidebarService.expand(tag);
      }

    }

    // TOGGLE QUEUE
    if (type === SidebarType.Queue) {

      // IF DATA IS SHOWING
      if (this.sidebarState[SidebarType.Data]) {
        this.sidebarState[SidebarType.Data] = false;
      }

      // IF QUEUE IS SHOWING
      if (this.sidebarState[SidebarType.Queue]) {
        this.sidebarState[SidebarType.Queue] = false;
        this.nbSidebarService.collapse(tag);
      } else {
        this.sidebarState[SidebarType.Queue] = true;
        this.nbSidebarService.expand(tag);
      }

    }
  }
}
