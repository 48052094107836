import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as filesaver from 'file-saver';


@Component({
  selector: 'ngx-export-csv-modal',
  templateUrl: './export-csv-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ExportCsvModalComponent implements OnInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  csvData = [];
  csvKeyModels = [];
  selectAll = true;


  constructor(public modalRef: BsModalRef) {
  }

  ngOnInit() {
    this.modalData.title = 'Export CSV';
    if (this.modalData.data) {
      this.csvData = this.modalData.data;
      if (this.csvData.length > 0) {
        this.csvKeyModels = Object.keys(this.csvData[0]).map(elem => {
          return {
            key: elem,
            selected: this.selectAll
          };
        });
      }
    }
  }

  saveChanges() {
    this.result.success = true;
    this.result.payload = null;
    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }


  exportCSV() {
    if (this.modalData.data) {
      const array = this.csvData;

      const selected = this.csvKeyModels.map(elem => {
        if (elem.selected) {
          return elem.key;
        }
      });
      let selectedArray = array.map(elem => this.pick(elem, selected));

      selectedArray = selectedArray.map(elem => {
        const keys = Object.keys(elem);
        keys.forEach(k => {
          elem[k] = String(elem[k]).replace(/(?:\\[rn]|[\r\n]+)+/g, '').replace(/"/g, '\'');
        });
        return elem;
      });
      const result = this.convertToCSV(selectedArray);
      this.downloadFile(result.lines);
    }
  }


  pick(obj, keys) {
    return keys.map(k => k in obj ? {[k]: obj[k]} : {}).reduce((res, o) => Object.assign(res, o), {});
  }


  convertToCSV(objArray) {
    const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    const lines = [];

    for (const index in objArray[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    lines.push(row + '\r\n');

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (const index in array[i]) {
        if (line != '') {
          line += ',';
        }
        line += '"' + array[i][index] + '"';
      }
      lines.push(line + '\r\n');
      str += line + '\r\n';
    }

    return {
      str: str,
      lines: lines
    };
  }


  downloadFile(data) {
    const file = new File(data, 'data-queue.csv', {type: 'text/csv;charset=utf-8'});
    filesaver.saveAs(file);
    this.closeModal();
  }


  selectAllChanged() {
    this.csvKeyModels.forEach(elem => {
      elem.selected = this.selectAll;
    });
  }


}


