import {AfterViewInit, Component, EventEmitter, OnInit, Renderer2, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';


@Component({
  selector: 'ngx-json-modal',
  templateUrl: './json-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class JsonModalComponent implements OnInit, AfterViewInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;

  viewReady = false;
  editorIsValid = true;

  showSaveButton = false;

  constructor(private renderer: Renderer2, public modalRef: BsModalRef) {
    this.editorOptions = new JsonEditorOptions();

    // this.editorOptions.onChange = this.validateEditor;
    const _this = this;
    this.editorOptions.onChange = function () {
      try {
        _this.editor.get();
        _this.editorIsValid = true;
      } catch (err) {
        console.log(err);
        _this.editorIsValid = false;
      }
    };
  }

  ngOnInit() {
    this.initEditorOptions();
    this.renderer.setStyle(this.editor.jsonEditorContainer.nativeElement, 'height', '100%');

    if (this.modalData.showSaveButton) {
      this.showSaveButton = this.modalData.showSaveButton;
    }
  }

  ngAfterViewInit() {
    this.viewReady = true;
  }

  saveChanges() {
    this.result.success = true;
    this.result.payload = this.payLoad;
    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  initEditorOptions() {
    this.editorOptions.mode = 'code';
  }

}
