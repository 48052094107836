import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../_api/api.service';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {GenericData, GenericDataObservable} from '../generic-data';
import {TypeService} from '../../type/type.service';
import {JsonModalComponent} from '../../../_utils/modals/modal/json-modal.component';
import {PagesService} from '../../pages.service';
import {BsModalService} from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-generic-data-table',
  templateUrl: './generic-data-table.component.html',
  styleUrls: ['../generic-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ApiService]
})
export class GenericDataTableComponent implements OnInit, OnChanges {

  listData: Array<any> = [];
  @Input() typeOrigin: string = undefined; // type ID
  @Input() allowReprocess = true;
  @Input() selectionType = 'checkbox';

  @Output() reprocessed?: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleted?: EventEmitter<any> = new EventEmitter<any>();
  @Output() dataExported?: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletedAll?: EventEmitter<any> = new EventEmitter<any>();

  rows: BehaviorSubject<GenericData[]>;
  genericDataObservable: GenericDataObservable;
  next_url: string;
  filterData = '';
  selected = [];
  showDataOptions = ['25', '100', '250', '500', '5000', '10000', 'ALL'];
  showDataQty = '25';
  reorderable = false;
  allTypesLinks = [];
  allKeys = [];
  showAdvancedFilter = false;
  invertFilterResult = false;
  showAdvancedMenu = false;
  stopLoading = false;
  isLoadingBatch = false;
  batchCount = 0;
  batchTotal: number | string = 0;

  @ViewChild('genDataTable') table: any;

  constructor(private _api: ApiService,
              private bsModalService: BsModalService,
              private _typeService: TypeService,
              private _pageService: PagesService) {

    _pageService.stopLoadingEvent.subscribe(() => {
      setTimeout(() => {
        this.stopLoading = true;
      });
    });

  }

  ngOnInit() {
    this.rows = new BehaviorSubject<GenericData[]>([]);

    this._typeService.getAllTypesLinks().then(response => {
      this.allTypesLinks = response;
      const temp = this.allTypesLinks.map(elem => elem.links.map(elB => elB.type));
      this.allKeys = temp.reduce((acc, cur) => acc.concat(cur), []).sort().filter(function (item, pos, ary) {
        return !pos || item != ary[pos - 1];
      });
    });
  }

  ngOnChanges(changes) {
    if (changes.typeOrigin) {
      this.reloadData();
    }
  }

  reloadData() {
    this.rows = new BehaviorSubject<GenericData[]>([]);
    this.next_url = '';
    this.listData = [];
    this.selected = [];

    this.genericDataObservable = new GenericDataObservable();
    this.genericDataObservable.dataChange.subscribe(
      value => {
        this.listData = value;
        this.updateDataRows(value);

        if (this.filterData) {
          this.filterItems(this.filterData);
        }
      },
      error => console.log('ERR', error),
      () => console.log('FINISHED')
    );

    this.loadMore();
  }

  setLoading() {
    this._pageService.setLoading(true, true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }

  filterItems(value) {
    const filts = value.split('|');
    const arr = Object.assign([], this.listData);

    const filteredArray = arr.filter(el => {
      const aux = JSON.stringify(el);
      if (this.invertFilterResult) {
        return !filts.some(ele => {
          return aux.toLowerCase().indexOf(ele.trim().toLowerCase()) > -1;
        });
      } else {
        return filts.some(ele => {
          return aux.toLowerCase().indexOf(ele.trim().toLowerCase()) > -1;
        });
      }
    });
    this.updateDataRows(filteredArray);
  }

  updateDataRows(values) {
    // sort the data by date using moment.js
    const sorted = values.sort(function (left, right) {
      return moment.utc(right.updated_at).diff(moment.utc(left.updated_at));
    });
    this.rows = new BehaviorSubject<GenericData[]>(sorted);
  }

  loadMore() {
    if (this.typeOrigin) {

      this.setLoading();

      if (this.showDataQty !== 'ALL' && Number(this.showDataQty) <= 500) {

        let urlObj = {};
        if (this.next_url !== '') {
          const search = this.next_url.replace('/api/v1/genericdata/' + this.typeOrigin, '');
          urlObj = this.queryStringToJSON(search);
        }
        urlObj['fetch_qty'] = this.showDataQty;

        const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
          return [k, urlObj[k]].join('=');
        }).join('&');

        const getUrl = 'genericdata/' + this.typeOrigin + '?' + urlParams;

        this._api.get(getUrl).then(response => {
          this.next_url = response.next_url;
          this.genericDataObservable.addData(response.results);
          this.loaded();
        });
      } else {
        this.innerLoadMore();
      }
    }
  }

  async innerLoadMore() {
    const loops = this.showDataQty == 'ALL' ? 2 : Number(this.showDataQty) / 100;
    this.isLoadingBatch = true;
    this.batchCount = 1;
    this.batchTotal = this.showDataQty == 'ALL' ? 'ALL' : loops;

    let tempData = [];

    for (let i = 0; i < loops; i++) {

      if (this.stopLoading) {
        this.stopLoading = false;
        break;
      }

      let urlObj = {};
      if (this.next_url !== '') {
        const search = this.next_url.replace('/api/v1/genericdata/' + this.typeOrigin, '');
        urlObj = this.queryStringToJSON(search);
      }
      urlObj['fetch_qty'] = '100';

      const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
        return [k, urlObj[k]].join('=');
      }).join('&');

      const getUrl = 'genericdata/' + this.typeOrigin + '?' + urlParams;

      const res = await this._api.get(getUrl);
      this.next_url = res.next_url;

      tempData = [...tempData, ...res.results];
      this.batchCount++;

      if (this.showDataQty == 'ALL') {
        i = 0;
      }

      if (!this.next_url) {
        break;
      }
    }

    this.genericDataObservable.addData(tempData);
    this.isLoadingBatch = false;
    this.loaded();
  }


  formatDate(date) {
    const mdate = moment(date);
    return mdate.format('DD/MM/YYYY HH:mm:ss');
  }

  compareDates(a, b) {
    return moment.utc(a).diff(moment.utc(b));
  }

  queryStringToJSON(url) {
    const pairs = url.split('&');
    const result = {};
    pairs.forEach(function (pair) {
      let a = pair.substring(0, pair.indexOf('='));
      const b = pair.substring(pair.indexOf('=') + 1);
      a = a.replace('?', '');
      result[a] = decodeURIComponent(b || '');
    });
    return JSON.parse(JSON.stringify(result));
  }


  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  unselect(dataId) {
    this.selected = this.selected.filter(item => {
      return item.id !== dataId;
    });
  }

  removeDeleted(dataId) {
    this.unselect(dataId);
    const delRows = this.rows.value.filter(elem => elem.id === dataId);
    const newRows = this.rows.value.filter(elem => elem.id !== dataId);

    this.rows = new BehaviorSubject<GenericData[]>(newRows);
    this.genericDataObservable.removeData(delRows);
  }

  getSelected() {
    return this.selected;
  }

  openJsonModal(title, id) {

    const data = this.rows.getValue().find(elem => elem.id === id);
    if (data) {
      const formData = {title: title, data: data.structure};

      const initialState = {modalData: formData};
      const config = {
        class: 'modal-lg',
        initialState: initialState,
        backdrop: true,
        ignoreBackdropClick: true
      };

      const activeModal = this.bsModalService.show(JsonModalComponent, config);
      activeModal.content.modalResult.subscribe(res => {
        if (res && res.success) {
          const payload = res.payload;
        }
      });
    }

  }

  reprocessSelected() {
    this.reprocessed.emit(this.selected);
  }

  deleteSelected() {
    this.deleted.emit(this.selected);
  }


  getKey(link) {
    const aux = link.split('|');
    if (aux.length === 2) {
      return aux[0];
    } else {
      return null;
    }
  }

  getLinkInfo(link) {
    const aux = link.split('|');
    if (aux.length === 2) {
      const key = aux[0];
      return this.getKeyPresentAt(key);
    } else {
      return null;
    }
  }

  getLabel(id) {
    return this._typeService.getSystemTypeLabel(id);
  }

  getKeyPresentAt(key) {
    return this.allTypesLinks.filter(elem => elem.links.some(el => el.type === key));
  }

  getLinkAt(links, key) {
    return links.filter(elem => elem.type === key);
  }

  exportData() {
    const data = this.selected.length > 0 ? this.selected : this.rows.value;
    this.dataExported.emit(data);
  }

  deleteAllData() {
    this.deletedAll.emit();
    this.showAdvancedMenu = false;
  }

}
