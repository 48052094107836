import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {QuestionBase} from './question-base';
import {QuestionControlService} from './question-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService]
})
export class DynamicFormComponent {

  @Input() questions: QuestionBase<any>[] = [];
  @Input() form: FormGroup;

  constructor(private qcs: QuestionControlService) {
  }

}
