import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/debounceTime';

import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class ApiService {

  private baseUrl = environment.baseUrl;  // URL to web api
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private _route: ActivatedRoute) {

  }

  get(url, search = null, headers: Array<{ name: string, value: string }> = null): Promise<any> {
    if (headers) {
      headers.forEach(elem => {
        this.headers = this.headers.append(elem.name, elem.value);
      });
    }

    const options = {headers: this.headers};
    const urlParamsArray = [];
    let urlParams = '';
    if (search) {
      // options['search'] = search;
      for (const key in search) {
        if (search.hasOwnProperty(key)) {
          urlParamsArray.push(key + '=' + search[key]);
        }
      }
      urlParams = urlParamsArray.join('&');
    }

    return this.http.get(this.baseUrl + url + (urlParams.length > 0 ? '?' + urlParams : ''), options)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(err => {
        return this.handleError(err);
      });
  }

  post(url, data) {
    return this.http.post(this.baseUrl + url, data, {headers: this.headers, observe: 'response'})
      .toPromise()
      .then(response => {
        return response.body['results'];
      })
      .catch(err => {
        return this.handleError(err);
      });
  }

  put(url, data, headers: Array<{ name: string, value: string }> = null) {
    if (headers) {
      headers.forEach(elem => {
        this.headers = this.headers.append(elem.name, elem.value);
      });
    }

    return this.http.put(this.baseUrl + url, data, {headers: this.headers})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(err => {
        return this.handleError(err);
      });
  }

  api_delete(url) {
    return this.http.delete(this.baseUrl + url, {headers: this.headers})
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(err => {
        return this.handleError(err);
      });
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
