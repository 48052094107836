import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.css']
})
export class SystemSettingsComponent implements OnInit {

  color = '#FFFFFF';

  @Input() data: { color: string };
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    this.color = this.data.color;
  }

  colorChanged($event) {
    // $event = HEX color
    this.dataChange.emit($event);
  }

}
