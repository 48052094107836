import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {System} from '../system/system';
import {ApiService} from '../../_api/api.service';
import {ActivatedRoute} from '@angular/router';
import {ModalsService} from '../../_utils/modals/modals.service';
import {BodyOutputType, Toast, ToasterConfig, ToasterService} from 'angular2-toaster';
import * as moment from 'moment';
import {SidebarService, SidebarType} from '../sidebar.service';
import {PagesService} from '../pages.service';
import * as async from 'async';
import {ProgressbarConfig} from 'ngx-bootstrap/progressbar';
import {Type} from '../type/type';
import {GenericDataTableComponent} from './table/generic-data-table.component';
import * as filesaver from 'file-saver';


@Component({
  selector: 'app-generic-data',
  templateUrl: './generic-data.component.html',
  styleUrls: ['./generic-data.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ApiService, ProgressbarConfig]
})
export class GenericDataComponent implements OnInit {

  loading = true;

  systems: Array<System>;
  type_origin: Type = undefined;
  typeOriginId: string = null;
  type_origin_param: string;
  types_from_display: Array<any> = [];
  config: ToasterConfig;
  isReprocessing = false;
  progressValue = 0;

  isDeleting = false;

  @ViewChild('genTable') genericDataTable: GenericDataTableComponent;

  constructor(private _api: ApiService,
              private _route: ActivatedRoute,
              private _modals: ModalsService,
              private toasterService: ToasterService,
              private customSidebarService: SidebarService,
              private _pageService: PagesService) {
  }


  ngOnInit() {

    this.setLoading();

    // call change type function
    this.changeType();

    this.loading = true;

    // get SYSTEMS from API
    this.loadSystems();

    this._route.params.subscribe(params => {
      this.type_origin_param = params['from'];
      if (this.type_origin_param) {
        this.customSidebarService.expand('settings-sidebar', SidebarType.Data);
        this.reloadSelectedTypes();
      }
    });
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }


  loadSystems() {
    return new Promise((resolve, reject) => {
      // get SYSTEMS from API
      this._api.get('system').then(response => {
        this.systems = response.results;
        this.loading = false;
        this.types_from_display = [];

        this.systems.forEach(s => {
          s.types.forEach(t => {
            const obj = {
              type: t,
              label: (t ? `${this.getSystemFromId(t.system_id).alias} > ${t.description}` : '[TYPE NOT FOUND]')
            };
            this.types_from_display.push(obj);
          });
        });
        this.types_from_display.sort((a, b) => a.label.toUpperCase() >= b.label.toUpperCase() ? 1 : -1);

        this.loaded();
        resolve();
      });
    });
  }

  reloadSelectedTypes() {
    this.setLoading();
    this.loadSystems().then(response => {
      if (this.type_origin_param) {
        this.type_origin = this.getTypeFromId(this.type_origin_param);
        this.typeOriginId = this.type_origin.id;
        this.changeType();
      }
    });
  }


  getSystemFromId(id): System {
    return this.systems.filter(s => s.id === id)[0];
  }

  getTypeFromId(id): Type {
    return this.systems.map(s => {
      return s.types.filter(t => t.id === id)[0];
    }).filter(t => t !== undefined)[0];
  }


  reloadData() {
    if (this.genericDataTable) {
      this.genericDataTable.reloadData();
    }
  }


  changeType() {
    if (this.type_origin) {
      this.typeOriginId = this.type_origin.id;
    }
  }


  reprocessSelected(event) {

    const selected = event;

    const _th = this;
    const genTable = this.genericDataTable;

    const bucketSize = 20;

    this.isReprocessing = true;
    this.progressValue = 0;

    let countSuccess = 0;
    let countError = 0;
    const totals = selected.length;

    // create a queue object with concurrency "bucketSize"
    const q = async.queue(function (item, callback) {
      _th._api.post('genericdata/reprocess', {id: item.id}).then(() => {
        genTable.unselect(item.id);
        countSuccess++;
        callback(null, true);
      }).catch(error => {
        console.log(error);
        countError++;
        callback(error);
      });
    }, bucketSize);

    // assign a callback
    q.drain = function () {
      console.log('all items have been processed');
      _th.isReprocessing = false;

      if (countSuccess > 0) {
        _th.showToast('info', 'Notification', countSuccess + (countSuccess == 1 ? ' task' : ' tasks') + ' added to queue');
      }

      if (countError > 0) {
        _th.showToast('error', 'Error', countError + (countError == 1 ? ' error' : ' errors') + ' occured!');
      }

    };

    // add some items to the queue (batch-wise)
    q.push(selected, function (err) {
      const aux = Math.round(100 * (countSuccess + countError) / totals);
      _th.progressValue = (aux <= 0 ? 0 : (aux >= 100 ? 100 : aux));
    });
  }


  deleteSelected(event) {
    const _th = this;
    const selected = event;
    const genTable = this.genericDataTable;

    this._modals.showConfirmModal('Delete Generic Data', 'Are you sure you want to delete the selected Generic Data?').then(cRes => {
      if (cRes && cRes.success) {
        const bucketSize = 20;

        this.isDeleting = true;
        this.progressValue = 0;

        let countSuccess = 0;
        let countError = 0;
        const totals = selected.length;

        // create a queue object with concurrency "bucketSize"
        const q = async.queue(function (item, callback) {
          _th._api.api_delete('genericdata/get_by_id/' + item.id).then(() => {
            genTable.removeDeleted(item.id);
            countSuccess++;
            callback(null, true);
          }).catch(error => {
            console.log(error);
            countError++;
            callback(error);
          });
        }, bucketSize);

        // assign a callback
        q.drain = function () {
          console.log('finished deleting');
          _th.isDeleting = false;

          if (countSuccess > 0) {
            _th.showToast('info', 'Notification', countSuccess + (countSuccess == 1 ? ' item' : ' items') + ' deleted!');
          }

          if (countError > 0) {
            _th.showToast('error', 'Error', countError + (countError == 1 ? ' error' : ' errors') + ' occured!');
          }

        };

        // add some items to the queue (batch-wise)
        q.push(selected, function (err) {
          const aux = Math.round(100 * (countSuccess + countError) / totals);
          _th.progressValue = (aux <= 0 ? 0 : (aux >= 100 ? 100 : aux));
        });

      }
      this.isDeleting = false;
    });
  }


  runTypeAction(action) {
    const type_id = this.type_origin.id;
    if (type_id) {
      this._modals.showConfirmModal('Execute ' + (action === 'run' ? 'Task' : 'Commit') + '?', '').then(cRes => {
        if (cRes && cRes.success) {
          this.runNow(type_id, action);
        }
      });
    }
  }

  runNow(type_id, action) {
    this.setLoading();
    this._api.put('type/' + type_id + '/' + action, {}).then(() => {
      this.showToast('info', 'Notification', action.toUpperCase() + ' added to queue');
      this.loaded();
    }).catch(res => {
      this.showToast('error', 'Error', action.toUpperCase() + ' ERROR (' + res._body + ')');
      this.loaded();
    });
  }

  private showToast(type: string, title: string, body: string) {
    this.config = new ToasterConfig({
      positionClass: 'toast-top-right',
      timeout: 2000,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: false,
      animation: 'fade',
      limit: 5,
    });
    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.popAsync(toast);
  }


  exportData(event) {
    this.downloadFile(event);
  }

  downloadFile(data) {
    const now = moment().format('DD-MM-YYYY-HH-mm-ss');
    const json = JSON.stringify(data);
    const file = new File([json], 'generic-data-' + now + '.txt', {type: 'data:application/json;charset=utf-8'});
    filesaver.saveAs(file);
  }

  deleteAllData() {
    const _th = this;
    const genTable = this.genericDataTable;

    this._modals.showConfirmModal('Delete Generic Data', 'Are you sure you want to delete ALL Generic Data?').then(cRes => {
      if (cRes && cRes.success) {
        this.isDeleting = true;

        if (this.type_origin && this.type_origin.id) {
          const tOrigin = this.type_origin.id;

          _th._api.put('genericdata/delete', {type_id: tOrigin}).then(() => {
            _th.showToast('info', 'Notification', 'All Generic Data will be deleted in the background!');
            this.isDeleting = false;
          }).catch(error => {
            console.log(error);
            _th.showToast('error', 'Error', 'Error deleting Generic Data!');
            this.isDeleting = false;
          });
        }
      }
      this.isDeleting = false;
    });
  }

}
