import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SystemCollection} from '../../system/system';
import {TypeCollection} from '../../type/type';
import {ApiService} from '../../../_api/api.service';
import * as moment from 'moment';

enum AlertType {
  Info = 'info',
  Success = 'success',
  Danger = 'danger'
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css']
})
export class WizardComponent implements OnInit {

  @Input()
  set user(value: any) {
    this._user = value;
  }

  @Input()
  set wizardStructure(value: any) {
    this._wizardStructure = value;
    this.loadStructure();
  }

  @Output() isLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _user: any;
  private _wizardStructure: any;
  wizardGroups = [];

  allSystems: SystemCollection;
  allTypes: TypeCollection;

  // WIZARD STEPS
  currentStep = 0;
  maxSteps: number;

  loading = false;
  alertType: string;
  alertMsg: string;


  constructor(private _api: ApiService) {
  }

  ngOnInit() {
  }


  setLoading(status, type = 'info', msg = null) {
    this.loading = status;
    this.alertType = type;
    this.alertMsg = msg;
    this.isLoading.emit(status);
  }


  loadStructure() {
    if (this._wizardStructure) {

      this._api.get('system').then(response => {
        const systems = response.results;

        this.allSystems = new SystemCollection();
        systems.map(el => this.allSystems[el.id] = el);

        const aux = systems.reduce(function (a, b) {
          return a.concat(b.types);
        }, []);
        this.allTypes = new TypeCollection();
        aux.map(el => this.allTypes[el.id] = el);

        this.createWizardGroups(this._wizardStructure);
      }).catch(error => {
        console.log('ERROR', error);
      });

    }
  }


  createWizardGroups(struct) {
    const groups = [];
    const keys = Object.keys(struct);
    keys.forEach(elem => {

      // only checking for systems at the moment
      if (elem === 'systems') {
        struct[elem].forEach(el => {

          const obj = {
            groupId: el.id,
            groupLabel: this.allSystems[el.id].alias,
            groupType: 'system',
            schemas: []
          };

          obj.schemas = this.createGroupSchemas(el);

          groups.push(obj);
        });
      }
    });

    this.wizardGroups = groups;
    this.maxSteps = this.wizardGroups.length - 1;
  }


  createGroupSchemas(schema) {
    return this.recursiveKeys(schema, []);
  }


  recursiveKeys(arr, returnArray, nav = '') {
    const keys = Object.keys(arr);

    keys.forEach(elem => {
      if (elem !== 'id') {

        if (arr[elem].hasOwnProperty('_schema')) {

          const obj = {
            key: elem,
            schema: arr[elem]._schema,
            navigation: nav.split('|')
          };

          if (!arr[elem]._schema.value) {
            arr[elem]._schema.value = arr[elem]._schema.default;
          }

          returnArray.push(obj);
        } else {
          const aux = nav !== '' ? (nav + '|' + elem) : (elem);
          this.recursiveKeys(arr[elem], returnArray, aux);
        }
      }
    });

    return returnArray;
  }


  stepClicked(step) {
    this.currentStep = step;
    this.goToTop();
  }

  prevStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.goToTop();
    }
  }

  nextStep() {
    if (this.currentStep < this.maxSteps) {
      this.currentStep++;
      this.goToTop();
    }
  }

  finishWizard() {
    this.saveWizardStructure();
  }


  getNested(theObject, path, separator) {
    try {
      separator = separator || '.';
      return path.replace('[', separator).replace(']', '').split(separator).reduce(function (obj, property) {
        return obj[property];
      }, theObject);
    } catch (err) {
      return undefined;
    }
  }


  saveWizardStructure() {
    if (this._user && this._wizardStructure && this.wizardGroups) {

      this.setLoading(true, AlertType.Info, 'Updating Customer');

      this._wizardStructure['lastRunDate'] = moment();

      const payload = {
        wizard_structure: this._wizardStructure
      };

      this._api.put('customer/' + this._user.id, payload).then(() => {

        this.wizardGroups.filter(elem => elem.groupType === 'system').forEach(elem => {

          this.setLoading(true, AlertType.Info, 'Updating System: ' + elem.groupLabel);

          const sysId = elem.groupId;

          // get system by id
          this._api.get('system/' + sysId).then(system => {
            elem.schemas.forEach(sch => {
              const key = sch.key;
              const sysNav = this.getNested(system, sch.navigation.join('.'), '.');
              sysNav[key] = sch.schema.value;
            });

            // update system
            this._api.put('system/' + sysId, system).then(() => {
              this.setLoading(false, AlertType.Success, 'Finished Updating');
            }).catch(error => {
              console.error(error);
              this.setLoading(false, AlertType.Danger, 'Error: ' + error);
            });

          }).catch(error => {
            console.error(error);
            this.setLoading(false, AlertType.Danger, 'Error: ' + error);
          });

        });

      }).catch(error => {
        console.error(error);
        this.setLoading(false, AlertType.Danger, 'Error: ' + error);
      });
    }
  }


  goToTop() {
    let top = document.getElementById('top');
    if (top != null) {
      top.scrollIntoView(false);
      top = null;
    }
  }

}
