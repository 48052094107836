import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {LoginService} from '../../auth/components/login/login.service';
import {ModalsService} from '../../_utils/modals/modals.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HiddenQuestion, QuestionBase, TextboxQuestion} from '../../_utils/forms';
import {PagesService} from '../pages.service';

export enum KEY_CODE {
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
  ENTER = 13
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [ApiService]
})
export class DashboardComponent implements OnInit {

  @ViewChild('loginaslink') loginAsLink: ElementRef;

  isAdmin = false;
  loggedAsUser = null;

  usersList = [];
  filteredUsersList = [];
  showInactive = false;
  selectedUser = null;
  filterList = '';

  userListSize = 0;
  highlightIndex = 0;


  constructor(private _api: ApiService,
              private _login: LoginService,
              private _modals: ModalsService,
              private _pageService: PagesService,
              private _router: Router,
              private _route: ActivatedRoute) {
  }

  ngOnInit() {
    this.setLoading();
    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();

    const showInactiveLS = localStorage.getItem('showInactiveUsers');
    if (showInactiveLS) {
      const obj = JSON.parse(showInactiveLS);
      this.showInactive = obj.value;
    }

    this.loadData();
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.UP_ARROW) {
      if (this.highlightIndex > 0) {
        this.highlightIndex--;
      }
    }

    if (event.keyCode === KEY_CODE.DOWN_ARROW) {
      if (this.highlightIndex < this.userListSize) {
        this.highlightIndex++;
      }
    }

    if (event.keyCode === KEY_CODE.ENTER) {
      if (this.selectedUser) {
        this.loginAsLink.nativeElement.click();
      }
    }

    this.selectUser(this.filteredUsersList[this.highlightIndex]);
  }


  loadData() {
    this.setLoading();
    if (this.isAdmin && !this.loggedAsUser) {
      this.loadUsersList();
    } else {
      this.loaded();
    }
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }

  loadUsersList() {
    return this._api.get('customer', {}).then(response => {
      if (response && response.results) {
        this.usersList = response.results;
        this.usersList.sort((a, b) => a.name.toUpperCase() >= b.name.toUpperCase() ? 1 : -1);


        this.usersList.forEach(usr => {
          usr['_loading'] = true;
          const user = usr;

          usr['_systems'] = [];
          if (user.settings && user.settings.config && user.settings.config.systems) {
            usr['_systems'] = Object.keys(user.settings.config.systems).map(key => {
              return user.settings.config.systems[key].label;
            }).sort((a, b) => a.toUpperCase() >= b.toUpperCase() ? 1 : -1);
          }
          usr['_loading'] = false;
        });


        this.assignCopy();
        localStorage.setItem('usersList', JSON.stringify(this.usersList));
      }

      const userFilterInputLS = localStorage.getItem('userFilterInput');
      if (userFilterInputLS) {
        const obj = JSON.parse(userFilterInputLS);
        this.filterList = obj.value;
        this.filterItem(this.filterList);
      }

      this.loaded();
    }).catch(() => {
      this.loaded();
    });
  }

  onRefresh() {
    this.loadData();
  }

  assignCopy() {
    if (!this.showInactive) {
      this.filteredUsersList = Object.assign([], this.usersList).filter(item => item.is_active);
    } else {
      this.filteredUsersList = Object.assign([], this.usersList);
    }

    this.highlightIndex = 0;
    this.userListSize = this.filteredUsersList.length - 1;

    this.selectUser(this.filteredUsersList[this.highlightIndex]);
  }

  filterItem(value) {
    if (!value) {
      this.assignCopy();
    }

    const obj = {value: value};
    localStorage.setItem('userFilterInput', JSON.stringify(obj));

    this.filteredUsersList = Object.assign([], this.usersList).filter(item => {
      if (!item._systems) {
        item._systems = [];
      }
      return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.email.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item._systems.join(' ').toLowerCase().indexOf(value.toLowerCase()) > -1;
    }).filter(item => {
      if (!this.showInactive) {
        return item.is_active;
      } else {
        return item;
      }
    });

    this.highlightIndex = 0;
    this.userListSize = this.filteredUsersList.length - 1;

    this.selectUser(this.filteredUsersList[this.highlightIndex]);
  }

  selectUser(user: any) {
    this.selectedUser = user;
  }


  addNewUser() {
    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        value: '',
        required: true,
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: '',
        required: true,
        order: 2
      }),
      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        type: 'password',
        value: '',
        required: true,
        order: 3
      })
    ];

    const formData = {
      title: 'Add new User',
      questions: questions
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        this.setLoading();
        const payload = res.payload;
        this._api.post('customer', payload).then(() => this.loadUsersList());
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }

  editUser(user: any) {
    const id = user.id;

    const editUser = {
      email: '',
      name: '',
      password: null
    };

    if (user.email) {
      editUser.email = user.email;
    }
    if (user.name) {
      editUser.name = user.name;
    }

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        value: editUser.email,
        required: false,
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: editUser.name,
        required: false,
        order: 2
      }),
      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        type: 'password',
        value: null,
        required: false,
        order: 3
      })
    ];

    const formData = {
      title: 'Edit User',
      questions: questions
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        this.setLoading();
        const payload = res.payload;
        if (!payload.password || payload.password === '') {
          delete payload['password'];
        }
        this._api.put('customer/' + id, payload).then(() => this.loadUsersList());
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }

  duplicateUser(user: any) {
    const editUser = {
      email: '',
      name: '',
      password: null
    };

    if (user.email) {
      editUser.email = user.email;
    }
    if (user.name) {
      editUser.name = user.name;
    }

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        value: editUser.email,
        required: false,
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: editUser.name,
        required: false,
        order: 2
      })
    ];

    const formData = {
      title: 'Copy User',
      questions: questions
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        const payload = res.payload;

        this._modals.showConfirmModal('Copy Customer?', '').then(cRes => {
          if (cRes && cRes.success) {
            this.setLoading();
            if (payload.email) {
              editUser.email = payload.email;
            }
            if (payload.name) {
              editUser.name = payload.name;
            }

            this._api.put('customer/' + user.id + '/copy', editUser).then(() => this.loadUsersList());
          }
        });
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }

  toggleActivate(user: any, status: string) {
    this.setLoading();
    this._api.put('customer/' + user.id + '/' + status, {}).then(() => this.loadUsersList());
  }

  runNSMigration(user: any) {

    const editUser = {
      email: '',
      name: '',
      password: null,
      data_limit: null,
      queue_number_of_weeks: null,
      queue_from_types: null,
    };

    if (user.email) {
      editUser.email = user.email;
    }
    if (user.name) {
      editUser.name = user.name;
    }

    if (user.data_limit) {
      editUser.data_limit = user.data_limit;
    }
    if (user.queue_number_of_weeks) {
      editUser.queue_number_of_weeks = user.queue_number_of_weeks;
    }
    if (user.queue_from_types) {
      editUser.queue_from_types = user.queue_from_types;
    }

    const questions: QuestionBase<any>[] = [
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        value: editUser.email,
        required: false,
        order: 1
      }),
      new TextboxQuestion({
        key: 'name',
        label: 'Name',
        value: editUser.name,
        required: false,
        order: 2
      }),
      new HiddenQuestion({key: 'data_limit', value: editUser.data_limit}),
      new HiddenQuestion({key: 'queue_number_of_weeks', value: editUser.queue_number_of_weeks}),
      new HiddenQuestion({key: 'queue_from_types', value: editUser.queue_from_types}),
    ];

    const formData = {
      title: 'Edit data for namespace migration',
      questions: questions
    };

    this._modals.showFormModal(formData).then(res => {
      if (res && res.success) {
        const payload = res.payload;

        this._modals.showConfirmModal('Run Namespace Migration?', '').then(cRes => {
          if (cRes && cRes.success) {
            this.setLoading();
            if (payload.email) {
              editUser.email = payload.email;
            }
            if (payload.name) {
              editUser.name = payload.name;
            }

            if (payload.data_limit) {
              editUser.data_limit = payload.data_limit;
            } else {
              delete editUser['data_limit'];
            }
            if (payload.queue_number_of_weeks) {
              editUser.queue_number_of_weeks = payload.queue_number_of_weeks;
            } else {
              delete editUser['queue_number_of_weeks'];
            }
            if (payload.queue_from_types) {
              editUser.queue_from_types = payload.queue_from_types;
            } else {
              delete editUser['queue_from_types'];
            }

            this._api.put('customer/' + user.id + '/namespaces_migration', editUser).then(() => this.loadUsersList()).catch(error => {
              console.log(error);
              this.loaded();
            });
          }
        });
      }
    }).catch(err => {
      console.log('ERR', err);
    });
  }

  showInactiveChanged() {
    const obj = {value: this.showInactive};
    localStorage.setItem('showInactiveUsers', JSON.stringify(obj));
    this.assignCopy();
  }


  runWizard(user) {
    this._api.get('customer/' + user.id).then(resp => {
      const modalData = {title: 'Template Wizard', data: resp};
      this._modals.showWizardModal(modalData).subscribe(response => {
        this.loadUsersList();
      });
    });
  }

}
