import {Injectable} from '@angular/core';


export class ComplexTypeSnippet {

  public type: string;
  public args: any;
  public schemas: ComplexSchemaCollection;

  constructor(type: string, args: any, schemas: ComplexSchemaCollection) {
    this.type = type;
    this.args = args;
    this.schemas = schemas;
  }

}


export class ComplexSchema {
  public type: string;
  public helper: string;
  public default: any;
  public options: Array<string>;
  public optional?: { opt: boolean, model: boolean } = {opt: false, model: false};
  public schema?: ComplexSchemaCollection;

  public allowEmpty?: boolean;
}

export class ComplexSchemaCollection {
  [key: string]: ComplexSchema;
}


export enum ComplexSchemaType {
  String = 'string',
  ArrayPG = 'array_pg',
  PathGroup = 'path_group',
  Any = 'any',
  Array = 'array',
  Integer = 'integer',
  Boolean = 'boolean',
  TextArea = 'textarea',
  Structure = 'structure',
  Schema = 'schema'
}


export class ComplexTypeSnippetList {

  private complex_types = [

    // without Args
    {type: 'base64encode', args: null, schema: null},
    {type: 'decimal', args: null, schema: null},
    {type: 'from_json', args: null, schema: null},
    {type: 'hide_null', args: null, schema: null},
    {type: 'integer', args: null, schema: null},
    {type: 'lowercase', args: null, schema: null},
    {type: 'strip', args: null, schema: null},
    {type: 'uppercase', args: null, schema: null},
    {type: 'url_decode', args: null, schema: null},
    {type: 'create_uuid', args: null, schema: null},

    // with args
    {
      type: 'concatenate',
      args: {multiple_paths: []},
      schema: {multiple_paths: {type: ComplexSchemaType.ArrayPG, helper: 'multiple_paths', default: null, options: null}}
    },    // Array de PATH GROUP
    {
      type: 'convert_date',
      args: {format_input: '%Y-%m-%dT%H:%M:%S.%Z', format_output: '%d/%m/%Y'},
      schema: {
        format_input: {type: ComplexSchemaType.String, helper: 'format_input', default: '%Y-%m-%dT%H:%M:%S.%Z', options: []},
        format_output: {type: ComplexSchemaType.String, helper: 'format_output', default: '%d/%m/%Y', options: []}
      }
    },
    {
      type: 'create_list',
      args: {multiple_paths: [[], []]},
      schema: {multiple_paths: {type: ComplexSchemaType.ArrayPG, helper: 'multiple_paths', default: null, options: null}}
    },   // Array de PATH GROUP
    {
      type: 'current_date',
      args: {timezone: 'Australia/Sydney', format: '%Y-%m-%dT%H:%M:%S.%Z'},
      schema: {
        timezone: {type: ComplexSchemaType.String, helper: 'timezone', default: 'Australia/Sydney', options: []},
        format: {type: ComplexSchemaType.String, helper: 'format', default: '%Y-%m-%dT%H:%M:%S.%Z', options: []}
      }
    },
    {
      type: 'default_value',
      args: {path: []},
      schema: {
        path: {type: ComplexSchemaType.PathGroup, helper: 'path', default: [], options: null}
      }
    },    // PATH GROUP
    {
      type: 'end_of_month',
      args: {path: [], format: '%d/%m/%Y'},
      schema: {
        path: {type: ComplexSchemaType.PathGroup, helper: 'path', default: [], options: null},
        format: {type: ComplexSchemaType.String, helper: 'format', default: '%d/%m/%Y', options: []}
      }
    },
    {
      type: 'hashmap',
      args: {options: {}, default: ''},
      schema: {
        options: {type: ComplexSchemaType.Any, helper: 'options', default: {}, options: null},
        default: {type: ComplexSchemaType.Any, helper: 'default', default: '', options: null, optional: {opt: true, model: true}}
      }
    },
    {
      type: 'find',
      args: {value: ''},
      schema: {
        value: {type: ComplexSchemaType.String, helper: 'value', default: '', options: null}
      }
    },
    {
      type: 'if_else',
      args: {value_1: [], value_2: [], if_true: [], if_false: [], comparison_type: '= or != or > or >='},      // 4x PATH GROUP
      schema: {
        value_1: {type: ComplexSchemaType.PathGroup, helper: 'value_1', default: [], options: null},
        value_2: {type: ComplexSchemaType.PathGroup, helper: 'value_2', default: [], options: null},
        if_true: {
          type: ComplexSchemaType.PathGroup,
          helper: 'if_true',
          default: [],
          options: null,
          optional: {opt: true, model: true},
          allowEmpty: true
        },
        if_false: {
          type: ComplexSchemaType.PathGroup,
          helper: 'if_false',
          default: [],
          options: null,
          optional: {opt: true, model: true},
          allowEmpty: true
        },
        comparison_type: {type: ComplexSchemaType.String, helper: 'comparison_type', default: null, options: ['=', '!=', '>', '>=']}
      }
    },
    {
      type: 'join',
      args: {path: [], separator: ''},
      schema: {
        path: {type: ComplexSchemaType.PathGroup, helper: 'path', default: [], options: null},
        separator: {type: ComplexSchemaType.String, helper: 'separator', default: '', options: []}
      }
    },    // PATH GROUP
    {
      type: 'list_filter_string',
      args: {filters: ['*.jpg']},
      schema: {filters: {type: ComplexSchemaType.Array, helper: 'filters', default: ['*.jpg'], options: null}}
    },
    {
      type: 'list_filter_object',
      args: {filters: ['*.jpg'], path: []},    // PATH GROUP
      schema: {
        filters: {type: ComplexSchemaType.Array, helper: 'filters', default: ['*.jpg'], options: null},
        path: {type: ComplexSchemaType.PathGroup, helper: 'path', default: [], options: null}
      }
    },
    {
      type: 'list_position',
      args: {pos: 0},
      schema: {
        pos: {type: ComplexSchemaType.Integer, helper: 'pos', default: 0, options: null}
      }
    },
    {
      type: 'literal',
      args: {value: true},
      schema: {
        value: {type: ComplexSchemaType.Any, helper: 'value', default: null, options: null}
      }
    },
    {
      type: 'math',
      args: {value_1: [], value_2: [], operation: '+ or - or * or /'},     // 2x PATH GROUP
      schema: {
        value_1: {type: ComplexSchemaType.PathGroup, helper: 'value_1', default: [], options: null},
        value_2: {type: ComplexSchemaType.PathGroup, helper: 'value_2', default: [], options: null},
        operation: {type: ComplexSchemaType.String, helper: 'operation', default: null, options: ['+', '-', '*', '/']}
      }
    },
    {
      type: 'min_max',
      args: {value: 'min/max'},
      schema: {
        value: {type: ComplexSchemaType.String, helper: 'value', default: 'min', options: ['min', 'max']}
      },
    },
    {
      type: 'replace',
      args: {from: '', to: '', path: []},    // PATH GROUP
      schema: {
        from: {type: ComplexSchemaType.String, helper: 'from', default: '', options: []},
        to: {type: ComplexSchemaType.String, helper: 'to', default: '', options: []},
        path: {type: ComplexSchemaType.PathGroup, helper: 'path', default: [], options: null}
      }
    },
    {
      type: 'sort_list',
      args: {
        key: [],
        reverse: false
      },
      schema: {
        key: {type: ComplexSchemaType.PathGroup, helper: 'key', default: [], options: null},
        reverse: {type: ComplexSchemaType.Boolean, helper: 'reverse', default: false, options: null}
      }
    },
    {
      type: 'split',
      args: {separator: ''},
      schema: {
        separator: {type: ComplexSchemaType.String, helper: 'separator', default: '', options: null}
      }
    },
    {
      type: 'string',
      args: {string: ''},
      schema: {
        string: {type: ComplexSchemaType.TextArea, helper: 'string', default: '', options: null, optional: {opt: true, model: true}}
      }
    },
    {
      type: 'substring',
      args: {start: 0, stop: 0},
      schema: {
        start: {type: ComplexSchemaType.Integer, helper: 'start', default: 0, options: null},
        stop: {type: ComplexSchemaType.Integer, helper: 'stop', default: 0, options: null}
      }
    },
    {
      type: 'sum_list',
      args: {path: []},
      schema: {
        path: {type: ComplexSchemaType.PathGroup, helper: 'path', default: [], options: null}
      }
    },
    {
      type: 'text_format',
      args: {format: ''},
      schema: {
        format: {type: ComplexSchemaType.String, helper: 'format', default: '', options: null}
      }
    },
    {
      type: 'to_json',
      args: {indent: 2},
      schema: {
        indent: {type: ComplexSchemaType.Integer, helper: 'indent', default: 2, options: null}
      }
    },
    {
      type: 'transform_list',
      args: {
        structure: [{field_origin: [], field_destination: []}],
        start_index_at: 0,
        append_index_name: ''
      },   // Array de STRUCTURE ITEM = STRUCTURE
      schema: {
        structure: {type: ComplexSchemaType.Structure, helper: 'structure', default: '', options: null},
        start_index_at: {
          type: ComplexSchemaType.Integer,
          helper: 'start_index_at',
          default: null,
          options: null,
          optional: {opt: true, model: true}
        },
        append_index_name: {
          type: ComplexSchemaType.String,
          helper: 'append_index_name',
          default: null,
          options: null,
          optional: {opt: true, model: true}
        }
      }
    },
    {
      type: 'virtual_attribute',
      args: {field_origin: [], field_destination: []},    // STRUCTURE ITEM
      schema: {
        field_origin: {type: ComplexSchemaType.PathGroup, helper: 'field_origin', default: [], options: null},
        field_destination: {type: ComplexSchemaType.PathGroup, helper: 'field_destination', default: [], options: null}
      }
    },
    {
      type: 'timedelta',
      args: {
        format: '',
        delta: {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          weeks: 0,
          microseconds: 0,
          milliseconds: 0
        },
        path: [],
        dynamic_delta: []
      },
      schema: {
        format: {type: ComplexSchemaType.String, helper: 'format', default: '', options: null},
        delta: {
          type: ComplexSchemaType.Schema, helper: 'delta', default: null, options: null, schema: {
            days: {type: ComplexSchemaType.Integer, helper: 'days', default: 0, options: null},
            hours: {type: ComplexSchemaType.Integer, helper: 'hours', default: 0, options: null},
            minutes: {type: ComplexSchemaType.Integer, helper: 'minutes', default: 0, options: null},
            seconds: {type: ComplexSchemaType.Integer, helper: 'seconds', default: 0, options: null},
            weeks: {type: ComplexSchemaType.Integer, helper: 'weeks', default: 0, options: null},
            microseconds: {type: ComplexSchemaType.Integer, helper: 'microseconds', default: 0, options: null},
            milliseconds: {type: ComplexSchemaType.Integer, helper: 'milliseconds', default: 0, options: null}
          }
        },
        path: {type: ComplexSchemaType.PathGroup, helper: 'path', default: [], options: null},
        dynamic_delta: {type: ComplexSchemaType.PathGroup, helper: 'dynamic_delta', default: [], options: null}
      }
    },
    {
      type: 'hashing_object',
      args: {
        key: '',
        algorithm: 'md5,sha1,sha224,sha256,sha384,sha512'
      },
      schema: {
        key: {type: ComplexSchemaType.String, helper: 'key', default: '', options: null},
        algorithm: {
          type: ComplexSchemaType.String,
          helper: 'algorithm',
          default: 'md5',
          options: ['md5', 'sha1', 'sha224', 'sha256', 'sha384', 'sha512']
        }
      }
    }
  ];


  public snippetList: Array<ComplexTypeSnippet>;

  constructor() {

    this.snippetList = new Array<ComplexTypeSnippet>();

    for (let i = 0; i < this.complex_types.length; i++) {

      const type = this.complex_types[i].type;
      const args = this.complex_types[i].args;
      const schema = this.complex_types[i].schema;

      const ct = new ComplexTypeSnippet(type, args, schema);
      this.snippetList.push(ct);
    }

  }

}

@Injectable()
export class ComplexTypeService {

  private ctsList: Array<ComplexTypeSnippet>;

  constructor() {
    const cts = new ComplexTypeSnippetList();
    this.ctsList = cts.snippetList;
  }

  getComplexTypeSnippetList() {
    return this.ctsList;
  }

}
