import {AfterViewInit, Component, EventEmitter, OnInit, Renderer2, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';
import {PagesService} from '../../../pages/pages.service';
import {
  ConnectionAuthenticationCustom,
  ConnectionAuthenticationOauth1,
  ConnectionAuthenticationOauth2,
  ConnectionAuthenticationSession,
  ConnectionAuthenticationType,
  ConnectionDetailSchema,
  ConnectionDetailsFtp,
  ConnectionDetailsLocalFile,
  ConnectionDetailsLocalRest,
  ConnectionDetailsMssql,
  ConnectionDetailsOdbc,
  ConnectionDetailsRestCustom,
  ConnectionDetailsRestNoAuth,
  ConnectionDetailsRestOauth1,
  ConnectionDetailsRestOauth2,
  ConnectionDetailsRestSession,
  ConnectionDetailsSoap,
  ConnectionDetailStructure,
  ConnectionDetailsType,
} from '../../../pages/system/system';

enum EditTabs {
  Structure = 'Structure',
  Advanced = 'Advanced',
}

@Component({
  selector: 'ngx-edit-system-modal',
  templateUrl: './edit-system-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class EditSystemModalComponent implements OnInit, AfterViewInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  thirdParties: any;
  system: any;

  @ViewChild('editor') editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;

  viewReady = false;
  editorIsValid = true;

  connectionDetails: any = null;
  systemSettings: { color: string };

  editTabs = EditTabs;
  currentTab = this.editTabs.Structure;

  structureHistory = {};
  authTypeHistory = {};
  paginationHistory = {};


  constructor(private renderer: Renderer2, public modalRef: BsModalRef, private _pageService: PagesService) {
    this.editorOptions = new JsonEditorOptions();

    const _this = this;
    this.editorOptions.onChange = function () {
      try {
        _this.editor.get();
        _this.editorIsValid = true;
      } catch (err) {
        console.log(err);
        _this.editorIsValid = false;
      }
    };
  }

  ngOnInit() {
    this.initEditorOptions();
    this.renderer.setStyle(this.editor.jsonEditorContainer.nativeElement, 'height', '100%');

    this._pageService.getThirdParties(false).then(result => {
      this.thirdParties = result.sort((a, b) => a.description.toUpperCase() >= b.description.toUpperCase() ? 1 : -1);
    });

    if (this.modalData && this.modalData.data) {
      this.system = this.modalData.data;
      if (!this.system.third_party_auth_id) {
        this.system.third_party_auth_id = undefined;
      }

      this.systemSettings = this.modalData.settings;

      this.initConnectionDetails();
    }
  }

  ngAfterViewInit() {
    this.viewReady = true;
  }

  saveChanges() {
    this.result.success = true;

    if (this.currentTab == this.editTabs.Advanced) {
      const json = this.editor.get();
      this.system = JSON.parse(JSON.stringify(json));
      this.initConnectionDetails();
    }

    this.result.payload = {
      system: this.system,
      settings: this.systemSettings
    };
    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  initEditorOptions() {
    this.editorOptions.mode = 'code';
  }

  formatDate(date) {
    const mdate = moment(date);
    return mdate.format('DD/MM/YYYY HH:mm:ss');
  }


  onConnDetailsChange(event) {
    this.updateConnectionDetails();
  }

  onTypeChange($event) {
    this.system.connection_details = this.structureHistory[$event] || {};
    this.system.connection_details.type = $event;
    this.initConnectionDetails();
  }

  onAuthChange($event) {
    const type = this.system.connection_details.type;

    Object.keys(this.authTypeHistory).forEach(key => {
      if ($event.toLowerCase() == key.toLowerCase()) {
        this.system.connection_details[$event.toLowerCase()] = this.authTypeHistory[$event] || {};
      } else {
        delete this.system.connection_details[key.toLowerCase()];
      }
    });

    this.system.connection_details.type = type;
    this.system.connection_details.authentication_type = $event;
    this.initConnectionDetails();
  }

  onPaginationChange($event) {
    this.system.connection_details.pagination = this.paginationHistory[$event] || {};
    this.system.connection_details.pagination_type = $event;
    this.initConnectionDetails();
  }


  tabChanged(event) {
    if (event.tabTitle == 'Advanced') {
      this.currentTab = this.editTabs.Advanced;

      this.mapStructure(this.system.connection_details, this.connectionDetails);

      this.editor.set(JSON.parse(JSON.stringify(this.system)));
    }

    if (event.tabTitle == 'Structure') {
      this.currentTab = this.editTabs.Structure;
      const json = this.editor.get();
      this.system = JSON.parse(JSON.stringify(json));
      this.updateConnectionDetails();
    }
  }


  updateConnectionDetails() {

    const conn = this.system.connection_details;

    if (conn) {
      this.structureHistory[conn.type] = conn;

      if (conn.authentication_type) {
        const authType = conn.authentication_type;
        this.authTypeHistory[authType] = conn[authType.toLowerCase()];
      }

      this.mapReverseStructure(this.system.connection_details, this.connectionDetails);
    }
  }


  initConnectionDetails() {

    if (this.system && this.system.connection_details && this.system.connection_details.type) {

      const conn = this.system.connection_details;

      let connection = null;
      if (conn && conn.type) {

        this.structureHistory[conn.type] = conn;

        if (conn.pagination_type) {
          this.paginationHistory[conn.pagination_type] = conn.pagination;
        }

        const connectionType = conn.type.toUpperCase() as ConnectionDetailsType;

        switch (connectionType) {
          case ConnectionDetailsType.FTP:
            connection = new ConnectionDetailsFtp(conn);
            break;
          case ConnectionDetailsType.MSSQL:
            connection = new ConnectionDetailsMssql(conn);
            break;
          case ConnectionDetailsType.ODBC:
            connection = new ConnectionDetailsOdbc(conn);
            break;
          case ConnectionDetailsType.LOCAL_FILE:
            connection = new ConnectionDetailsLocalFile(conn);
            break;
          case ConnectionDetailsType.LOCAL_REST:
            connection = new ConnectionDetailsLocalRest(conn);
            break;
          case ConnectionDetailsType.SOAP:
            connection = new ConnectionDetailsSoap(conn);
            break;
          case ConnectionDetailsType.REST:

            if (conn.authentication_type) {
              const authType = conn.authentication_type;

              this.authTypeHistory[authType] = conn[authType.toLowerCase()];

              switch (authType) {
                case ConnectionAuthenticationType.OAUTH1:
                  connection = new ConnectionDetailsRestOauth1(conn);
                  break;
                case ConnectionAuthenticationType.OAUTH2:
                  connection = new ConnectionDetailsRestOauth2(conn);
                  break;
                case ConnectionAuthenticationType.SESSION:
                  connection = new ConnectionDetailsRestSession(conn);
                  break;
                case ConnectionAuthenticationType.NO_AUTHENTICATION:
                  connection = new ConnectionDetailsRestNoAuth(conn);
                  break;
                case ConnectionAuthenticationType.CUSTOM:
                  connection = new ConnectionDetailsRestCustom(conn);
                  break;
              }

            } else {
              conn.authentication_type = ConnectionAuthenticationType.NO_AUTHENTICATION;
              const authType = conn.authentication_type;
              this.authTypeHistory[authType] = conn[authType.toLowerCase()];
              connection = new ConnectionDetailsRestNoAuth(conn);
            }

            break;
        }
      }

      this.connectionDetails = connection;

      this.mapStructure(this.system.connection_details, this.connectionDetails);
    }
  }


  mapStructure(original, data) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (key !== '_ctype') {
          if (!original.hasOwnProperty(key)) {
            original[key] = {};
          }

          if (data[key]) {
            // var cname = data[key].constructor.name;
            const cname = data[key]._ctype;

            if (cname === 'ConnectionDetailStructure') {
              this.mapStructure(original[key], data[key]._structure);
            } else if (cname === 'ConnectionDetailSchema') {
              original[key] = data[key].value;
            } else if (cname === 'ConnectionAuthenticationOauth1' ||
              cname === 'ConnectionAuthenticationOauth2' ||
              cname === 'ConnectionAuthenticationSession' ||
              cname === 'ConnectionAuthenticationCustom') {
              this.mapStructure(original[key], data[key]);
            }
          }
        }
      });
    }
  }


  mapReverseStructure(original, data) {
    if (data) {
      Object.keys(data).forEach(key => {
        if (key !== '_ctype') {
          if (!original.hasOwnProperty(key)) {
            original[key] = {};
          }

          if (data[key]) {
            // var cname = data[key].constructor.name;
            const cname = data[key]._ctype;

            if (cname === 'ConnectionDetailStructure') {
              this.mapReverseStructure(original[key], data[key]._structure);
            } else if (cname === 'ConnectionDetailSchema') {
              // original[key] = data[key].value;

              if (data[key].optional && data[key].optional.isOptional) {
                if (data[key].optional.bkp) {
                  data[key].value = data[key].optional.bkp;
                  data[key].optional.model = false;
                } else if (data[key].optional.model) {
                  data[key].value = null;
                } else {
                  data[key].value = original[key] || data[key].optional.bkp;
                }
              } else {
                data[key].value = original[key];
              }

            } else if (cname === 'ConnectionAuthenticationOauth1' ||
              cname === 'ConnectionAuthenticationOauth2' ||
              cname === 'ConnectionAuthenticationSession' ||
              cname === 'ConnectionAuthenticationCustom') {
              this.mapReverseStructure(original[key], data[key]);
            }
          }
        }
      });
    }
  }

  onSystemSettingsChange($event) {
    console.log($event);
    this.systemSettings.color = $event;
    console.log(this.systemSettings);
  }

}
