import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {System} from '../system/system';
import {ApiService} from '../../_api/api.service';
import {Type} from '../type/type';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalsService} from '../../_utils/modals/modals.service';
import {BodyOutputType, Toast, ToasterConfig, ToasterService} from 'angular2-toaster';
import {SidebarService, SidebarType} from '../sidebar.service';
import {PagesService} from '../pages.service';
import * as async from 'async';
import 'rxjs/add/operator/toPromise';
import {ProgressbarConfig} from 'ngx-bootstrap/progressbar';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {QueueTableComponent} from './table/queue-table.component';


@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ApiService, ProgressbarConfig]
})
export class QueueComponent implements OnInit {

  loading = true;

  @ViewChild('pop') pop: PopoverDirective;

  systems: Array<System>;
  type_origin: Type = undefined;
  type_destination: Type = undefined;

  types_from_display: Array<any> = [];
  types_to_display: Array<any> = [];

  typeSelected: { type_origin: Type, type_destination: Type };

  public hookCommitAfterPublish = false;

  typeOrigin_param: string;
  typeDestination_param: string;

  next_url: string;
  data_id = null;

  // TABLE PROPERTIES
  @ViewChild('myTable') table: any;

  selected = [];

  config: ToasterConfig;

  isReprocessing = false;
  progressValue = 0;

  isDeleting = false;

  @ViewChild('queueTable') queueTable: QueueTableComponent;
  typeOriginId: string = null;
  typeDestinationId: string = null;


  constructor(private _api: ApiService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _modals: ModalsService,
              private toasterService: ToasterService,
              private customSidebarService: SidebarService,
              private _pageService: PagesService) {
  }


  ngOnInit() {

    this.setLoading();

    this.typeSelected = {
      type_origin: undefined,
      type_destination: undefined
    };

    // route subscriber
    this._route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.data_id = params['data_id'];
        this._router.navigate(['.'], {relativeTo: this._route, queryParams: {userId: params['userId']}});
      });

    this.loading = true;

    // get SYSTEMS from API
    this.loadSystems();

    this._route.params.subscribe(params => {
      this.typeOrigin_param = params['from'];
      this.typeDestination_param = params['to'];
      if (this.typeOrigin_param) {
        this.customSidebarService.expand('settings-sidebar', SidebarType.Queue);
        this.reloadSelectedTypes();
      }
    });

  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }


  loadSystems() {
    return new Promise((resolve, reject) => {
      // get SYSTEMS from API
      this._api.get('system').then(response => {
        this.systems = response.results;
        this.loading = false;
        this.types_from_display = [];

        this.systems.forEach(s => {
          s.types.forEach(t => {
            if (t.hooks && t.hooks.post && t.hooks.post.map(hp => hp.action === 'publish').length > 0) {
              // this.types_from.push(t);

              const obj = {
                type: t,
                label: (t ? `${this.getSystemFromId(t.system_id).alias} > ${t.description}` : '[TYPE NOT FOUND]')
              };
              this.types_from_display.push(obj);
            }
          });
        });

        this.types_from_display.sort((a, b) => a.label.toUpperCase() >= b.label.toUpperCase() ? 1 : -1);

        this.loaded();
        resolve();
      });
    });
  }


  reloadSelectedTypes() {
    this.loadSystems().then(() => {
      if (this.typeOrigin_param) {
        this.typeSelected.type_origin = this.getTypeFromId(this.typeOrigin_param);
        this.typeOriginId = this.typeSelected.type_origin.id;
        this.selectTypeFrom();
        if (this.typeDestination_param) {
          setTimeout(() => {
            this.typeSelected.type_destination = this.getTypeFromId(this.typeDestination_param);
            this.typeDestinationId = this.typeSelected.type_destination.id;
          });
        }
      }
    });
  }

  reloadData() {
    if (this.queueTable) {
      this.queueTable.reloadData();
    }
  }


  getSystemFromId(id): System {
    return this.systems.filter(s => s.id === id)[0];
  }

  getTypeFromId(id): Type {
    return this.systems.map(s => {
      return s.types.filter(t => t.id === id)[0];
    }).filter(t => t !== undefined)[0];
  }

  selectTypeFrom() {

    this.typeOriginId = this.typeSelected.type_origin.id;

    this.types_to_display = [];
    setTimeout(() => {
      this.typeSelected.type_destination = undefined;
      this.typeDestinationId = undefined;

      this.typeSelected.type_origin.hooks.post.filter(hp => hp.action === 'publish').map(hp => {
        // this.types_to.push(this.getTypeFromId(hp.destination));
        const t = this.getTypeFromId(hp.destination);
        const obj = {
          type: t,
          label: (t ? `${this.getSystemFromId(t.system_id).alias} > ${t.description}` : '[TYPE NOT FOUND]'),
          commit_after_publish: hp.commit_after_publish
        };
        this.types_to_display.push(obj);
      });

      this.types_to_display.sort((a, b) => a.label.toUpperCase() >= b.label.toUpperCase() ? 1 : -1);
    });
  }

  selectTypeTo() {
    if (this.typeSelected.type_destination) {

      // Check if hook has commit after publish
      this.types_to_display.forEach(ttd => {
        if (ttd.type.id === this.typeSelected.type_destination.id) {
          this.hookCommitAfterPublish = ttd.commit_after_publish;
        }
      });

      this.typeDestinationId = this.typeSelected.type_destination.id;
    }
  }

  formatDate(date) {
    return date.format('DD/MM/YYYY HH:mm:ss');
  }

  reprocessSelected(event) {
    const selected = event;

    const _th = this;
    const queueTable = this.queueTable;


    const bucketSize = 20;

    this.isReprocessing = true;
    this.progressValue = 0;

    let countSuccess = 0;
    let countError = 0;
    const totals = selected.length;

    // create a queue object with concurrency "bucketSize"
    const q = async.queue(function (item, callback) {
      _th._api.post('genericdata/reprocess', {data_id: item.parent.value.data_id}).then(() => {

        queueTable.unselect(item.parent.value.data_id);

        countSuccess++;
        callback(null, true);
      }).catch(error => {
        console.log(error);
        countError++;
        callback(error);
      });
    }, bucketSize);

    // assign a callback
    q.drain = function () {
      console.log('all items have been processed');
      _th.isReprocessing = false;

      if (countSuccess > 0) {
        _th.showToast('info', 'Notification', countSuccess + (countSuccess == 1 ? ' task' : ' tasks') + ' added to queue');
      }

      if (countError > 0) {
        _th.showToast('error', 'Error', countError + (countError == 1 ? ' error' : ' errors') + ' occured!');
      }

    };

    // add some items to the queue (batch-wise)
    q.push(selected, function (err) {
      const aux = Math.round(100 * (countSuccess + countError) / totals);
      _th.progressValue = (aux <= 0 ? 0 : (aux >= 100 ? 100 : aux));
    });
  }

  runTypeAction(action) {
    const type_id = action === 'run' ? this.typeSelected.type_origin.id : this.typeSelected.type_destination.id;
    if (type_id) {
      this._modals.showConfirmModal('Execute ' + (action === 'run' ? 'Task' : 'Commit') + '?', '').then(cRes => {
        if (cRes && cRes.success) {
          this.runNow(type_id, action);
        }
      });
    }
  }

  runNow(type_id, action) {
    this.setLoading();
    this._api.put('type/' + type_id + '/' + action, {}).then(() => {
      this.showToast('info', 'Notification', action.toUpperCase() + ' added to queue');
      this.loaded();
    }).catch(res => {
      this.showToast('error', 'Error', action.toUpperCase() + ' ERROR (' + res._body + ')');
      this.loaded();
    });
  }

  private showToast(type: string, title: string, body: string) {
    this.config = new ToasterConfig({
      positionClass: 'toast-top-right',
      timeout: 2000,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: false,
      animation: 'fade',
      limit: 5,
    });
    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.popAsync(toast);
  }

  exportToCsv(event) {
    const parsedArray = event.map(elem => {
      const parent = elem.parent;
      const value = parent.value;
      return {
        dataID: parent.dataID,
        customer_id: value.customer_id,
        created_at: value.created_at,
        updated_at: value.updated_at,
        data_id: value.data_id,
        id: value.id,
        is_local_processed: value.is_local_processed,
        is_processed: value.is_processed,
        log: value.log,
        type_destination: value.type_destination,
        type_origin: value.type_origin,
      };
    });

    console.log(parsedArray);

    const formData = {data: parsedArray};
    this._modals.showExportCsvModal(formData).subscribe(res => {
      if (res && res.success) {
        const payload = res.payload;
        console.log(payload);
      }
    });

  }

  markAsProcessedSelected(event) {
    const selected = event;

    const _th = this;
    const queueTable = this.queueTable;

    const bucketSize = 20;

    this.isReprocessing = true;
    this.progressValue = 0;

    let countSuccess = 0;
    let countError = 0;
    const totals = selected.length;

    // create a queue object with concurrency "bucketSize"
    const q = async.queue(function (item, callback) {

      const queueId = item.parent.queueId;

      _th._api.put('gatewayqueue/' + queueId + '/set_as_processed', {}).then(() => {
        queueTable.reloadSelected(queueId, item.parent.value.data_id);
        countSuccess++;
        callback(null, true);
      }).catch(error => {
        console.log(error);
        countError++;
        callback(error);
      });
    }, bucketSize);

    // assign a callback
    q.drain = function () {
      console.log('all items have been processed');
      _th.isReprocessing = false;

      if (countSuccess > 0) {
        _th.showToast('info', 'Notification', countSuccess + (countSuccess == 1 ? ' task' : ' tasks') + ' marked as processed.');
      }

      if (countError > 0) {
        _th.showToast('error', 'Error', countError + (countError == 1 ? ' error' : ' errors') + ' occured!');
      }

    };

    // add some items to the queue (batch-wise)
    q.push(selected, function (err) {
      const aux = Math.round(100 * (countSuccess + countError) / totals);
      _th.progressValue = (aux <= 0 ? 0 : (aux >= 100 ? 100 : aux));
    });
  }

  deleteAllData() {
    const _th = this;

    this._modals.showConfirmModal('Delete Queue Data', 'Are you sure you want to delete ALL Queue Data?').then(cRes => {
      if (cRes && cRes.success) {
        this.isDeleting = true;

        if (this.typeSelected
          && this.typeSelected.type_destination
          && this.typeSelected.type_destination.id
          && this.typeSelected.type_origin
          && this.typeSelected.type_origin.id) {

          const tOrigin = this.typeSelected.type_origin.id;
          const tDest = this.typeSelected.type_destination.id;

          _th._api.put('gatewayqueue/delete', {type_origin: tOrigin, type_destination: tDest}).then(() => {
            // queueTable.removeDeleted(item.dataId);
            _th.showToast('info', 'Notification', 'All Queue Data will be deleted in the background!');
            this.isDeleting = false;
          }).catch(error => {
            console.log(error);
            _th.showToast('error', 'Error', 'Error deleting Queue Data!');
            this.isDeleting = false;
          });
        }
      }
      this.isDeleting = false;
    });
  }

  public checkAutoCommit(ty: {type: Type, label: string, commit_after_publish: boolean}) {
    console.log(ty);
    this.hookCommitAfterPublish = ty.commit_after_publish;
  }
}
