import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpoint, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/delay';
import {LoginService} from '../../../auth/components/login/login.service';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-main-layout',
  styleUrls: ['./main.layout.scss'],
  templateUrl: './main.layout.html',
})
export class MainLayoutComponent implements OnDestroy, OnInit {

  layout: any = {};
  sidebar: any = {};

  isAdmin = false;
  loggedAsUser = null;

  protected menuClick$: Subscription;

  constructor(protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService,
              private _login: LoginService) {

    const isBp = this.bpService.getByName('is');
    this.menuClick$ = this.menuService.onItemSelect()
      .withLatestFrom(this.themeService.onMediaQueryChange())
      .delay(20)
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
        }
      });
  }

  ngOnInit() {
    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();
  }

  ngOnDestroy() {
    this.menuClick$.unsubscribe();
  }
}
