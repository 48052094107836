import {Component, ViewChild} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {SidebarService} from './pages/sidebar.service';
import {ComplexTypeService} from './_utils/complex_types/complex-types';
import {PagesService} from './pages/pages.service';
import {DomSanitizer} from '@angular/platform-browser';

import {ngxLoadingAnimationTypes, NgxLoadingComponent} from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'ngx-app',
  providers: [SidebarService, ComplexTypeService],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  private toasterService: ToasterService;
  title = 'app';

  public loading = false;
  public showStopLoading = false;

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public config = {
    animationType: ngxLoadingAnimationTypes.none,
    primaryColour: this.primaryColour,
    secondaryColour: this.secondaryColour,
    tertiaryColour: this.primaryColour,
    backdropBorderRadius: '3px'
  };

  constructor(toasterService: ToasterService, public _pagesService: PagesService) {
    this.toasterService = toasterService;

    _pagesService.loadingEvent.subscribe(event => {
      setTimeout(() => {
        this.loading = event.loading;
        this.showStopLoading = event.showStop;
      });
    });
  }

  public stopLoading(): void {
    this._pagesService.stopLoading(true);
  }
}
