import {ITypeScheduleData} from '../../_utils/interfaces/itype-schedule-data';

export class StructureFieldObject {
  public field_origin: Array<string | object>;
  public field_destination: Array<string | object>;
  public validation: Array<string | object>;
}

export class PublishObject {
  public action: string;
  public structure: StructureFieldObject[];
  public commit_after_publish = false;
  public on_hold = false;

  constructor(public destination: string) {
    this.action = 'publish';
  }
}

export class PreObject {
  public property: any;
  public type: string;

  constructor() {
  }
}

export class HooksObject {
  public pre: Array<PreObject>;
  public commit: object;
  public post: Array<PublishObject>;

  constructor() {
    this.post = [];
  }
}

export class Type {

  public id: string;
  public hooks: HooksObject;
  public commit: object;
  public cron: boolean;
  public task: object;
  public on_hold: boolean;
  public cron_schedule_commit: any;
  public cron_schedule_task: any;
  public links: Array<TypeLink>;
  public append_links: Array<TypeAppendLink>;
  public public_name: string;
  public schedulers: Array<ITypeScheduleData>;

  constructor(public system_id: string, public description: string) {
    this.hooks = new HooksObject();
  }

}

export class EmptyType extends Type {
  constructor(public system_id: string) {
    super(system_id, null);
  }
}


export class TypeLink {
  public type: string;
  public property: Array<any>;

  constructor(type?, property?) {
    this.type = type || '';
    this.property = property || [];
  }
}


export class TypeAppendLink {
  public type: string;
  public property: Array<any>;

  constructor(type?, property?) {
    this.type = type || '';
    this.property = property || [];
  }
}


export class TypeCollection {
  [key: string]: Type;
}
