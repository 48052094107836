import {AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {LoginService} from '../../auth/components/login/login.service';
import {PagesService} from '../pages.service';

import * as moment from 'moment';
import * as d3 from 'd3';

@Component({
  selector: 'app-gapp-statistics',
  templateUrl: './gapp-statistics.component.html',
  styleUrls: ['./gapp-statistics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ApiService],
})
export class GappStatisticsComponent implements OnInit, AfterViewInit {

  isAdmin = false;
  loggedAsUser = false;

  loading = false;
  queueStats: any;
  datastoreStats: any;
  storageStats: any;

  // GRAPHS
  width = 600;
  height = 400;

  colorScale: any;
  svgDatastore_A: any;
  svgDatastore_B: any;
  svgStorage_A: any;
  svgStorage_B: any;

  // legend dimensions
  legendRectSize = 25; // defines the size of the colored squares in legend
  legendSpacing = 6; // defines spacing between squares

  radioModelDatastore = 'bytes';
  radioModelStorage = 'files_size';

  hasNextPageDatastore = false;
  nextPage = null;

  storageStatsMessage = null;


  constructor(private _api: ApiService, private _login: LoginService, private _pageService: PagesService) {
    this.colorScale = d3.scaleSequential(d3.interpolateCool).domain([0, 10]);
  }

  ngOnInit() {
    this.isAdmin = this._login.isAdmin();
    this.loggedAsUser = this._login.getLoggedAsUser();

    this.loadData('all');
  }

  ngAfterViewInit() {
  }

  setLoading() {
    this.loading = true;
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
    this.loading = false;
  }

  loadData(type: string) {
    this.setLoading();

    // QUEUE STATS - only for super users
    if (type === 'all' || type === 'queue') {
      if (this.isAdmin && !this.loggedAsUser) {
        this._api.get('gae/queue_stats').then(response => {
          this.queueStats = response.results;
          this.queueStats.map(elem => {
            elem.oldest_eta_usec = this.formatEta(elem.oldest_eta_usec);
            return elem;
          });
          this.loaded();
        }).catch(error => {
          console.error(error);
          this.loaded();
        });
      }
    }

    // DATASTORE STATS
    if (type === 'all' || type === 'datastore') {
      this._api.get('gae/datastore_stats?detail_kinds=true').then(response => {
        this.datastoreStats = response.results;
        if (this.datastoreStats) {
          if (this.datastoreStats.namespaces && this.datastoreStats.namespaces.next_page !== '') {
            this.hasNextPageDatastore = true;
            this.nextPage = this.datastoreStats.namespaces.next_page;
          } else {
            this.hasNextPageDatastore = false;
            this.nextPage = null;
          }
          this.createCanvas();
          this.loaded();
        }
      }).catch(error => {
        console.error(error);
        this.loaded();
      });
    }

    // STORAGE STATS
    if (type === 'all' || type === 'storage') {
      this._api.get('gae/storage_stats').then(response => {

        if (typeof response.results == 'string') {
          this.storageStatsMessage = response.results;
          this.loaded();
        } else {
          this.storageStats = response.results;
          if (this.storageStats) {
            this.createStorageCanvas();
          } else {
            this.storageStatsMessage = 'No data to show';
          }
          this.loaded();
        }


      }).catch(error => {
        console.error(error);
        this.loaded();
      });
    }


  }


  onRefresh(type) {
    this.loadData(type);
  }


  formatEta(value) {
    if (value) {
      const now = moment().utc();
      const eta = moment(value / 1000).utc();
      const elapsed = now.diff(eta, 'minutes', true);
      return Math.round(elapsed);
    }
  }


  createCanvas() {
    // SVG CANVAS
    const canvas_A = d3.select('#datastoreCanvas_A');
    canvas_A.selectAll('svg').remove();

    // GRAPH - DATASTORE KINDS / BYTES
    const svg = canvas_A.append('svg')
      .attr('id', 'svgDatastore_A')
      .attrs({width: this.width, height: this.height});
    this.svgDatastore_A = svg.append('g')
      .attr('transform', 'translate(' + this.height / 2 + ',' + this.height / 2 + ')')
      .style('background-color', '#999');

    this.createDataStoreGraph_A(this.svgDatastore_A);


    if (this.isAdmin && !this.loggedAsUser) {
      // SVG CANVAS
      const canvas_B = d3.select('#datastoreCanvas_B');
      canvas_B.selectAll('svg').remove();

      // GRAPH - DATASTORE NAMESPACES.ITEMS / BYTES
      const svg2 = canvas_B.append('svg')
        .attrs({width: this.width, height: this.height});
      this.svgDatastore_B = svg2.append('g')
        .attr('transform', 'translate(' + this.height / 2 + ',' + this.height / 2 + ')')
        .style('background-color', '#999');

      this.createDataStoreGraph_B(this.svgDatastore_B);
    }
  }

  createDataStoreGraph_A(canvas) {
    const _this = this;

    const dataset = this.datastoreStats.kinds.map(elem => {
      return {label: elem.kind_name, value: elem[_this.radioModelDatastore], enabled: true};
    });

    dataset.sort((a, b) => b.value - a.value);

    const radius = this.height / 2 - 10;

    const arc = d3.arc().innerRadius(radius * 0.3).outerRadius(radius);
    const pie = d3.pie().value(function (d) {
      return d['value'];
    }).sort(null);

    const path = canvas.selectAll('path')
      .data(pie(dataset))
      .enter().append('path')
      .attr('class', 'datastore-g1')
      .style('fill', function (d, i) {
        return _this.colorScale(i);
      })
      .attr('d', arc);

    if (this.radioModelDatastore === 'count') {
      this.defineTooltip(path, '#datastoreCanvas_A', '', 'Files qty');
    } else if (this.radioModelDatastore === 'bytes') {
      this.defineTooltip(path, '#datastoreCanvas_A', 'Mb', 'Files size', this.byteToMbConverter);
    }

    this.createLegends(canvas, pie, arc, dataset, path);
  }

  createDataStoreGraph_B(canvas) {
    const _this = this;

    const dataset = this.datastoreStats.namespaces.items.map(elem => {
      return {label: elem.customer_name, value: elem[_this.radioModelDatastore], enabled: true};
    });

    dataset.sort((a, b) => b.value - a.value);

    const radius = this.height / 2 - 10;

    const arc = d3.arc().innerRadius(radius * 0.3).outerRadius(radius);
    const pie = d3.pie().value(function (d) {
      return d['value'];
    }).sort(null);

    const path = canvas.selectAll('path')
      .data(pie(dataset))
      .enter().append('path')
      .attr('class', 'datastore-g2')
      .style('fill', function (d, i) {
        return _this.colorScale(i);
      })
      .attr('d', arc);

    if (this.radioModelDatastore === 'count') {
      this.defineTooltip(path, '#datastoreCanvas_B', '', 'Files qty');
    } else if (this.radioModelDatastore === 'bytes') {
      this.defineTooltip(path, '#datastoreCanvas_B', 'Mb', 'Files size', this.byteToMbConverter);
    }

    this.createLegends(canvas, pie, arc, dataset, path);
  }


  createStorageCanvas() {
    // SVG CANVAS
    const canvas = d3.select('#storageCanvas');
    canvas.select('svg').remove();

    // GRAPH - DATASTORE KINDS / BYTES
    if (this.isAdmin && !this.loggedAsUser) {
      const svg = canvas.append('svg')
        .attrs({width: this.width, height: this.height});
      this.svgStorage_A = svg.append('g')
        .attr('transform', 'translate(' + this.height / 2 + ',' + this.height / 2 + ')')
        .style('background-color', '#999');

      this.createStorageGraph_A(this.svgStorage_A);
    }

    if (!this.isAdmin || this.loggedAsUser) {
      const svg2 = canvas.append('svg')
        .attrs({width: this.width, height: this.height});
      this.svgStorage_B = svg2.append('g')
        .attr('transform', 'translate(' + this.height / 2 + ',' + this.height / 2 + ')')
        .style('background-color', '#999');

      this.createStorageGraph_B(this.svgStorage_B);
    }
  }

  createStorageGraph_A(canvas) {
    // GRAPH - showing files_size for each customer
    const _this = this;

    const dataset = this.storageStats.customers.map(elem => {
      return {label: elem.customer_name, value: elem[_this.radioModelStorage], enabled: true};
    });
    dataset.sort((a, b) => b.value - a.value);

    const radius = this.height / 2 - 10;

    const arc = d3.arc().innerRadius(radius * 0.3).outerRadius(radius);
    const pie = d3.pie().value(function (d) {
      return d['value'];
    }).sort(null);

    const path = canvas.selectAll('path')
      .data(pie(dataset))
      .enter().append('path')
      .attr('class', 'storage-g1')
      .style('fill', function (d, i) {
        return _this.colorScale(i);
      })
      .attr('d', arc);

    if (this.radioModelStorage === 'files_quantity') {
      this.defineTooltip(path, '#storageCanvas', '', 'Files qty');
    } else if (this.radioModelStorage === 'files_size') {
      this.defineTooltip(path, '#storageCanvas', 'Mb', 'Files size', this.byteToMbConverter);
    }

    this.createLegends(canvas, pie, arc, dataset, path);
  }


  createStorageGraph_B(canvas) {
    // GRAPH - showing files_size for each customer
    const _this = this;

    const dataset = this.storageStats.type_details.map(elem => {
      return {label: elem.type_description, value: elem[_this.radioModelStorage], enabled: true};
    });
    dataset.sort((a, b) => b.value - a.value);

    const radius = this.height / 2 - 10;

    const arc = d3.arc().innerRadius(radius * 0.3).outerRadius(radius);
    const pie = d3.pie().value(function (d) {
      return d['value'];
    }).sort(null);

    const path = canvas.selectAll('path')
      .data(pie(dataset))
      .enter().append('path')
      .attr('class', 'storage-g1')
      .style('fill', function (d, i) {
        return _this.colorScale(i);
      })
      .attr('d', arc);

    if (this.radioModelStorage === 'files_quantity') {
      this.defineTooltip(path, '#storageCanvas', '', 'Files qty');
    } else if (this.radioModelStorage === 'files_size') {
      this.defineTooltip(path, '#storageCanvas', 'Mb', 'Files size', this.byteToMbConverter);
    }

    this.createLegends(canvas, pie, arc, dataset, path);
  }


  byteToMbConverter(number) {
    return Math.round(100 * number / 1048576) / 100;
  }


  defineTooltip(path, canvasId, unit: string = '', prefix: string = '', converter: (n: number) => any = null) {
    // define tooltip

    d3.select(canvasId).selectAll('.c-tooltip').remove();

    const tooltip = d3.select(canvasId) // select element in the DOM with id 'chart'
      .append('div') // append a div element to the element we've selected
      .attr('class', 'c-tooltip'); // add class 'tooltip' on the divs we just selected
    tooltip.append('div') // add divs to the tooltip defined above
      .attr('class', 'label'); // add class 'label' on the selection
    tooltip.append('div') // add divs to the tooltip defined above
      .attr('class', 'count'); // add class 'count' on the selection


    // mouse event handlers are attached to path so they need to come after its definition
    path.on('mouseover', function (d) {  // when mouse enters div
      tooltip.select('.label').html(d.data.label); // set current label

      let value = d.value;
      if (converter) {
        value = converter(value);
      }

      tooltip.select('.count').html((prefix !== '' ? prefix + ': ' : '') + value + ' ' + unit); // set current count
      tooltip.style('display', 'block'); // set display
    });

    path.on('mouseout', function () { // when mouse leaves div
      tooltip.style('display', 'none'); // hide tooltip for that element
    });

    path.on('mousemove', function (d) { // when mouse moves
      tooltip.style('top', (d3.event.layerY + 10) + 'px') // always 10px below the cursor
        .style('left', (d3.event.layerX + 10) + 'px'); // always 10px to the right of the mouse
    });
  }


  createLegends(canvas, pie, arc, dataset, path) {
    const _this = this;

    // define legend
    const legend = canvas.selectAll('.legend') // selecting elements with class 'legend'
      .data(pie(dataset)) // refers to an array of labels from our dataset
      .enter() // creates placeholder
      .append('g') // replace placeholders with g elements
      .attr('class', 'legend') // each g is given a legend class
      .attr('transform', function (d, i) {
        // height of element is the height of the colored square plus the spacing
        const height = _this.legendRectSize + _this.legendSpacing;
        // vertical offset of the entire legend = height of a single element & half the total number of elements
        const offset = _this.height / 2;
        // the top of the element is hifted up or down from the center using the offset defiend earlier and the index of the
        // current element 'i'
        const vert = i * height - offset;
        return 'translate(' + (_this.height / 2) + ',' + vert + ')'; // return translation
      });

    // adding colored squares to legend
    legend.append('rect') // append rectangle squares to legend
      .attr('width', _this.legendRectSize) // width of rect size is defined above
      .attr('height', _this.legendRectSize) // height of rect size is defined above
      .style('fill', function (d, i) {
        return _this.colorScale(i);
      }) // each fill is passed a color
      .style('stroke', function (d, i) {
        return _this.colorScale(i);
      }) // each stroke is passed a color
      .on('click', function (label) {
        const rect = d3.select(this); // this refers to the colored squared just clicked
        let enabled = true; // set enabled true to default
        const totalEnabled = d3.sum(dataset.map(function (d) { // can't disable all options
          return (d.enabled) ? 1 : 0; // return 1 for each enabled entry. and summing it up
        }));

        if (rect.attr('class') === 'disabled') { // if class is disabled
          rect.attr('class', ''); // remove class disabled
        } else { // else
          if (totalEnabled < 2) {
            return;
          } // if less than two labels are flagged, exit
          rect.attr('class', 'disabled'); // otherwise flag the square disabled
          enabled = false; // set enabled to false
        }

        pie.value(function (d) {
          if (d['label'] === label.data.label) {
            d['enabled'] = enabled; // if entry label matches legend label
          }
          return (d['enabled']) ? d['value'] : 0; // update enabled property and return count or 0 based on the entry's status
        });

        path = path.data(pie(dataset)); // update pie with new data

        path.transition() // transition of redrawn pie
          .duration(750) //
          .attrTween('d', function (d) { // 'd' specifies the d attribute that we'll be animating
            const interpolate = d3.interpolate(this._current, d); // this = current path element
            this._current = interpolate(0); // interpolate between current value and the new value of 'd'
            return function (t) {
              return arc(interpolate(t));
            };
          });
      });

    // adding text to legend
    legend.append('text')
      .attr('x', _this.legendRectSize + _this.legendSpacing)
      .attr('y', _this.legendRectSize - _this.legendSpacing)
      .text(function (d) {
        return d.data.label;
      }); // return label
  }


  radioModelDatastoreChanged() {
    this.createCanvas();
  }

  radioModelStorageChanged() {
    this.createStorageCanvas();
  }

  loadMoreDatastore() {

    const url = this.nextPage.replace('/api/v1/', '');

    this._api.get(url).then(response => {
      if (response && response.results) {
        this.datastoreStats.namespaces.items.concat(response.results.namespaces.items);
        if (response.results.namespaces.next_page !== '') {
          this.hasNextPageDatastore = true;
          this.nextPage = this.datastoreStats.namespaces.next_page;
        } else {
          this.hasNextPageDatastore = false;
          this.nextPage = null;
        }
        this.createCanvas();
      }
    }).catch(error => {
      console.log(error);
    });
  }

}
