import {Component, Input} from '@angular/core';
import {PathGroupType} from '../path-group/path-group.component';

@Component({
  selector: 'app-path-group-view',
  templateUrl: './path-group-view.component.html',
  styleUrls: ['./path-group-view.component.css']
})
export class PathGroupViewComponent {

  @Input() dataArray: Array<any>;
  @Input() groupType: PathGroupType;
  @Input() testResult?: any;

}
