import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ApiService} from '../../../_api/api.service';


export class ValidationElement {
  public description: string;
  public condition: Array<any>;

  constructor(description?, condition?) {
    this.description = description || '';
    this.condition = condition || [];
  }
}


@Component({
  selector: 'ngx-edit-validation-modal',
  templateUrl: './edit-validation-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class EditValidationModalComponent implements OnInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  validation: Array<ValidationElement>;

  constructor(public modalRef: BsModalRef, private _api: ApiService) {
  }

  ngOnInit() {
    this.modalData.title = 'Edit Validation';
    if (this.modalData.data) {
      this.validation = Object.assign([], this.modalData.data);
    }
  }


  saveChanges() {
    this.result.success = true;
    this.result.payload = this.validation;
    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }


  onChange(event, index) {
    this.validation[index].condition = event;
  }

  removeValidation(val, ind) {
    this.validation.splice(ind, 1);
  }

  addValidation() {
    const newObj = new ValidationElement();
    this.validation.push(newObj);
  }


  isValid() {
    return true;
  }

}




