import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LoginService} from '../auth/components/login/login.service';

@Injectable()
export class UserGuard implements CanActivate {

  constructor(private router: Router, private _login: LoginService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const isAdmin = this._login.isAdmin();
    const isLoggedUser = this._login.getLoggedAsUser() != null;

    if (!isAdmin || isLoggedUser) {
      return true;
    } else {
      this.router.navigate(['/pages/dashboard']);
      return false;
    }
  }
}
