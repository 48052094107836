import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {ApiService} from '../../../_api/api.service';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {GatewayQueueObservable} from '../gateway-queue';
import {TypeService} from '../../type/type.service';
import {JsonModalComponent} from '../../../_utils/modals/modal/json-modal.component';
import {PagesService} from '../../pages.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {ClipboardService} from 'ngx-clipboard';


@Component({
  selector: 'app-queue-table',
  templateUrl: './queue-table.component.html',
  styleUrls: ['../queue.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ApiService]
})
export class QueueTableComponent implements OnInit, OnChanges {

  loading = false;

  listData: Array<any> = [];
  listDataGrouped: Array<any> = [];
  @Input() typeOrigin: string = undefined; // type ID
  @Input() typeDestination: string = undefined; // type ID

  @Input() allowReprocess = true;
  @Input() selectionType = 'checkbox';

  @Output() reprocessed?: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportedToCsv?: EventEmitter<any> = new EventEmitter<any>();
  @Output() markedAsProcessed?: EventEmitter<any> = new EventEmitter<any>();
  @Output() deletedAll?: EventEmitter<any> = new EventEmitter<any>();

  rows: BehaviorSubject<any[]>;
  gatewayQueueObservable: GatewayQueueObservable;

  showAdvancedFilter = false;
  invertFilterResult = false;
  includeNestedFilterResult = true;

  next_url: string;
  filterQueue = '';
  selected = [];
  showDataOptions = ['25', '100', '250', '500', '5000', '10000', 'ALL'];
  showDataQty = '25';

  @ViewChild('queueTable') table: any;
  reorderable = false;

  @ViewChild('pop') pop: PopoverDirective;
  showAdvancedMenu = false;
  stopLoading = false;

// ==========================

  data_id = null;

// ==========================

  isLoadingBatch = false;
  batchCount = 0;
  batchTotal: number | string = 0;


  constructor(private _api: ApiService,
              private bsModalService: BsModalService,
              private _typeService: TypeService,
              private _clipboardService: ClipboardService,
              private _pageService: PagesService) {

    _pageService.stopLoadingEvent.subscribe(() => {
      setTimeout(() => {
        this.stopLoading = true;
      });
    });

  }

  ngOnInit() {
    this.rows = new BehaviorSubject<any[]>([]);
  }

  ngOnChanges(changes) {
    if (changes.typeDestination) {
      this.reloadData();
    }
  }

  reloadData() {
    this.rows = new BehaviorSubject<any[]>([]);
    this.next_url = '';
    this.listData = [];
    this.listDataGrouped = [];
    this.selected = [];

    this.gatewayQueueObservable = new GatewayQueueObservable();
    this.gatewayQueueObservable.dataChange.subscribe(
      value => {
        this.listData = value;
        this.listDataGrouped = this.updateDataRowsInner(value);
        this.updateDataRows(value);

        if (this.filterQueue) {
          this.filterItems(this.filterQueue);
        }
      },
      error => console.log('ERR', error),
      () => console.log('FINISHED')
    );

    this.loadMore();
  }

  setLoading() {
    this._pageService.setLoading(true, true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }


  filterItems(value) {
    const filts = value.split('|');
    let arr = [];

    if (this.includeNestedFilterResult) {
      arr = Object.assign([], this.listData);
    } else {
      arr = Object.assign([], this.listDataGrouped.map(el => el.parent.value));
    }

    const filteredArray = arr.filter(el => {
      const aux = JSON.stringify(el);
      if (this.invertFilterResult) {
        return !filts.some(ele => {
          return aux.toLowerCase().indexOf(ele.trim().toLowerCase()) > -1;
        });
      } else {
        return filts.some(ele => {
          return aux.toLowerCase().indexOf(ele.trim().toLowerCase()) > -1;
        });
      }
    });
    this.updateDataRows(filteredArray);
  }

  copyFilter(value) {
    this._clipboardService.copyFromContent(value);
  }

  clearFilter() {
    this.filterQueue = '';
    this.filterItems(this.filterQueue);
  }

  updateDataRowsInner(values) {
    const newData = values.map(elem => {
      const newElem = {updated: null, created: null, dataID: null, value: null, queueId: null};
      newElem.updated = moment(elem.updated_at);
      newElem.created = moment(elem.created_at);
      newElem.dataID = elem.data_id.replace(elem.type_origin + '|', '');
      newElem.value = elem;
      newElem.queueId = elem.id;
      return newElem;
    });

    // sort the data by date using moment.js
    const sorted = newData.sort(function (left, right) {
      if (moment.utc(right.updated).diff(moment.utc(left.updated)) == 0) {
        return (left.value.is_processed === right.value.is_processed) ? 0 : left.value.is_processed ? 1 : -1;
      } else {
        return moment.utc(right.updated).diff(moment.utc(left.updated));
      }
    });

    const grouped = this.groupBy(sorted, 'dataID');
    let resultData = [];

    for (let i = 0; i < Object.keys(grouped).length; i++) {
      const key = Object.keys(grouped)[i];
      values = grouped[key];

      const obj = {dataId: key, parent: values.shift(), children: values};
      resultData.push(obj);
    }

    resultData = resultData.sort(function (left, right) {
      return moment.utc(right.parent.updated).diff(moment.utc(left.parent.updated));
    });

    return resultData;
  }

  updateDataRows(values) {
    const resultData = this.updateDataRowsInner(values);
    this.rows = new BehaviorSubject<any[]>(resultData);
  }


  loadMore() {
    if (this.typeOrigin && this.typeDestination) {
      this.setLoading();

      if (this.showDataQty !== 'ALL' && Number(this.showDataQty) <= 500) {
        let urlObj = {};
        if (this.next_url !== '') {
          const search = this.next_url.replace('/api/v1/gatewayqueue?', '');
          urlObj = this.queryStringToJSON(search);
        }
        urlObj['data_id'] = this.data_id;
        urlObj['type_origin'] = this.typeOrigin;
        urlObj['type_destination'] = this.typeDestination;
        urlObj['fetch_qty'] = this.showDataQty;

        const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
          return [k, urlObj[k]].join('=');
        }).join('&');

        const getUrl = 'gatewayqueue?' + urlParams;

        this._api.get(getUrl).then(response => {
          this.next_url = response.next_url;
          this.gatewayQueueObservable.addData(response.results);

          this.loaded();
        });

      } else {
        this.innerLoadMore();
      }

    }
  }

  async innerLoadMore() {
    const loops = this.showDataQty == 'ALL' ? 2 : Number(this.showDataQty) / 100;
    this.isLoadingBatch = true;
    this.batchCount = 1;
    this.batchTotal = this.showDataQty == 'ALL' ? 'ALL' : loops;

    let tempData = [];

    for (let i = 0; i < loops; i++) {

      if (this.stopLoading) {
        this.stopLoading = false;
        break;
      }

      let urlObj = {};
      if (this.next_url !== '') {
        const search = this.next_url.replace('/api/v1/gatewayqueue?', '');
        urlObj = this.queryStringToJSON(search);
      }
      urlObj['data_id'] = this.data_id;
      urlObj['type_origin'] = this.typeOrigin;
      urlObj['type_destination'] = this.typeDestination;
      urlObj['fetch_qty'] = '100';

      const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
        return [k, urlObj[k]].join('=');
      }).join('&');

      const getUrl = 'gatewayqueue?' + urlParams;

      const res = await this._api.get(getUrl);
      this.next_url = res.next_url;

      tempData = [...tempData, ...res.results];
      this.batchCount++;

      if (this.showDataQty == 'ALL') {
        i = 0;
      }

      if (!this.next_url) {
        break;
      }
    }

    this.gatewayQueueObservable.addData(tempData);
    this.isLoadingBatch = false;
    this.loaded();
  }


  formatDate(date) {
    return date.format('DD/MM/YYYY HH:mm:ss');
  }

  compareDates(a, b) {
    return moment.utc(a).diff(moment.utc(b));
  }

  groupBy(array, prop) {
    return array.reduce(function (groups, item) {
      const val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
  }


  queryStringToJSON(url) {
    const pairs = url.split('&');
    const result = {};
    pairs.forEach(function (pair) {
      let a = pair.substring(0, pair.indexOf('='));
      const b = pair.substring(pair.indexOf('=') + 1);
      a = a.replace('?', '');
      result[a] = decodeURIComponent(b || '');
    });
    return JSON.parse(JSON.stringify(result));
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }


  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }


  unselect(dataId) {
    this.selected = this.selected.filter(item => {
      return item.parent.value.data_id !== dataId;
    });
  }

  reloadSelected(queueId, dataId) {
    const _th = this;

    const urlParams = 'fetch_qty=1&data_id=' + dataId;
    const getUrl = 'gatewayqueue?' + urlParams;
    this._api.get(getUrl).then(response => {
      if (response && response.results && response.results.length == 1) {
        const resp = response.results[0];
        const nRows = _th.rows.value.filter(elem => elem.parent.queueId === resp.id);
        if (nRows && nRows.length == 1) {
          nRows[0].parent.value = resp;
        }
        _th.gatewayQueueObservable.reloadData(resp);
        _th.unselect(dataId);
      }
    });
  }

  getSelected() {
    return this.selected;
  }

  onShown(): void {
    setTimeout(() => {
      this.pop.hide();
    }, 1000);
  }

  openJsonModal(title, data) {
    const formData = {title: title, data: data};

    const initialState = {modalData: formData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(JsonModalComponent, config);
    activeModal.content.modalResult.subscribe(res => {
      if (res && res.success) {
        const payload = res.payload;
      }
    });
  }

  reprocessSelected() {
    this.reprocessed.emit(this.selected);
  }

  exportToCsv() {
    this.exportedToCsv.emit(this.rows.value);
  }

  getLabel(id) {
    return this._typeService.getSystemTypeLabel(id);
  }

  markAsProcessedSelected() {
    this.markedAsProcessed.emit(this.selected);
  }

  deleteAllData() {
    this.deletedAll.emit();
    this.showAdvancedMenu = false;
  }


}
