import {Component, EventEmitter, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ApiService} from '../../../_api/api.service';
import {GenericDataTableComponent} from '../../../pages/generic-data/table/generic-data-table.component';
import {QueueTableComponent} from '../../../pages/queue/table/queue-table.component';
import {Type} from '../../../pages/type/type';
import {System} from '../../../pages/system/system';
import {PagesService} from '../../../pages/pages.service';


@Component({
  selector: 'ngx-data-selector-modal',
  templateUrl: './data-selector-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class DataSelectorModalComponent implements OnInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  @ViewChild('genTable') genericDataTable: GenericDataTableComponent;
  @ViewChild('queueTable') queueTable: QueueTableComponent;

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  typeFrom: string;
  typeTo: string;
  typeFromList = [];
  typeToList = [];
  dataType = 'hook'; // hook || reprocessing

  // ===========
  typeSelected: { type_origin: Type, type_destination: Type };
  systems: Array<System>;
  types_from: Array<Type> = [];
  types_to: Array<Type>;


  constructor(public modalRef: BsModalRef,
              private _api: ApiService,
              private renderer: Renderer2,
              private _pageService: PagesService) {
  }

  ngOnInit() {
    this.modalData.title = 'Select Data';
    this.typeFrom = this.modalData.typeFrom;

    if (this.modalData.dataType) {
      this.dataType = this.modalData.dataType;
    }

    if (this.modalData.typeFromList) {
      this.typeFromList = this.modalData.typeFromList;
    }
    if (this.modalData.typeToList) {
      this.typeToList = this.modalData.typeToList;
    }

    this.typeSelected = {
      type_origin: undefined,
      type_destination: undefined
    };

    // get SYSTEMS from API
    this.loadSystems();
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }


  loadSystems() {
    this._api.get('system').then(response => {
      this.systems = response.results;
      this.types_from = [];

      this.systems.map(s => {
        s.types.map(t => {
          if (t.hooks && t.hooks.post && t.hooks.post.map(hp => hp.action === 'publish').length > 0) {
            this.types_from.push(t);
          }
        });
      });

      const tfrom = this.typeFromList.map(elem => this.getTypeFromId(elem));
      if (tfrom.length > 0) {
        const matchTypesFrom = this.types_from.filter(elem => tfrom.indexOf(elem) > -1);
        if (matchTypesFrom.length > 0) {
          this.types_from = matchTypesFrom;
          if (this.types_from.length === 1) {
            this.typeSelected.type_origin = this.types_from[0];
            this.selectTypeFrom();
          }
        }
      }

      this.loaded();
    });
  }


  saveChanges() {
    this.result.success = true;

    if (this.dataType === 'hook') {
      this.result.payload = this.genericDataTable.getSelected()[0];
    } else if (this.dataType === 'reprocessing') {
      this.result.payload = this.queueTable.getSelected()[0];
    }

    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  isValid() {
    if (this.dataType === 'hook') {
      if (!this.genericDataTable) {
        return false;
      } else {
        return (this.genericDataTable.getSelected().length > 0);
      }
    } else if (this.dataType === 'reprocessing') {
      if (!this.queueTable) {
        return false;
      } else {
        return (this.queueTable.getSelected().length > 0);
      }
    }
  }


  callRefresh() {
    if (this.dataType === 'hook') {
      this.genericDataTable.reloadData();
    } else if (this.dataType === 'reprocessing') {
      this.queueTable.reloadData();
    }
  }


  getSystemFromId(id): System {
    return this.systems.filter(s => s.id === id)[0];
  }

  getTypeFromId(id): Type {
    return this.systems.map(s => {
      return s.types.filter(t => t.id === id)[0];
    }).filter(t => t !== undefined)[0];
  }


  selectTypeFrom() {

    this.typeFrom = this.typeSelected.type_origin.id;
    this.types_to = [];
    setTimeout(() => {
      this.typeSelected.type_destination = undefined;
      this.typeSelected.type_origin.hooks.post.filter(hp => hp.action === 'publish').map(hp => {
        this.types_to.push(this.getTypeFromId(hp.destination));
      });

      if (this.typeFromList.length > 0) {
        const tto = this.typeToList.map(elem => this.getTypeFromId(elem));
        if (tto.length > 0) {
          const matchTypesTo = this.types_to.filter(elem => tto.indexOf(elem) > -1);
          if (matchTypesTo.length > 0) {
            this.types_to = matchTypesTo;
            if (this.types_to.length === 1) {
              this.typeSelected.type_destination = this.types_to[0];
              this.selectTypeTo();
            }
          }
        }
      }

    });
  }

  selectTypeTo() {
    if (this.typeSelected.type_destination) {
      this.typeTo = this.typeSelected.type_destination.id;
    }
  }

}

