import {Type} from '../type/type';
import {ITypeScheduleData} from '../../_utils/interfaces/itype-schedule-data';


export class System {
  public alias: string;
  public third_party_auth_id: string;
  public connection_details: object;
  public settings: object;
  public id: string;
  public types: Array<Type>;
  readonly customer_id: string;
  public schedulers: ITypeScheduleData[];

  constructor() {
  }
}

function checkNested(obj, ...arg) {
  const args = Array.prototype.slice.call(arguments, 1);
  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}


// ====================================================
// ======= CONNECTION AUTHENTICATION

export enum ConnectionAuthenticationType {
  OAUTH1 = 'OAUTH1',
  OAUTH2 = 'OAUTH2',
  SESSION = 'SESSION',
  CUSTOM = 'CUSTOM',
  NO_AUTHENTICATION = 'NO_AUTHENTICATION',
}

export class ConnectionAuthenticationOauth2 {
  public _ctype = 'ConnectionAuthenticationOauth2';

  public data = new ConnectionDetailSchema(DetailSchemaType.Object, {}, true);
  public username = new ConnectionDetailSchema(DetailSchemaType.String);
  public url_custom_path = new ConnectionDetailSchema(DetailSchemaType.String);
  public type = new ConnectionDetailSchema(DetailSchemaType.String);
  public password = new ConnectionDetailSchema(DetailSchemaType.String);

  constructor(data: any) {
    if (!data) {
      data = {};
    }
    this.password.value = data['password'] || null;
    this.type.value = data['type'] || null;
    this.url_custom_path.value = data['url_custom_path'] || null;
    this.username.value = data['username'] || null;
    this.data.value = data['data'] || {};
  }
}

export class ConnectionAuthenticationOauth1 {

  private _ctype = 'ConnectionAuthenticationOauth1';

  public type = new ConnectionDetailSchema(DetailSchemaType.String);
  public client_key = new ConnectionDetailSchema(DetailSchemaType.String);
  public data = new ConnectionDetailSchema(DetailSchemaType.Object, null, true);
  public url_custom_path = new ConnectionDetailSchema(DetailSchemaType.String);

  constructor(data: any) {
    if (!data) {
      data = {};
    }
    this.type.value = data['type'] || null;
    this.client_key.value = data['client_key'] || null;
    this.data.value = data['data'] || null;
    this.url_custom_path.value = data['url_custom_path'] || null;
  }
}

export class ConnectionAuthenticationSession {

  private _ctype = 'ConnectionAuthenticationSession';

  public data = new ConnectionDetailSchema(DetailSchemaType.Object, null, true);

  public login = new ConnectionDetailStructure({
    method: new ConnectionDetailSchema(DetailSchemaType.String),
    url: new ConnectionDetailSchema(DetailSchemaType.String),
    structure: new ConnectionDetailSchema(DetailSchemaType.Structure),
  });

  constructor(data: any) {
    if (!data) {
      data = {};
    }
    this.data.value = data['data'] || null;
    if (data.hasOwnProperty('login')) {
      if (data['login'].hasOwnProperty('method')) {
        this.login._structure.method.value = data['login'].method || null;
      }
      if (data['login'].hasOwnProperty('url')) {
        this.login._structure.url.value = data['login'].url || null;
      }
      if (data['login'].hasOwnProperty('structure')) {
        this.login._structure.structure.value = data['login'].structure || null;
      }
    }
  }
}

export class ConnectionAuthenticationCustom {

  private _ctype = 'ConnectionAuthenticationCustom';

  public structure = new ConnectionDetailSchema(DetailSchemaType.Path);

  constructor(data: any) {
    if (!data) {
      data = {};
    }
    this.structure.value = data['structure'] || null;
  }
}


// ====================================================
// ======= CONNECTION DETAILS

export enum PaginationType {
  URL = 'url',
  URL_COUNTER = 'url_counter',
}

export enum ConnectionDetailsType {
  FTP = 'FTP',
  REST = 'REST',
  MSSQL = 'MSSQL',
  ODBC = 'ODBC',
  SOAP = 'SOAP',
  LOCAL_FILE = 'LOCAL_FILE',
  LOCAL_REST = 'LOCAL_REST',
}

export enum DetailSchemaType {
  String = 'string',
  Boolean = 'boolean',
  Object = 'object',
  Path = 'path',
  Structure = 'structure',
  Options = 'options'
}

class OptionalSchema {
  public isOptional: boolean;
  public model: boolean;
  public bkp: any;

  constructor(isOptional: boolean, model: boolean, bkp: any = null) {
    this.isOptional = isOptional;
    this.model = model;
    this.bkp = bkp;
  }
}

export class ConnectionDetailStructure {

  public _ctype = 'ConnectionDetailStructure';
  private isModal = false;
  public _structure: any;
  public optional: OptionalSchema;

  constructor(structure: any, isModal: boolean = false, optional?: OptionalSchema) {
    this._structure = structure;
    this.isModal = isModal;
    this.optional = optional;
  }
}

export class ConnectionDetailSchema {

  public _ctype = 'ConnectionDetailSchema';
  private type: DetailSchemaType;
  public value: any;
  private hidden = false;
  private optional: OptionalSchema;
  private options: Array<any>;

  constructor(type: DetailSchemaType, value: any = null, hidden: boolean = false, optional?: OptionalSchema, options?: Array<any>) {
    this.type = type;
    this.value = value;
    this.hidden = hidden;
    this.optional = optional;
    this.options = options;
  }
}


export interface ConnectionDetails {
  readonly type: ConnectionDetailsType;
  readonly authentication_type?: ConnectionAuthenticationType;
  readonly pagination_type?: PaginationType;
}


export class ConnectionDetailsFtp implements ConnectionDetails {
  type = ConnectionDetailsType.FTP;

  public ftp_hostname: ConnectionDetailSchema;
  public ftp_username: ConnectionDetailSchema;
  public ftp_password: ConnectionDetailSchema;

  constructor(data: any) {
    this.ftp_hostname = new ConnectionDetailSchema(DetailSchemaType.String, data['ftp_hostname']);
    this.ftp_username = new ConnectionDetailSchema(DetailSchemaType.String, data['ftp_username']);
    this.ftp_password = new ConnectionDetailSchema(DetailSchemaType.String, data['ftp_password']);
  }
}


export class ConnectionDetailsMssql implements ConnectionDetails {
  type = ConnectionDetailsType.MSSQL;

  public charset = new ConnectionDetailSchema(DetailSchemaType.String);
  public db = new ConnectionDetailSchema(DetailSchemaType.String);
  public host = new ConnectionDetailSchema(DetailSchemaType.String);
  public password = new ConnectionDetailSchema(DetailSchemaType.String);
  public username = new ConnectionDetailSchema(DetailSchemaType.String);

  constructor(data: any) {
    if (data.hasOwnProperty('charset')) {
      this.charset.value = data['charset'];
    }
    if (data.hasOwnProperty('db')) {
      this.db.value = data['db'];
    }
    if (data.hasOwnProperty('host')) {
      this.host.value = data['host'];
    }
    if (data.hasOwnProperty('password')) {
      this.password.value = data['password'];
    }
    if (data.hasOwnProperty('username')) {
      this.username.value = data['username'];
    }
  }
}


export class ConnectionDetailsOdbc implements ConnectionDetails {
  type = ConnectionDetailsType.ODBC;

  public autocommit = new ConnectionDetailSchema(DetailSchemaType.Boolean, false);
  public connection_string = new ConnectionDetailSchema(DetailSchemaType.String);

  constructor(data: any) {
    if (data.hasOwnProperty('autocommit')) {
      this.autocommit.value = data['autocommit'];
    }
    if (data.hasOwnProperty('connection_string')) {
      this.connection_string.value = data['connection_string'];
    }
  }
}


export class ConnectionDetailsLocalRest implements ConnectionDetails {
  type = ConnectionDetailsType.LOCAL_REST;

  public base_url: ConnectionDetailSchema;
  public requests: ConnectionDetailStructure;
  public task: ConnectionDetailStructure;

  constructor(data: any) {
    this.base_url = new ConnectionDetailSchema(DetailSchemaType.String, data['base_url']);

    let structure = null;
    const requests = data.requests;
    if (requests) {
      structure = requests.structure;
    }

    this.requests = new ConnectionDetailStructure({
      structure: new ConnectionDetailSchema(DetailSchemaType.Structure, structure)
    });

    let task, convert_from, data_list_path, pagination, pagType, pagArgs, attribute, next_page, npType, npArgs, path = null;
    task = data.task;
    if (task) {
      convert_from = task.convert_from;
      data_list_path = task.data_list_path;
      pagination = task.pagination;
      if (pagination) {
        pagType = pagination.type;
        pagArgs = pagination.args;
        if (pagArgs) {
          attribute = pagArgs.attribute;
          next_page = pagArgs.next_page;
          if (next_page) {
            npType = next_page.type;
            npArgs = next_page.args;
            if (npArgs) {
              path = npArgs.path;
            }
          }
        }
      }
    }

    this.task = new ConnectionDetailStructure({
      convert_from: new ConnectionDetailSchema(DetailSchemaType.String, convert_from),
      data_list_path: new ConnectionDetailSchema(DetailSchemaType.Path,
        data_list_path,
        false,
        new OptionalSchema(true, true, data_list_path)
      ),
      pagination: new ConnectionDetailStructure({
        type: new ConnectionDetailSchema(DetailSchemaType.String, pagType),
        args: new ConnectionDetailStructure({
          attribute: new ConnectionDetailSchema(DetailSchemaType.Structure, attribute),
          next_page: new ConnectionDetailStructure({
            type: new ConnectionDetailSchema(DetailSchemaType.String, npType),
            args: new ConnectionDetailStructure({
              path: new ConnectionDetailSchema(DetailSchemaType.Path, path),
            }),
          }),
        }),
      }, true, new OptionalSchema(true, true, pagination)),
    });
  }
}


export class ConnectionDetailsLocalFile implements ConnectionDetails {
  type = ConnectionDetailsType.LOCAL_FILE;

  constructor(data: any) {
  }
}


export class ConnectionDetailsSoap implements ConnectionDetails {
  type = ConnectionDetailsType.SOAP;

  public authentication = new ConnectionDetailStructure({
    password: new ConnectionDetailSchema(DetailSchemaType.String),
    service_name_login: new ConnectionDetailSchema(DetailSchemaType.String),
    service_name_logout: new ConnectionDetailSchema(DetailSchemaType.String),
    url: new ConnectionDetailSchema(DetailSchemaType.String),
    username: new ConnectionDetailSchema(DetailSchemaType.String),
  });

  constructor(data: any) {
    if (data.hasOwnProperty('authentication')) {
      if (data['authentication'].hasOwnProperty('password')) {
        this.authentication._structure.password.value = data['authentication'].password;
      }
      if (data['authentication'].hasOwnProperty('service_name_login')) {
        this.authentication._structure.service_name_login.value = data['authentication'].service_name_login;
      }
      if (data['authentication'].hasOwnProperty('service_name_logout')) {
        this.authentication._structure.service_name_logout.value = data['authentication'].service_name_logout;
      }
      if (data['authentication'].hasOwnProperty('url')) {
        this.authentication._structure.url.value = data['authentication'].url;
      }
      if (data['authentication'].hasOwnProperty('username')) {
        this.authentication._structure.username.value = data['authentication'].username;
      }
    }
  }
}


export class ConnectionDetailsRest implements ConnectionDetails {
  type = ConnectionDetailsType.REST;
  authentication_type: ConnectionAuthenticationType;

  pagination_type: PaginationType;

  public base_url: ConnectionDetailSchema;
  public requests: ConnectionDetailStructure;
  public data_list_path: ConnectionDetailSchema;
  public pagination: ConnectionDetailStructure;
  public convert_from: ConnectionDetailSchema;

  constructor(data: any) {

    this.base_url = new ConnectionDetailSchema(DetailSchemaType.String, data['base_url']);
    this.data_list_path = new ConnectionDetailSchema(
      DetailSchemaType.Path,
      data['data_list_path'],
      false,
      new OptionalSchema(true, true, data['data_list_path'])
    );
    this.convert_from = new ConnectionDetailSchema(DetailSchemaType.String, data['convert_from']);

    let structure = null;
    const requests = data.requests;
    if (requests) {
      structure = requests.structure;
    }

    this.requests = new ConnectionDetailStructure({
      structure: new ConnectionDetailSchema(DetailSchemaType.Structure, structure)
    });

    this.pagination_type = data.pagination_type;
    if (!this.pagination_type) {
      this.pagination_type = undefined;
    }

    let pagination, pagArgs, attribute, next_page, npType, npArgs, path, pages_total_path = null;
    pagination = data.pagination;

    if (data.pagination_type == PaginationType.URL.toUpperCase()) {

      pagArgs = pagination.args;
      if (pagArgs) {
        attribute = pagArgs.attribute;
        next_page = pagArgs.next_page;
        if (next_page) {
          npType = next_page.type;
          npArgs = next_page.args;
          if (npArgs) {
            path = npArgs.path;
          }
        }

        delete pagArgs['pages_total_path'];
      }

      this.pagination = new ConnectionDetailStructure({
        type: new ConnectionDetailSchema(
          DetailSchemaType.Options,
          PaginationType.URL,
          true,
          null,
          [PaginationType.URL, PaginationType.URL_COUNTER]
        ),
        args: new ConnectionDetailStructure({
          attribute: new ConnectionDetailSchema(DetailSchemaType.Structure, attribute),
          next_page: new ConnectionDetailStructure({
            type: new ConnectionDetailSchema(DetailSchemaType.String, npType),
            args: new ConnectionDetailStructure({
              path: new ConnectionDetailSchema(DetailSchemaType.Path, path),
            }),
          }),
        }),
      }, false);

    } else if (data.pagination_type == PaginationType.URL_COUNTER.toUpperCase()) {

      pagArgs = pagination.args;
      if (pagArgs) {
        pages_total_path = pagArgs.pages_total_path;
        delete pagArgs['attribute'];
        delete pagArgs['next_page'];
      }

      this.pagination = new ConnectionDetailStructure({
        type: new ConnectionDetailSchema(
          DetailSchemaType.Options,
          PaginationType.URL_COUNTER,
          true,
          null,
          [PaginationType.URL, PaginationType.URL_COUNTER]
        ),
        args: new ConnectionDetailStructure({
          pages_total_path: new ConnectionDetailSchema(DetailSchemaType.Path, pages_total_path)
        }),
      }, false);

    } else {

    }
  }
}


export class ConnectionDetailsRestNoAuth extends ConnectionDetailsRest {
  type = ConnectionDetailsType.REST;
  authentication_type = ConnectionAuthenticationType.NO_AUTHENTICATION;

  constructor(data: any) {
    super(data);
  }
}


export class ConnectionDetailsRestOauth1 extends ConnectionDetailsRest {
  type = ConnectionDetailsType.REST;
  authentication_type = ConnectionAuthenticationType.OAUTH1;

  public oauth1: ConnectionAuthenticationOauth1;

  constructor(data: any) {
    super(data);
    this.oauth1 = new ConnectionAuthenticationOauth1(data['oauth1'] || null);
  }
}


export class ConnectionDetailsRestOauth2 extends ConnectionDetailsRest {
  type = ConnectionDetailsType.REST;
  authentication_type = ConnectionAuthenticationType.OAUTH2;

  public oauth2: ConnectionAuthenticationOauth2;

  constructor(data: any) {
    super(data);
    this.oauth2 = new ConnectionAuthenticationOauth2(data['oauth2'] || null);
  }
}


export class ConnectionDetailsRestSession extends ConnectionDetailsRest {
  type = ConnectionDetailsType.REST;
  authentication_type = ConnectionAuthenticationType.SESSION;

  public session: ConnectionAuthenticationSession;

  constructor(data: any) {
    super(data);
    this.session = new ConnectionAuthenticationSession(data['session'] || null);
  }
}


export class ConnectionDetailsRestCustom extends ConnectionDetailsRest {
  type = ConnectionDetailsType.REST;
  authentication_type = ConnectionAuthenticationType.CUSTOM;

  public custom_auth: ConnectionAuthenticationCustom;

  constructor(data: any) {
    super(data);
    this.custom_auth = new ConnectionAuthenticationCustom(data['custom_auth'] || null);
  }
}


export class SystemCollection {
  [key: string]: System;
}
