import {Component, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-refresher',
  templateUrl: './refresher.component.html',
  styleUrls: ['./refresher.component.css']
})
export class RefresherComponent implements OnInit {

  @Output() refreshed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  callRefresh() {
    this.refreshed.emit(true);
  }

}
