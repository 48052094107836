import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PagesService} from '../../pages/pages.service';
import {TypeService} from '../../pages/type/type.service';
import {ApiService} from '../../_api/api.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {JsonModalComponent} from '../modals/modal/json-modal.component';

enum RadioModelData {
  OriginalData = 'original',
  GenericData = 'generic-data',
  Queue = 'queue'
}


@Component({
  selector: 'app-data-selector',
  templateUrl: './data-selector.component.html',
  styleUrls: ['./data-selector.component.css'],
  providers: [ApiService]
})
export class DataSelectorComponent implements OnInit {

  @Input() originalData: any;
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  radioOptions = RadioModelData;
  radioModelData: RadioModelData;

  allTypesList = [];
  selectedTypeId: string = undefined;

  genericDataResult: any = null;
  queueDataResult: any = null;

  queueTypesFromList = [];
  selectedQueueTypeFromId: string = undefined;
  queueTypesToList = [];
  selectedQueueTypeToId: string = undefined;

  constructor(private _api: ApiService,
              private _pageService: PagesService,
              private _typeService: TypeService,
              private bsModalService: BsModalService) {
    this.radioModelData = this.radioOptions.OriginalData;
  }

  ngOnInit() {
    this._typeService.getAllTypesList().then(response => {
      this.allTypesList = response;
    });

    this._typeService.getHookTypesOrigin().then(response => {
      this.queueTypesFromList = response;
    });
  }

  setLoading() {
    this._pageService.setLoading(true);
  }

  loaded() {
    this._pageService.setLoading(false);
  }


  radioModelDataChanged() {
    if (this.radioModelData == this.radioOptions.OriginalData) {
      this.dataChange.emit(this.originalData);
    }
  }


  getLabel(id) {
    return this._typeService.getSystemTypeLabel(id);
  }

  changeSelectedType() {
  }

  loadGenericData() {
    this.setLoading();

    const urlObj = {};
    urlObj['fetch_qty'] = 1;

    const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
      return [k, urlObj[k]].join('=');
    }).join('&');

    const getUrl = 'genericdata/' + this.selectedTypeId + '?' + urlParams;

    this._api.get(getUrl).then(response => {
      console.log(response);
      if (response && response.results) {
        this.genericDataResult = response.results[0];
      }
      this.loaded();
    });
  }

  loadQueueData() {
    this.setLoading();

    const urlObj = {};
    urlObj['fetch_qty'] = 1;
    urlObj['type_origin'] = this.selectedQueueTypeFromId;
    urlObj['type_destination'] = this.selectedQueueTypeToId;

    const urlParams = Object.getOwnPropertyNames(urlObj).map(k => {
      return [k, urlObj[k]].join('=');
    }).join('&');

    const getUrl = 'gatewayqueue?' + urlParams;

    this._api.get(getUrl).then(response => {
      console.log(response);
      if (response && response.results) {
        this.queueDataResult = response.results[0];
      }
      this.loaded();
    });
  }


  selectGenericData() {
    if (this.genericDataResult && this.genericDataResult.structure) {
      this.dataChange.emit(this.genericDataResult.structure);
    }
  }

  selectQueueData() {
    if (this.queueDataResult && this.queueDataResult.structure_origin) {
      this.dataChange.emit(this.queueDataResult.structure_origin);
    }
  }

  openJsonModal(title, data) {
    const formData = {title: title, data: data};
    const initialState = {modalData: formData};
    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.bsModalService.show(JsonModalComponent, config);
  }


  changeSelectedQueueTypeFrom(id) {
    this.queueTypesToList = [];
    setTimeout(() => {
      this.selectedQueueTypeToId = undefined;
      this.queueTypesToList = this._typeService.getHookTypesDestination(id);
    });
  }

  changeSelectedQueueTypeTo() {
  }

}


