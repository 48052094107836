import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {EditContentModalComponent} from '../../../_utils/modals/modal/edit-content-modal.component';

import {ConnectionDetailSchema, ConnectionDetailStructure, DetailSchemaType, } from '../system';


@Component({
  selector: 'app-connection-detail-structure',
  templateUrl: './connection-detail-structure.component.html',
  styleUrls: ['./connection-detail.component.css']
})
export class ConnectionDetailStructureComponent implements OnInit {

  @Input() originalStructure: any;
  @Input() structure: any;
  @Output() structureChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() label: string;

  detailSchemaType = DetailSchemaType;

  constructor(private bsModalService: BsModalService) { }

  ngOnInit() {
    this.mapStructure();
  }

  getObjectKeys(obj) {
    return Object.keys(obj);
  }

  openInModal(label) {
    const structure = this.structure[label]._structure;

    const modalData = {
      title: 'Edit ' + label,
      label: label,
      data: structure,
      originalData: this.originalStructure ? this.originalStructure[label] : null
    };
    const initialState = { modalData: modalData };

    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(EditContentModalComponent, config);
    activeModal.content.modalResult.subscribe(response => {
      if (response && response.success) {
        this.structure[label]._structure = response.payload.data;
        this.originalStructure[label] = response.payload.original;
        this.structureChange.emit(this.structure);
      }
    });
  }

  onDataChange($event, item = null) {
    const value = $event.value;
    this.mapStructure();
    if (item) {
      this.originalStructure[item] = value;
      this.structure[item].value = value;
    }
    this.structureChange.emit(this.structure);
  }


  checkType(data: ConnectionDetailSchema | ConnectionDetailStructure, type) {
    if (type === 'schema') {
      // return data.constructor.name === ConnectionDetailSchema.name;
      return data._ctype === 'ConnectionDetailSchema';
    } else if (type === 'structure') {
      // return data.constructor.name === ConnectionDetailStructure.name;
      return data._ctype === 'ConnectionDetailStructure';
    } else {
      return false;
    }
  }


  mapStructure() {
  }


  toggleOptional(item) {
    // TODO

    this.structure[item].optional.model = !this.structure[item].optional.model;
    if (this.structure[item].optional.model) {
      this.structure[item].optional.bkp = this.structure[item].value;
    }
    this.structureChange.emit(this.structure);
  }


}
