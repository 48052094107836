import { Routes } from '@angular/router';

import { NgxAuthComponent } from './components/auth.component';
import { NgxLoginComponent } from './components/login/login.component';
import { NgxLogoutComponent } from './components/logout/logout.component';

export const routes: Routes = [
  {
    path: 'auth',
    component: NgxAuthComponent,
    children: [
      {
        path: '',
        component: NgxLoginComponent,
      },
      {
        path: 'login',
        component: NgxLoginComponent,
      },
      {
        path: 'logout',
        component: NgxLogoutComponent,
      },
    ],
  },
];
