import { Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NbLayoutModule, NbCardModule, NbCheckboxModule } from '@nebular/theme';

import { NgxAuthComponent } from './components/auth.component';

import { NgxAuthBlockComponent } from './components/auth-block/auth-block.component';
import { NgxLoginComponent } from './components/login/login.component';
import { NgxLogoutComponent } from './components/logout/logout.component';

import { routes } from './auth.routes';

// import { LoginService } from './components/login/login.service';


// export function nbAuthServiceFactory(config: any, tokenService: NbTokenService, injector: Injector) {
//   const providers = config.providers || {};

//   for (const key in providers) {
//     if (providers.hasOwnProperty(key)) {
//       const provider = providers[key];
//       const object = injector.get(provider.service);
//       object.setConfig(provider.config || {});
//     }
//   }

//   return new NbAuthService(tokenService, injector, providers);
// }

// export function nbOptionsFactory(options) {
//   return deepExtend(defaultSettings, options);
// }

@NgModule({
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbCheckboxModule,
    RouterModule.forChild(routes),
    FormsModule,
    HttpClientModule,
  ],
  declarations: [
    NgxAuthComponent,
    NgxAuthBlockComponent,
    NgxLoginComponent,
    NgxLogoutComponent,
  ],
  providers: [
    // LoginService,
  ]
  // exports: [
  //   NgxAuthComponent,
  //   NgxAuthBlockComponent,
  //   NgxLoginComponent,
  //   NgxLogoutComponent,
  // ],
})
export class NgxAuthModule {}
