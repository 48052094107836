import {Injectable} from '@angular/core';
import {ApiService} from '../../_api/api.service';
import {SystemCollection} from '../system/system';
import {TypeCollection} from './type';

@Injectable()
export class TypeService {

  loaded = false;

  allSystems: SystemCollection;
  allTypes: TypeCollection;

  constructor(private _api: ApiService) {
    this.load();
  }

  load() {
    return new Promise((resolve, reject) => {
      this._api.get('system').then(response => {
        const systems = response.results;

        this.allSystems = new SystemCollection();
        systems.map(el => this.allSystems[el.id] = el);

        const aux = systems.reduce(function (a, b) {
          return a.concat(b.types);
        }, []);
        this.allTypes = new TypeCollection();
        aux.map(el => this.allTypes[el.id] = el);

        this.loaded = true;
        resolve();
      }).catch(error => reject(error));
    });
  }

  getAllTypesList() {
    return this.load().then(() => {
      if (this.allTypes) {
        return Object.keys(this.allTypes).map(elem => {
          return elem;
        });
      } else {
        return null;
      }
    }).catch(() => {
      return null;
    });
  }

  getAllSystemsList() {
    return this.load().then(() => {
      if (this.allSystems) {
        return Object.keys(this.allSystems).map(elem => {
          return elem;
        });
      } else {
        return null;
      }
    }).catch(() => {
      return null;
    });
  }

  getSystemFromId(id) {
    if (this.loaded) {
      return this.allSystems[id];
    }
  }

  getTypeFromId(id) {
    if (this.loaded) {
      return this.allTypes[id];
    }
  }

  getSystemFromTypeId(typeId) {
    if (this.loaded && this.allTypes[typeId]) {
      const systemId = this.allTypes[typeId].system_id;
      return this.allSystems[systemId];
    }
  }

  getSystemTypeLabel(typeId) {
    if (this.loaded) {
      const system = this.getSystemFromTypeId(typeId);
      const type = this.getTypeFromId(typeId);

      if (system && type) {
        const systemLabel = system.alias;
        const typeLabel = type.description;
        return systemLabel + ' > ' + typeLabel;
      }
    }
  }


  getHookTypesOrigin() {
    return this.getAllSystemsList().then(response => {
      const systems = response;
      const types_from = [];
      systems.map(s => {
        this.getSystemFromId(s).types.map(t => {
          if (t.hooks && t.hooks.post && t.hooks.post.map(hp => hp.action === 'publish').length > 0) {
            types_from.push(t.id);
          }
        });
      });
      return types_from;
    });
  }

  getHookTypesDestination(typeOriginId) {
    const types_to = [];
    this.getTypeFromId(typeOriginId).hooks.post.filter(hp => hp.action === 'publish').map(hp => {
      types_to.push(this.getTypeFromId(hp.destination).id);
    });
    return types_to;
  }


  getAllTypesLinks() {
    return this.load().then(() => {
      if (this.allTypes) {
        const linksList = [];
        Object.keys(this.allTypes).forEach(elem => {
          if (this.allTypes[elem].links && this.allTypes[elem].links.length > 0) {
            linksList.push({typeId: elem, links: this.allTypes[elem].links});
          }
        });
        return linksList;
      } else {
        return null;
      }
    }).catch(() => {
      return null;
    });
  }


  getUnusedLinks() {
    return this.load().then(() => {
      if (this.allTypes) {
        const linksList = [];
        const appendLinksList = [];

        Object.keys(this.allTypes).forEach(elem => {
          if (this.allTypes[elem].links && this.allTypes[elem].links.length > 0) {
            this.allTypes[elem].links.forEach(el => {
              linksList.push({typeId: elem, linkType: el.type});
            });
          }
          if (this.allTypes[elem].append_links && this.allTypes[elem].append_links.length > 0) {
            this.allTypes[elem].append_links.forEach(el => {
              appendLinksList.push({typeId: elem, linkType: el.type});
            });
          }
        });

        const allLinkKeys = linksList.map(elem => elem.linkType);
        const allAppendLinkKeys = appendLinksList.map(elem => elem.linkType);

        const unusedLinks = linksList.filter(elem => allAppendLinkKeys.indexOf(elem.linkType) === -1);
        const unusedAppendLinks = appendLinksList.filter(elem => allLinkKeys.indexOf(elem.linkType) === -1);

        return {links: unusedLinks, appendLinks: unusedAppendLinks};
      } else {
        return null;
      }
    }).catch(() => {
      return null;
    });
  }


}
