import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TypeService} from '../type.service';

@Component({
  selector: 'app-type-list',
  templateUrl: './type-list.component.html',
  styleUrls: ['./type-list.component.css']
})
export class TypeListComponent implements OnInit {

  isLoaded = false;

  @Input() list: Array<any>;
  @Output() listChange: EventEmitter<any> = new EventEmitter<any>();

  typeList: Array<any>;

  constructor(private _typeService: TypeService) {
  }

  ngOnInit() {
    this.typeList = new Array<any>();
    this.list.forEach(elem => {
      const newElem = {
        id: elem,
        editMode: false
      };
      this.typeList.push(newElem);
    });

    this.isLoaded = true;
  }

  addNewType() {
    const newElem = {
      id: null,
      editMode: true
    };
    this.typeList.push(newElem);
  }

  typeChanged($event, item) {
    const action = $event.action;
    if (action === 'save') {
      item.id = $event.id;
      item.editMode = false;
    } else if (action === 'delete') {
      const idx = this.typeList.indexOf(item);
      this.typeList.splice(idx, 1);
    }
    // this.listChange.emit(this.typeList);

    this.listChange.emit(this.typeList.map(elem => elem.id));
  }
}
