import {EzyNotification, EzyNotificationType} from './ezy-notification';

export class EzyNotificationQueue {
  notifications: EzyNotification[];

  constructor() {
    this.notifications = new Array<EzyNotification>();
  }

  count() {
    return this.notifications.length;
  }

  get() {
    return this.notifications;
  }

  add(notification: EzyNotification, update = false) {
    if (update) {
      const notif = this.notifications.filter(elem => {
        if (elem.type === EzyNotificationType.SystemNotRunning) {
          return elem.type === notification.type && elem.typeId === notification.typeId && elem.sysId === notification.sysId;
        } else {
          return elem.type === notification.type && elem.typeId === notification.typeId;
        }
      })[0];

      if (notif) {
        notif.title = notification.title;
        notif.subtitle = notification.subtitle;
        notif.message = notification.message;
      } else {
        this.notifications.push(notification);
      }
    } else {
      this.notifications.push(notification);
    }
  }

  remove(notification: EzyNotification) {
    const notif = this.notifications.filter(elem => {
      if (elem.type === EzyNotificationType.SystemNotRunning) {
        return elem.type === notification.type && elem.typeId === notification.typeId && elem.sysId === notification.sysId;
      } else {
        return elem.type === notification.type && elem.typeId === notification.typeId;
      }
    })[0];
    if (notif) {
      this.notifications.splice(this.notifications.indexOf(notif), 1);
    }
  }
}
