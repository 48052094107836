import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ComplexTypeModalComponent} from '../../modals/modal/complex-type-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComplexTypeModalService} from '../../modals/modal/complex-type-modal.service';
import {ApiService} from '../../../_api/api.service';
import {ComplexTypeService} from '../complex-types';
import {DragulaService} from 'ng2-dragula';


class PathGroupElement {
  public edit = false;
  public new: any;

  constructor(edit, newElem) {
    this.edit = edit;
    this.new = newElem;
  }
}

export enum PathGroupType {
  Destination = 'Destination',
  Origin = 'Origin',
}

@Component({
  selector: 'app-path-group',
  templateUrl: './path-group.component.html',
  styleUrls: ['./path-group.component.css'],
  providers: [ApiService],
})
export class PathGroupComponent implements OnInit {

  @Output() dataArrayChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() dragBagName: string;
  @Input() allowComplex = false;
  @Input() allowEmpty = true;
  @Input() groupType: PathGroupType;
  @Input() testResult?: any;
  @Input() prePath?: any;
  @Input() allowTest = true;

  allowDrag = true;
  dataCopy = [];
  dragOptions: any;

  @Input()
  set dataArray(dataArray: Array<any>) {
    this._dataArray = dataArray;
    this.dataCopy = [];
    if (this._dataArray && this._dataArray.length > 0) {
      this._dataArray.forEach(el => {
        this.dataCopy.push(new PathGroupElement(false, el));
      });
    }
    this.dataCopy = [...this.dataCopy];
  }

  private _dataArray: Array<any>;


  constructor(private _api: ApiService,
              private bsModalService: BsModalService,
              private complexTypeService: ComplexTypeService,
              private ctmService: ComplexTypeModalService,
              private dragulaService: DragulaService) {

    const _th = this;
    this.dragOptions = {
      moves: function () {
        return _th.allowDrag;
      }
    };

    dragulaService.drop.subscribe(() => {
      this.dataChange();
    });
  }

  ngOnInit() {
  }

  addElement() {
    const elem = 'PATH';
    const newElem = new PathGroupElement(false, elem);
    this.dataCopy.push(newElem);
    this.dataChange();
  }

  removeElement(index) {
    if (!(!this.allowEmpty && this.dataCopy.length === 1)) {
      this.dataCopy.splice(index, 1);
      this.dataChange();
    }
  }

  updateDaraArray(data) {
    this._dataArray = data;
    this._dataArray.map((elem, j) => {
      this.dataCopy[j].new = elem;
    });
    this.dataChange();
  }

  addComplexElement() {
    const _th = this;

    this.ctmService.runPreTest(null, this.prePath, this._dataArray).then(response => {
      const originData = response;

      let origin = [];
      if (this.prePath) {
        origin = this.prePath.concat(this._dataArray);
      } else {
        origin = this._dataArray;
      }

      this.ctmService.setPreOriginData(originData);
      this.ctmService.setHookData({});

      const initialState = {initData: null, prePath: origin, originData: originData};
      this.ctmService.increaseCount();

      const config = {
        class: 'modal-lg',
        initialState: initialState,
        backdrop: true,
        ignoreBackdropClick: true
      };

      const activeModal = this.bsModalService.show(ComplexTypeModalComponent, config);

      activeModal.content.event.subscribe(res => {
        if (res && res.success) {
          const elem = res.payload;
          const newElem = new PathGroupElement(false, elem);
          _th.dataCopy.push(newElem);
          this.dataChange();
        }
      });

    }).catch(error => {
      console.log('RUN PRE TEST ERROR', error);
    });
  }

  onElementChange(event, index) {
    this.allowDrag = this.dataCopy.filter(elem => elem.edit).length === 0;

    if (event.edit) {
    } else {
      this.dataCopy[index] = event;
      this.dataChange();
    }
  }


  dataChange() {
    this.dataCopy = [...this.dataCopy];
    const ret = this.convertData(this.dataCopy);
    this.dataArrayChange.emit(ret);
  }


  runTest() {
    const _th = this;
    this.ctmService.getTypesInfo();
    let origin = [];
    if (this.prePath) {
      origin = this.prePath.concat(this._dataArray);
    } else {
      origin = this._dataArray;
    }

    const obj = {
      field_destination: ['RESULT'],
      field_origin: origin,
      validation: []
    };

    const data = {
      origin: this.ctmService.getOriginData(),
      structure: [obj]
    };

    this._api.post('../../ui/transformdict', data).then(res => {
      console.log(res);
      _th.testResult = res;
    });
  }

  convertData(data) {
    const cloned = JSON.parse(JSON.stringify(data));
    return cloned.map(el => el.new);
  }

}



