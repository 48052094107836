import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';
import {HookModalComponent} from '../../../_utils/modals/modal/hook-modal.component';


import {DetailSchemaType, } from '../system';


@Component({
  selector: 'app-connection-detail-element',
  templateUrl: './connection-detail-element.component.html',
  styleUrls: ['./connection-detail.component.css']
})
export class ConnectionDetailElementComponent implements OnInit {

  @Input() originalData: any;

  @Input()
  set data(data: any) {
    this._data = data;
  }

  _data: any;
  @Input() label: string;
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  detailSchemaType = DetailSchemaType;

  constructor(private bsModalService: BsModalService) {
  }

  ngOnInit() {
  }

  onChange($event) {
    this._data.value = $event;
    this.originalData = $event;
    if (this._data.optional && this._data.optional.isOptional) {
      this._data.optional.bkp = $event;
    }
    this.dataChange.emit(this._data);
  }


  editStructure(structure) {
    const originData = {};
    const idFrom = null;
    const idTo = null;

    const hookData = {structure: structure};

    const modalData = {
      title: 'Edit Structure',
      hookType: 'post-hook',
      typeFrom: idFrom,
      typeTo: idTo,
      data: hookData,
      originData: originData,
      isReturn: true
    };
    const initialState = {modalData: modalData};

    const config = {
      class: 'modal-lg',
      initialState: initialState,
      backdrop: true,
      ignoreBackdropClick: true
    };

    const activeModal = this.bsModalService.show(HookModalComponent, config);
    activeModal.content.event.subscribe(response => {
      if (response && response.success && response.payload) {
        this.onChange(response.payload.hookStructure);
      }
    });
  }


  toggleOptional() {
    this._data.optional.model = !this._data.optional.model;
    if (this._data.optional.model) {
      this._data.optional.bkp = this._data.value;
      this._data.value = null;
    } else {
      this._data.value = this._data.optional.bkp;
    }
    this.dataChange.emit(this._data);
  }


}
