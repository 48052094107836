import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup} from '@angular/forms';
import {QuestionControlService} from '../../forms/question-control.service';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import * as _ from 'underscore';


@Component({
  selector: 'ngx-form-modal',
  templateUrl: './form-modal.component.html',
  providers: [QuestionControlService]
})
export class FormModalComponent implements OnInit, AfterViewInit {

  formData: any;
  form: FormGroup;
  payLoad = '';

  result = {
    success: false,
    payload: null
  };

  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;
  public data: any;

  viewReady = false;
  editorIsValid = true;

  flipEnabled = true;

  constructor(private activeModal: NgbActiveModal, private qcs: QuestionControlService) {
    this.editorOptions = new JsonEditorOptions();

    const _this = this;
    this.editorOptions.onChange = function () {
      try {
        _this.editor.get();
        _this.editorIsValid = true;
      } catch (err) {
        console.log(err);
        _this.editorIsValid = false;
      }
    };
  }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.formData.questions);
    this.data = this.qcs.toJsonData(this.formData.questions.filter(elem => {
      return elem.controlType !== 'hidden';
    }));

    console.log(this.formData);
    console.log(this.data);

    if (this.formData.flipEnabled != null) {
      this.flipEnabled = this.formData.flipEnabled;
    }

    this.initEditorOptions();
  }

  ngAfterViewInit() {
    this.viewReady = true;
  }

  closeModal() {
    this.activeModal.close(this.result);
  }

  initEditorOptions() {
    this.editorOptions.mode = 'code';
  }

  onSubmit() {
    this.payLoad = this.form.value;
    this.result.success = true;
    this.result.payload = this.payLoad;
    this.closeModal();
  }

  applyChanges() {
    const editorValues = this.editor.get();

    const formValues = _.mapObject(this.form.controls, function (val) {
      return val.value;
    });

    this.form.setValue(_.defaults(_.pick(editorValues, _.keys(this.form.controls)), formValues));
  }

  onFlipped() {
    this.applyChanges();
  }

}
