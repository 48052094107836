import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {NgxAuthComponent, NgxLoginComponent, NgxLogoutComponent} from './auth';

import {AuthGuard} from './_guards';

import {PagesComponent} from './pages/pages.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {ConfigComponent} from './pages/config/config.component';
import {QueueComponent} from './pages/queue/queue.component';
import {GenericDataComponent} from './pages/generic-data/generic-data.component';
import {ReprocessingRuleComponent} from './pages/reprocessing-rule/reprocessing-rule.component';
import {ThirdPartyComponent} from './pages/third-party/third-party.component';
import {AlertComponent} from './pages/alert/alert.component';
import {GappStatisticsComponent} from './pages/gapp-statistics/gapp-statistics.component';
import {OauthComponent} from './pages/oauth/oauth.component';
import {TemplateComponent} from './pages/template/template.component';


const routes: Routes = [
  {
    path: 'pages',
    component: PagesComponent,
    children: [{
      path: 'dashboard',
      component: DashboardComponent,
    },
      {
        path: 'config',
        component: ConfigComponent
      },
      {
        path: 'reprocessing',
        component: ReprocessingRuleComponent
      },
      {
        path: 'third-party',
        component: ThirdPartyComponent,
      },
      {
        path: 'alert',
        component: AlertComponent,
      },
      {
        path: 'gapp-statistics',
        component: GappStatisticsComponent,
      },
      {
        path: 'queue',
        component: QueueComponent,
        outlet: 'sideRoute'
      },
      {
        path: 'queue/:from/:to',
        component: QueueComponent,
        outlet: 'sideRoute'
      },
      {
        path: 'generic-data',
        component: GenericDataComponent,
        outlet: 'sideRoute'
      },
      {
        path: 'generic-data/:from',
        component: GenericDataComponent,
        outlet: 'sideRoute'
      },
      {
        path: 'oauth',
        component: OauthComponent,
        canActivate: [AuthGuard]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'template',
        component: TemplateComponent,
      }],
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    component: NgxAuthComponent,
    children: [
      {
        path: '',
        component: NgxLoginComponent,
      },
      {
        path: 'login',
        component: NgxLoginComponent,
      },
      {
        path: 'logout',
        component: NgxLogoutComponent,
      },
    ],
  },
  {
    path: 'oauth',
    redirectTo: 'pages/oauth'
  },
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
  enableTracing: false // <-- debugging purposes only
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
