import {Component, Input, OnInit} from '@angular/core';

export enum WizardSchemaType {
  String = 'string',
  Boolean = 'boolean',
}

@Component({
  selector: 'app-wizard-element',
  templateUrl: './wizard-element.component.html',
  styleUrls: ['./wizard-element.component.css']
})
export class WizardElementComponent implements OnInit {

  @Input() stepData: any;

  wizardSchemaType = WizardSchemaType;

  constructor() {
  }

  ngOnInit() {
  }

}
