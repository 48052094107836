import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ComplexTypeModalComponent} from '../../modals/modal/complex-type-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ComplexTypeModalService} from '../../modals/modal/complex-type-modal.service';
import {ApiService} from '../../../_api/api.service';
import {ModalsService} from '../../modals/modals.service';

@Component({
  selector: 'app-complex-type-path',
  templateUrl: './complex-type-path.component.html',
  styleUrls: ['./complex-type-path.component.css'],
})
export class ComplexTypePathComponent {

  @Input() element: any;
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  @Output() elementChange = new EventEmitter<any>();

  @Input() pathData: any;
  @Input() prePath: any;

  constructor(private bsModalService: BsModalService,
              private ctmService: ComplexTypeModalService,
              private _api: ApiService,
              private _modals: ModalsService) {
  }

  removeElement(elem) {
    elem.confirmRemove = true;
  }

  confirmRemoveElement() {
    this.removed.emit();
  }

  cancelRemoveElement(elem) {
    elem.confirmRemove = false;
    elem.edit = false;
  }

  editComplexType(elem) {
    this.ctmService.runPreTest(this.pathData, this.prePath).then(response => {
      const originData = response;

      let preArray = [];
      const arr = this.pathData.array;
      const ind = this.pathData.index;
      const aux = arr.slice(0, ind);

      if (this.prePath) {
        preArray = this.prePath.concat(aux);
      } else {
        preArray = aux;
      }

      this.ctmService.setPreOriginData(originData);
      if (elem.new && elem.new.args) {
        this.ctmService.setHookData(elem.new.args);
      }

      const initialState = {initData: elem.new, prePath: preArray, originData: originData};

      const config = {
        class: 'modal-lg',
        initialState: initialState,
        backdrop: true,
        ignoreBackdropClick: true
      };

      this.ctmService.increaseCount();
      const activeModal = this.bsModalService.show(ComplexTypeModalComponent, config);

      activeModal.content.event.subscribe(res => {
        if (res && res.success) {
          elem.new = res.payload;
        }
        elem.edit = false;
        this.elementChange.emit(elem);
      });

    }).catch(error => {
      console.log('RUN PRE TEST ERROR', error);
    });
  }

}
