import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import 'rxjs/add/operator/toPromise';

import {UserLogin} from '../../models/user';
import {environment} from '../../../../environments/environment';
import {ApiService} from '../../../_api/api.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable()
export class LoginService {

  private baseUrl = environment.baseUrl;  // URL to web api
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private _api: ApiService) {
  }

  getCustomers(user: UserLogin): Promise<any> {
    this.headers = new HttpHeaders();
    const userToken = btoa(user.email + ':' + user.password);
    this.headers = this.headers.append('Authorization', 'Basic ' + userToken);

    return this.http.get<any>(this.baseUrl + 'customer', {headers: this.headers, observe: 'response'})
      .toPromise()
      .then((response) => {
        localStorage.setItem('currentUser', JSON.stringify(response));
        localStorage.setItem('token', userToken);
        const connAdmin = response.headers.get('Connector-User-Admin');
        if (connAdmin && connAdmin.toLowerCase().indexOf('true') > -1) {
          localStorage.setItem('isAdmin', 'true');
        } else {
          localStorage.removeItem('isAdmin');
        }
      })
      .catch(this.handleError);
  }

  isAdmin(): boolean {
    const admin = localStorage.getItem('isAdmin');
    return !!admin;
  }

  getLoggedAsUser(): any {
    const uid = this.route.snapshot.queryParamMap.get('userId');
    const usersList: Array<any> = JSON.parse(localStorage.getItem('usersList'));
    const templatesList: Array<any> = JSON.parse(localStorage.getItem('templatesList'));
    const currentUser: any = JSON.parse(localStorage.getItem('currentUser'));

    let all = usersList;
    if (templatesList) {
      all = usersList.concat(templatesList);
    }

    if (uid && all) {
      return all.filter(elem => elem.id === uid)[0];
    } else if (currentUser && currentUser.length === 1) {
      return currentUser[0];
    } else {
      return null;
    }
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }


  updateUsersList() {
    return this._api.get('customer', {}).then(response => {
      if (response && response.results) {
        const usersList = response.results;
        usersList.sort((a, b) => a.name.toUpperCase() >= b.name.toUpperCase() ? 1 : -1);
        const actualUsersList = JSON.parse(localStorage.getItem('usersList'));
        for (let i = 0; i < actualUsersList.length; i++) {
          for (let k = 0; k < usersList.length; k++) {
            if (actualUsersList[i].id === usersList[k].id) {
              actualUsersList[i] = usersList[k];
            }
          }
        }
        localStorage.setItem('usersList', JSON.stringify(actualUsersList));
      }
    });
  }

}
