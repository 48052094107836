import {Component, Input, Output, HostBinding, EventEmitter} from '@angular/core';

@Component({
  selector: 'ngx-flip-card',
  styleUrls: ['./flip-card.component.scss'],
  template: `
      <div class="flipcard-body">
          <div class="front-container">
              <ng-content select="nb-card-front"></ng-content>
              <a class="flip-button" (click)="toggleFlip()">
                  <i class="fas fa-retweet" aria-hidden="true"></i>
              </a>
          </div>
          <div class="back-container">
              <ng-content select="nb-card-back"></ng-content>
              <a class="flip-button" (click)="toggleFlip()">
                  <i class="fas fa-retweet" aria-hidden="true"></i>
              </a>
          </div>
      </div>
  `,
})
export class NgxFlipCardComponent {

  @Input()
  @HostBinding('class.flipped')
  isFlipped = false;

  @Input()
  @HostBinding('class.allowFlip')
  allowFlip = true;

  @Output() flipped = new EventEmitter<boolean>();


  toggleFlip() {
    if (this.allowFlip) {
      this.isFlipped = !this.isFlipped;
      this.flipped.emit();
    }
  }
}
