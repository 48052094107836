import {Component, EventEmitter, OnInit, Renderer2} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {ApiService} from '../../../_api/api.service';
import {TypeService} from '../../../pages/type/type.service';
import {Type, TypeAppendLink, TypeLink} from '../../../pages/type/type';


@Component({
  selector: 'ngx-edit-link-modal',
  templateUrl: './edit-link-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class EditLinkModalComponent implements OnInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  loading = false;

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  typeId: string;
  currentType: Type;

  allTypesLinks = [];

  typeLinks: Array<TypeLink> = [];
  typeAppendLinks: Array<TypeAppendLink> = [];

  allKeys = [];


  constructor(public modalRef: BsModalRef, private _api: ApiService, private renderer: Renderer2, private _typeService: TypeService) {
  }

  ngOnInit() {
    this.loading = true;

    this.modalData.title = 'Edit Links / Append Links';
    if (this.modalData.data) {
      this.typeId = this.modalData.data;

      this._typeService.getAllTypesLinks().then(response => {

        this.allTypesLinks = response;

        this.currentType = this._typeService.getTypeFromId(this.typeId);

        if (this.currentType && this.currentType.description) {
          this.modalData.title = this.currentType.description + ' - ' + this.modalData.title;
        }

        if (this.currentType && this.currentType.links) {
          this.typeLinks = this.currentType.links;
        }

        if (this.currentType && this.currentType.append_links) {
          this.typeAppendLinks = this.currentType.append_links;
        }

        const temp = this.allTypesLinks.map(elem => elem.links.map(elB => elB.type));
        this.allKeys = temp.reduce((acc, cur) =>
          acc.concat(cur), []).sort().filter(function (item, pos, ary) {
            return !pos || item != ary[pos - 1];
          });

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  }

  saveChanges() {
    this.result.success = true;
    this.result.payload = {
      links: this.typeLinks,
      appendLinks: this.typeAppendLinks
    };
    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  getLabel(id) {
    return this._typeService.getSystemTypeLabel(id);
  }

  isKey(key) {
    return this.allKeys.indexOf(key) > -1;
  }


  removeAppendLink(link, ind) {
    console.log(link, ind);
    this.typeAppendLinks.splice(ind, 1);
  }

  addAppendLink() {
    const newObj = new TypeAppendLink();
    this.typeAppendLinks.push(newObj);
  }


  removeLink(link, ind) {
    console.log(link, ind);
    this.typeLinks.splice(ind, 1);
  }

  addLink() {
    const newObj = new TypeLink();
    this.typeLinks.push(newObj);
  }


  getKeyPresentAt(key) {
    return this.allTypesLinks.filter(elem => elem.links.some(el => el.type === key));
  }


  isValid() {
    return !((this.typeLinks.length > 0 && this.typeLinks.some(elem => !elem.type || elem.type === '')) ||
      (this.typeAppendLinks.length > 0 && this.typeAppendLinks.some(elem => !elem.type || elem.type === '')));
  }

}
