import {AfterViewInit, Component, EventEmitter, OnInit, Renderer2, ViewChild} from '@angular/core';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';
import {ConnectionDetailSchema, ConnectionDetailStructure} from '../../../pages/system/system';


@Component({
  selector: 'ngx-edit-content-modal',
  templateUrl: './edit-content-modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class EditContentModalComponent implements OnInit, AfterViewInit {

  public modalResult: EventEmitter<any> = new EventEmitter();

  modalData: any;
  payLoad = '';
  result = {
    success: false,
    payload: null
  };

  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;

  viewReady = false;
  editorIsValid = true;

  constructor(private renderer: Renderer2, public modalRef: BsModalRef) {
    this.editorOptions = new JsonEditorOptions();

    const _this = this;
    this.editorOptions.onChange = function () {
      try {
        _this.editor.get();
        _this.editorIsValid = true;
      } catch (err) {
        console.log(err);
        _this.editorIsValid = false;
      }
    };
  }

  ngOnInit() {
    this.initEditorOptions();
    this.renderer.setStyle(this.editor.jsonEditorContainer.nativeElement, 'height', '100%');
  }

  ngAfterViewInit() {
    this.viewReady = true;
  }

  saveChanges() {
    this.result.success = true;

    this.mapStructure(this.modalData.originalData, this.modalData.data);
    this.result.payload = {
      data: this.modalData.data,
      original: this.modalData.originalData
    };

    this.closeModal();
  }

  closeModal() {
    this.modalResult.emit(this.result);
    this.modalRef.hide();
  }

  initEditorOptions() {
    this.editorOptions.mode = 'code';
  }

  formatDate(date) {
    const mdate = moment(date);
    return mdate.format('DD/MM/YYYY HH:mm:ss');
  }

  tabChanged(event) {
    if (event.tabTitle == 'Advanced') {
      this.editor.set(JSON.parse(JSON.stringify(this.modalData.originalData)));
    }

    if (event.tabTitle == 'Structure') {
      const json = this.editor.get();
      this.modalData.originalData = JSON.parse(JSON.stringify(json));
      this.mapStructure(this.modalData.originalData, this.modalData.data);
    }
  }


  mapStructure(original, data) {
    Object.keys(original).forEach(key => {
      if (key !== '_ctype') {
        if (data[key]._ctype === 'ConnectionDetailStructure') {
          this.mapStructure(original[key], data[key]._structure);
        } else if (data[key]._ctype === 'ConnectionDetailSchema') {
          data[key].value = original[key];
        }
      }
    });
  }

}
